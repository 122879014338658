<template>
  <div class="input-image-new bg-gray-01" @click="actions.openImageSelector()">
    <input
      ref="fileInput"
      type="file"
      style="display: none"
      accept=".mp4"
      @change="actions.onFileChange($event)"
    />
    <div>
      <button class="upload-btn">
        <upload-icon fill-color="rgba(36, 110, 241, 1)"></upload-icon>
      </button>
      <div class="title">
        <h3>{{ title }}</h3>
      </div>
      <p class="sub-text-s3 text-gray-second">
        * 업로드 파일 형식 : {{ state.acceptString }}
      </p>
      <p class="sub-text-s3 text-gray-second">
        {{ sizeComment }}
      </p>
      <p v-if="settingComment" class="sub-text-s3 text-gray-second">
        <span>{{ settingComment }}</span>
      </p>
    </div>
    <div class="file-preview" :style="`width: ${width}px;`">
      <button class="delete-btn" @click.stop="actions.deleteImage()">
        <delete-icon></delete-icon>
      </button>
      <div
        v-if="type === 'img'"
        class="img bg-gray-studio"
        :style="`background-image: url(${state.fileSrc})`"
      ></div>
      <video
        v-if="type === 'video'"
        ref="videoElement"
        class="bg-gray-studio featured-video"
        autoplay
        loop
        muted
        playsinline
        :poster="`${proxy.$const.blankImage}`"
      >
        <source :src="state.fileSrc" type="video/mp4" />
        Sorry, your browser doesn't support embedded videos.
      </video>
      <div v-if="state.isSaving" class="loading-image">
        <loading-spinner-icon
          class="loading-spinner"
          :width="24"
          :height="24"
          view-box="0 0 24 24"
          icon-color="none"
          clip-path="url(#clip0_19777_153569)"
        ></loading-spinner-icon>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import UploadIcon from "@/components/console/icons/UploadIcon.vue";
import DeleteIcon from "@/components/console/icons/DeleteIcon.vue";
import LoadingSpinnerIcon from "@/components/console/icons/LoadingSpinnerIcon.vue";

export default {
  name: "InputImageNew",
  components: { LoadingSpinnerIcon, DeleteIcon, UploadIcon },
  props: {
    // 이미지 파일 외에 동영상 등등 넣을 수 있는 컴포넌트로 확장시키기 위해 type prop 만듬
    type: {
      type: String,
      default: "img",
    },
    title: {
      type: String,
      default: "파일 업로드",
    },
    accepts: {
      type: Array,
      default: () => {
        return [];
      },
    },
    sizeComment: {
      type: String,
      default: "",
    },
    settingComment: {
      type: String,
      default: "",
    },
    src: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
    ratio: {
      type: String,
      default: "1/1",
    },
    isFileStackAvailability: {
      type: Boolean,
      default: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["openFileSelector", "deleteImage", "fileChange"],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const fileInput = ref(null);
    const videoElement = ref(null);

    const videoAccepts = ["avi", "mp4"];

    const state = reactive({
      acceptString: computed(() => {
        return props.accepts.reduce((result, current) => {
          if (result) {
            return `${result}, ${current}`;
          } else {
            return current;
          }
        }, "");
      }),
      fileSrc: computed(() => {
        let src = props.src;
        if (props.type === "video") {
          return `${src}`;
        } else {
          return src ? src : proxy.$const.blankImage;
        }
      }),
      type: computed(() => {
        let src = props.src;
        let extension = src ? src.split(".")[src.split(".").length - 1] : null;
        return videoAccepts.includes(extension) ? "video" : "img";
      }),
      isFileStackAvailability: true,
      isSaving: computed(() => {
        return props.isSaving;
      }),
    });

    watch(
      () => props.src,
      (newSrc, oldSrc) => {
        if (props.type === "video") {
          reloadVideo();
        }
      }
    );

    const reloadVideo = () => {
      if (videoElement.value) {
        videoElement.value.load();
      }
    };

    onMounted(() => {
      state.isFileStackAvailability = props.isFileStackAvailability;
    });

    //  note input type='file'에 'a'라는 파일을 올린 후, 삭제한 다음 다시 'a'라는 파일을 올릴 때 초기화를 해주지 않으면 동일 파일이므로 업로드가 안됨.
    const resetFileInput = () => {
      fileInput.value.value = "";
    };
    const triggerFileInput = () => {
      resetFileInput();
      fileInput.value.click();
    };

    const triggerFileStack = () => {
      emit("openFileSelector");
    };

    const actions = {
      deleteImage: () => {
        emit("deleteImage");
      },
      openImageSelector: () => {
        state.isFileStackAvailability && !state.isSaving
          ? triggerFileStack()
          : triggerFileInput();
      },
      // todo code 개선
      onFileChange(e) {
        const files = e.target.files || e.dataTransfer.files;
        emit("fileChange", files[0]);
      },
    };

    return { state, actions, fileInput, proxy, videoElement };
  },
};
</script>

<style scoped>
.input-image-new {
  display: flex;
  gap: 30px;
  flex-direction: column;
  padding: 24px;
  border-radius: 4px;
  border: 1px dashed #dcdcdc;
  cursor: pointer;
}

.input-image-new:hover {
  border-color: #246ef1;
}

.upload-btn,
.delete-btn {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 50%;
}

/*todo z-index 비디오 태그가 있을 때 버튼 호버 시키기 위해 z-index 넣었음.*/
.delete-btn {
  background-color: #ffffff;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 5;
}

.delete-btn:hover {
  background-color: #f6f6f6;
}

.file-preview {
  width: 200px;
  max-height: 200px;
  position: relative;
}

.file-preview .img {
  //padding-top: calc(100% * 9 / 16);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  //background-color: yellow;
  width: 100%;
  height: 200px;
}

.input-image-new > div p {
  margin-bottom: 0;
}

.upload-btn {
  margin-bottom: 28px;
}

.title {
  margin-bottom: 6px;
}

.loading-image {
}

@media (min-width: 768px) {
  .input-image-new {
    flex-direction: row;
    justify-content: space-between;
  }
}

.featured-video {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.file-preview .loading-image {
  position: absolute;
  top: 0;
  z-index: 6;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  animation: loading-spinner 1s linear infinite;
}

@keyframes loading-spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
