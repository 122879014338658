<template>
  <base-modal-side :is-dimmed="true" @closeModal="actions.closeModal()">
    <template #modalHeader>
      <div class="header">
        <h3>투표 설정</h3>
        <p class="sub-text-s2 text-gray-second">투표를 선택해주세요!</p>
      </div>
    </template>

    <template #modalBody>
      <page-loading v-if="state.isLoading"></page-loading>
      <template v-if="!state.isLoading">
        <div v-if="state.pollList.length > 0">
          <ul>
            <li
              v-for="(poll, index) in state.pollList"
              :key="`poll-${index}`"
              class="poll-item"
            >
              <session-poll-modal-item
                :poll="poll"
                :selected-poll-resource-id="state.selectedPollResourceId"
                @select-poll="(pollId) => actions.selectPoll(pollId)"
              ></session-poll-modal-item>
            </li>
          </ul>
        </div>
        <div v-if="state.pollList.length === 0">
          <empty-list
            v-if="state.pollList.length === 0"
            :text="`생성된 투표가 없어요.<br />투표를 만들어주세요.`"
            btn-text="투표 만들기"
            :is-info-icon="true"
            :is-button="true"
            @btnAction="actions.moveToPoll()"
          >
            <template #buttonIcon>
              <arrow-icon class="arrow-icon" stroke-size="normal"></arrow-icon>
            </template>
          </empty-list>
        </div>
      </template>
    </template>
    <template #modalFooter>
      <div class="btn-wrapper">
        <button-basic
          class="cancel"
          text="취소"
          text-size="sub-title-s1"
          bg-color="#ECF1F4"
          color="#0d0d0d"
          @action="actions.closeModal()"
        ></button-basic>

        <button-basic
          class="save"
          :text="meeting ? '수정' : '등록'"
          text-size="sub-title-s1"
          :is-loading="state.isSaveLoading"
          :disabled="!state.activeSaveBtn || state.isSaveLoading"
          @action="actions.registerPollSession()"
        ></button-basic>
      </div>
    </template>
  </base-modal-side>
</template>

<script>
import { reactive, onBeforeMount, getCurrentInstance, computed } from "vue";
import BaseModalSide from "@/components/common/BaseModalSide.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import ApiService from "@/api";
import PageLoading from "@/components/console/loadings/PageLoading.vue";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";
import EmptyList from "@/components/console/empties/EmptyList.vue";
import { useRouter } from "vue-router";
import SessionPollModalItem from "@/pages/console/EntClubShow/SessionPollModalItem/SessionPollModalItem.vue";
import { useStore } from "vuex";

export default {
  name: "SessionPollModal",
  components: {
    SessionPollModalItem,
    EmptyList,
    ArrowIcon,
    PageLoading,
    ButtonBasic,
    BaseModalSide,
  },
  props: {
    club: {
      type: Object,
      required: false,
    },
    meeting: {
      type: Object,
      required: false,
    },
    pollsSessions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    sessionId: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["hideModal", "saveComplete", "updateMeetingStatus"],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      // activeSaveModal: computed(() => {
      //   return ;
      // }),
      club: {},
      isLoading: true,
      connectedPollList: [],
      pollList: [],
      pollForm: {},
      selectedPollResourceId: "",
      isSavingLoading: false,
      activeSaveBtn: computed(() => {
        return state.selectedPollResourceId;
      }),
    });

    const removeDuplicationItems = (array1, array2) => {
      const resourceIdsInArray2 = new Set(
        array2.map((item) => item.resourceId)
      );
      console.log(resourceIdsInArray2);
      return array1.filter((item) => !resourceIdsInArray2.has(item.resourceId));
    };
    //
    // // Example usage
    // const array1 = [
    //   { resourceId: 1, name: "Item 1" },
    //   { resourceId: 2, name: "Item 2" },
    // ];
    // const array2 = [
    //   { resourceId: 2, name: "Item 2" },
    //   { resourceId: 3, name: "Item 3" },
    // ];
    //
    // const result = removeCommonItems(array1, array2);
    // console.log(result); // This will log [{ resourceId: 1, name: 'Item 1' }]

    onBeforeMount(async () => {
      console.log("onBeforeMount");
      state.club = props.club ? props.club : {};
      state.connectedPollList = props.pollsSessions.map((item) => item.poll);
      console.log(state.connectedPollList);

      try {
        // let payload = { clubResourceId: state.club.resourceId };
        let paramsData = { clubResourceId: state.club.resourceId };
        const { data } = await ApiService.getClubPolls(paramsData);
        const result = data;
        // state.pollList = data.data;
        if (result.success) {
          state.pollList = result.data;
        }
        console.log(state.pollList);
        // if()
        // if (state.pollList.length > 0) {
        // }
        state.isLoading = false;
      } catch (e) {
        console.log(e);
      }
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      // note 미팅과 콘텐츠는 세션과 함께 만들지만, 투표는 이미 만들어진 투푤르 가져와서 세션을 생성하면서 연결시키므로 payload 에 투표 아이디를 넣어서 보냄
      registerPollSession: async () => {
        // let payload = {
        //   clubResourceId: props.club.resourceId,
        //   data: {
        //     model: "poll",
        //     resourceId: state.selectedPollResourceId,
        //   },
        // };

        await store
          .dispatch("newClubs/postClubSessions", {
            clubResourceId: props.club.resourceId,
            data: {
              model: "poll",
              resourceId: state.selectedPollResourceId,
            },
          })
          .then(() => {
            emit("saveComplete");
          });

        // await ApiService.postClubSessionPoll(
        //   payload.clubResourceId,
        //   payload.data
        // ).then(async (res) => {
        //   if (res.data.success) {
        //     console.log(res.data.data);
        //     emit("saveComplete");
        //   }
        // });
      },
      moveToPoll: () => {
        router.push({ name: "console.polls" });
      },
      selectPoll: (pollRerouceId) => {
        state.selectedPollResourceId = pollRerouceId;
      },
    };

    return { state, actions, proxy };
  },
};
</script>

<style src="./style.css" scoped></style>
