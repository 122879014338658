<template>
  <base-modal-side :is-dimmed="true" @closeModal="actions.closeModal()">
    <template #modalHeader>
      <div class="header">
        <h3>{{ state.title }}</h3>
        <p class="sub-text-s3 text-gray-second">{{ state.description }}</p>
      </div>
    </template>

    <template #modalBody>
      <input-basic-new
        class="plan-title"
        label="티켓명"
        placeholder="티켓명을 입력해주세요."
        :required="true"
        :default-value="state.planForm.title"
        :max-length="25"
        :caption="`${state.planForm.title.length}/25`"
        :disabled="state.isSoldPlan"
        :error-message="state.titleError"
        @updateData="(value) => actions.updatePlanTitle(value)"
      ></input-basic-new>

      <div class="plan-description-section">
        <label class="sub-text-s2">
          구성소개 <span class="text-purple-50">*</span>
        </label>
        <p class="text-gray-second sub-text-s3">
          티켓 구매자들에게 제공될 구성 및 혜택을 입력해주세요. (최대 3개까지
          가능)
        </p>

        <div class="input-area">
          <input-basic
            placeholder="구성 및 혜택 내용을 입력해주세요"
            :default-value="state.inputDescriptionTag"
            :max-length="30"
            :caption="`${state.inputDescriptionTag.length}/30`"
            :disabled="state.isSoldPlan"
            :error-message="state.tagsError"
            :is-submit-event="true"
            :is-focus-event="true"
            @updateData="(value) => actions.updateDescriptionTag(value)"
            @submitEvent="(data) => actions.addPlanDescription(data)"
            @focusEvent="actions.focusEvent()"
          ></input-basic>

          <!--         note 스타일 임시 수정 -->
          <div
            v-if="state.showDescriptionInputGuide"
            class="sub-text-s3"
            :style="{
              position: !state.tagsError ? 'absolute' : '',
              top: !state.tagsError ? '56px' : '',
              marginTop: !state.tagsError ? '4px' : '',
            }"
          >
            <span class="text-purple-20">입력</span
            ><span>하려면 엔터(Enter) 키를 눌러주세요</span>
          </div>

          <div class="desc-list">
            <badge-hash-tag
              v-for="(tag, index) in state.planForm.descriptions"
              :key="`plan-description-tag-${index}`"
              class="tag-item"
              :text="tag"
              @delete="
                state.isSoldPlan ? '' : actions.updatePlanDescriptions(index)
              "
            ></badge-hash-tag>
          </div>
        </div>
      </div>

      <div class="price-area">
        <label class="sub-text-s2 currency"> 가격 (KRW) </label>
        <div class="price-inputs">
          <input-money-v3
            class="list-price"
            label="정가"
            locale="ko-KR"
            :value="state.planForm.prices.KRW.listPrice"
            :disabled="state.isSoldPlan"
            :is-focus-out-event="true"
            placeholder="정가를 입력해주세요."
            @updateData="
              (value) => actions.updatePrice('KRW', 'listPrice', value)
            "
          ></input-money-v3>
          <input-money-v3
            class="price"
            label="판매가"
            locale="ko-KR"
            :required="true"
            :value="state.planForm.prices.KRW.price"
            :discount="state.discount"
            :disabled="state.isSoldPlan"
            :is-focus-out-event="true"
            placeholder="판매가를 입력해주세요."
            @updateData="(value) => actions.updatePrice('KRW', 'price', value)"
          ></input-money-v3>
        </div>
        <p
          v-if="state.priceError"
          class="sub-text-s3 text-red-50 error-message"
        >
          {{ state.priceError }}
        </p>
        <template v-if="state.planForm.isDelivery">
          <div class="delivery-inputs">
            <div class="price-inputs">
              <input-money-v3
                label="국내 배송비"
                locale="ko-KR"
                :value="state.planForm.prices.KRW.deliveryFee"
                :disabled="state.isSoldPlan"
                :is-focus-out-event="true"
                placeholder="국내 배송비를 입력해주세요."
                @updateData="
                  (value) => actions.updatePrice('KRW', 'deliveryFee', value)
                "
              ></input-money-v3>
              <input-money-v3
                label="해외 배송비"
                locale="ko-KR"
                :value="state.planForm.prices.KRW.deliveryFeeUs"
                :disabled="state.isSoldPlan"
                :is-focus-out-event="true"
                placeholder="해외 배송비를 입력해주세요."
                @updateData="
                  (value) => actions.updatePrice('KRW', 'deliveryFeeUs', value)
                "
              ></input-money-v3>
            </div>
          </div>
        </template>
        <div class="detail-option">
          <div>
            <label class="sub-text-s2"> 배송 상품 </label>
            <p class="text-gray-second sub-text-s3"></p>
          </div>
          <input-switch v-model="state.planForm.isDelivery"></input-switch>
        </div>
        <div class="detail-option">
          <div>
            <label class="sub-text-s2">외화 설정 - USD</label>
          </div>

          <input-switch v-model="state.isCurrencyUSD"></input-switch>
        </div>
        <template v-if="state.isCurrencyUSD">
          <label class="sub-text-s2 currency"> 가격 (USD) </label>
          <div class="price-inputs">
            <input-money-v3
              class="list-price"
              label="정가"
              locale="en-US"
              :value="state.planForm.prices.USD.listPrice"
              :disabled="state.isSoldPlan"
              :is-focus-out-event="true"
              placeholder="정가를 입력해주세요."
              @updateData="
                (value) => actions.updatePrice('USD', 'listPrice', value)
              "
            ></input-money-v3>
            <input-money-v3
              class="price"
              label="판매가"
              locale="en-US"
              :required="true"
              :value="state.planForm.prices.USD.price"
              :discount="state.usdDiscount"
              :disabled="state.isSoldPlan"
              :is-focus-out-event="true"
              placeholder="판매가를 입력해주세요."
              @updateData="
                (value) => actions.updatePrice('USD', 'price', value)
              "
            ></input-money-v3>
          </div>
          <p
            v-if="state.priceErrorUSD"
            class="sub-text-s3 text-red-50 error-message"
          >
            {{ state.priceErrorUSD }}
          </p>
        </template>
        <template v-if="state.isCurrencyUSD && state.planForm.isDelivery">
          <div class="delivery-inputs">
            <div class="price-inputs">
              <input-money-v3
                label="국내 배송비"
                locale="en-US"
                :value="state.planForm.prices.USD.deliveryFee"
                :disabled="state.isSoldPlan"
                :is-focus-out-event="true"
                placeholder="국내 배송비를 입력해주세요."
                @updateData="
                  (value) => actions.updatePrice('USD', 'deliveryFee', value)
                "
              ></input-money-v3>
              <input-money-v3
                label="해외 배송비"
                locale="en-US"
                :value="state.planForm.prices.USD.deliveryFeeUs"
                :disabled="state.isSoldPlan"
                :is-focus-out-event="true"
                placeholder="해외 배송비를 입력해주세요."
                @updateData="
                  (value) => actions.updatePrice('USD', 'deliveryFeeUs', value)
                "
              ></input-money-v3>
            </div>
          </div>
        </template>
        <div class="detail-option">
          <div>
            <label class="sub-text-s2">외화 설정 - JPY</label>
          </div>

          <input-switch v-model="state.isCurrencyJPY"></input-switch>
        </div>
        <template v-if="state.isCurrencyJPY">
          <label class="sub-text-s2 currency"> 가격 (JPY) </label>
          <div class="price-inputs">
            <input-money-v3
              class="list-price"
              label="정가"
              locale="ja-JP"
              :value="state.planForm.prices.JPY.listPrice"
              :disabled="state.isSoldPlan"
              :is-focus-out-event="true"
              placeholder="정가를 입력해주세요."
              @updateData="
                (value) => actions.updatePrice('JPY', 'listPrice', value)
              "
            ></input-money-v3>
            <input-money-v3
              class="price"
              label="판매가"
              locale="ja-JP"
              :required="true"
              :value="state.planForm.prices.JPY.price"
              :discount="state.jpyDiscount"
              :disabled="state.isSoldPlan"
              :is-focus-out-event="true"
              placeholder="판매가를 입력해주세요."
              @updateData="
                (value) => actions.updatePrice('JPY', 'price', value)
              "
            ></input-money-v3>
          </div>
          <p
            v-if="state.priceErrorJPY"
            class="sub-text-s3 text-red-50 error-message"
          >
            {{ state.priceErrorJPY }}
          </p>
        </template>
        <template v-if="state.isCurrencyJPY && state.planForm.isDelivery">
          <div class="delivery-inputs">
            <div class="price-inputs">
              <input-money-v3
                label="국내 배송비"
                locale="ja-JP"
                :value="state.planForm.prices.JPY.deliveryFee"
                :disabled="state.isSoldPlan"
                :is-focus-out-event="true"
                placeholder="국내 배송비를 입력해주세요."
                @updateData="
                  (value) => actions.updatePrice('JPY', 'deliveryFee', value)
                "
              ></input-money-v3>
              <input-money-v3
                label="해외 배송비"
                locale="ja-JP"
                :value="state.planForm.prices.JPY.deliveryFeeUs"
                :disabled="state.isSoldPlan"
                :is-focus-out-event="true"
                placeholder="해외 배송비를 입력해주세요."
                @updateData="
                  (value) => actions.updatePrice('JPY', 'deliveryFeeUs', value)
                "
              ></input-money-v3>
            </div>
          </div>
        </template>
        <div class="detail-option">
          <div>
            <label class="sub-text-s2">외화 설정 - THB</label>
          </div>

          <input-switch v-model="state.isCurrencyTHB"></input-switch>
        </div>
        <template v-if="state.isCurrencyTHB">
          <label class="sub-text-s2 currency"> 가격 (THB) </label>
          <div class="price-inputs">
            <input-money-v3
              class="list-price"
              label="정가"
              locale="th"
              :value="state.planForm.prices.THB.listPrice"
              :disabled="state.isSoldPlan"
              :is-focus-out-event="true"
              placeholder="정가를 입력해주세요."
              @updateData="
                (value) => actions.updatePrice('THB', 'listPrice', value)
              "
            ></input-money-v3>
            <input-money-v3
              class="price"
              label="판매가"
              locale="th"
              :required="true"
              :value="state.planForm.prices.THB.price"
              :discount="state.thbDiscount"
              :disabled="state.isSoldPlan"
              :is-focus-out-event="true"
              placeholder="판매가를 입력해주세요."
              @updateData="
                (value) => actions.updatePrice('THB', 'price', value)
              "
            ></input-money-v3>
          </div>
          <p
            v-if="state.priceErrorTHB"
            class="sub-text-s3 text-red-50 error-message"
          >
            {{ state.priceErrorTHB }}
          </p>
        </template>
        <template v-if="state.isCurrencyTHB && state.planForm.isDelivery">
          <div class="delivery-inputs">
            <div class="price-inputs">
              <input-money-v3
                label="국내 배송비"
                locale="th"
                :value="state.planForm.prices.THB.deliveryFee"
                :disabled="state.isSoldPlan"
                :is-focus-out-event="true"
                placeholder="국내 배송비를 입력해주세요."
                @updateData="
                  (value) => actions.updatePrice('THB', 'deliveryFee', value)
                "
              ></input-money-v3>
              <input-money-v3
                label="해외 배송비"
                locale="th"
                :value="state.planForm.prices.THB.deliveryFeeUs"
                :disabled="state.isSoldPlan"
                :is-focus-out-event="true"
                placeholder="해외 배송비를 입력해주세요."
                @updateData="
                  (value) => actions.updatePrice('THB', 'deliveryFeeUs', value)
                "
              ></input-money-v3>
            </div>
          </div>
        </template>
      </div>

      <hr class="hr-third" />
      <div v-show="state.isSelectedPoll">
        <div class="poll-inputs">
          <label class="sub-text-s2"
            >판매할 투표권 수<span class="text-purple-50">*</span></label
          >
          <input-basic-new
            type="number"
            min="0"
            :default-value="state.planForm.suffrageQuantity"
            :error-message="state.suffrageQuantityError"
            @update-data="(value) => actions.updateSuffrageQuantity(value)"
          ></input-basic-new>
        </div>
        <hr class="hr-third" />
      </div>

      <button-accordion @toggleContents="actions.toggleDetailOptionForm()">
        <template #button>
          <button-text
            text="고급 옵션"
            text-size="s2"
            :is-icon="true"
            icon-position="back"
          >
            <template #icon>
              <arrow-icon
                :direction="state.showDetailOptionsForm ? 'up' : 'down'"
              ></arrow-icon>
            </template>
          </button-text>
        </template>
        <template #contents>
          <div class="detail-option">
            <div>
              <label class="sub-text-s2"> 얼리버드 </label>
              <p class="text-gray-second sub-text-s3">
                판매 중인 상품에 얼리버드 태그가 노출됩니다.
              </p>
            </div>
            <input-switch v-model="state.planForm.isEarlyBird"></input-switch>
          </div>

          <div class="detail-option">
            <div>
              <label class="sub-text-s2"> 시청 기한 (일) </label>
              <p class="text-gray-second sub-text-s3">
                구매자들이 구매일로부터 콘텐츠를 시청할 수 있는 기간을
                설정합니다. 미설정 시 90일 동안 시청할 수 있습니다.
              </p>
            </div>

            <input-basic
              type="number"
              placeholder=""
              :default-value="state.planForm.contentExpiredDay"
              :disabled="state.isSoldPlan"
              :error-message="state.durationError"
              @updateData="(value) => actions.updatePlanDuration(value)"
            ></input-basic>
          </div>

          <div class="detail-option">
            <div>
              <label class="sub-text-s2">구매 인원 제한</label>
              <p class="text-gray-second sub-text-s3">
                인원 제한 설정 시 해당 수량만큼만 판매 가능합니다.
              </p>
            </div>

            <input-switch v-model="state.isLimitCount"></input-switch>
          </div>

          <input-basic
            type="number"
            placeholder="구매 제한 인원 수 입력해 주세요."
            :disabled="state.isSoldPlan || !state.isLimitCount"
            :default-value="state.planForm.limitCount"
            :error-message="state.limitCountError"
            @updateData="(value) => actions.updatePlanLimitCount(value)"
          ></input-basic>
        </template>
      </button-accordion>

      <hr class="hr-third" />
      <label class="sub-text-s2">구성품 별 개수</label>
      <div class="product-descriptions">
        <p class="text-gray-second sub-text-s3">
          '상품명:판매개수||상품명:판매개수'로 기입해주셔야 합니다.
        </p>
        <p class="text-gray-second sub-text-s3">
          상품이 1개인 경우 '포토카드:10'
        </p>
        <p class="text-gray-second sub-text-s3">
          상품이 n개인 경우 '포토카드:5||라이브:25|| ... ||VOD:10'
        </p>
      </div>
      <input-basic-new
        :default-value="state.planForm.productDescription"
        @update-data="(value) => actions.updateProductDescription(value)"
      ></input-basic-new>

      <hr class="hr-third" />

      <div class="contents">
        <label class="sub-text-s2"> 포함될 콘텐츠 </label>
        <p class="text-gray-second sub-text-s3">
          구매자들이 시청할 수 있는 콘텐츠를 선택해주세요. 티켓별로 다르게
          설정할 수 있습니다.
        </p>
      </div>

      <ul class="selectable-sessions">
        <li
          v-for="(session, index) in state.sessions"
          :key="`selectable-session-${session.resourceId}`"
        >
          <selectable-session-item
            :club="club"
            :session="session"
            :number="index + 1"
            :plan="plan"
            @connectSession="actions.connectSession(session)"
            @unConnectSession="actions.unConnectSession(session)"
          ></selectable-session-item>
        </li>
      </ul>
    </template>

    <template #modalFooter>
      <div class="button-wrapper">
        <button-basic
          class="cancel"
          color="#0d0d0d"
          bg-color="#ECF1F4"
          text="취소"
          text-size="s1"
          @action="actions.closeModal()"
        ></button-basic>

        <button-basic
          class="save"
          :text="plan ? '수정' : '등록'"
          :disabled="state.isSaving"
          text-size="s1"
          @action="
            plan
              ? actions.createEditPlan('edit')
              : actions.createEditPlan('post')
          "
        ></button-basic>
      </div>
    </template>
  </base-modal-side>
</template>

<script>
import { computed, onBeforeMount, reactive, ref, watch } from "vue";
import InputBasic from "../../../../components/console/inputs/InputBasic";
import InputSwitch from "../../../../components/console/inputs/InputSwitch";
import SelectableSessionItem from "../SelectableSessionItem/SelectableSessionItem";
import BadgeHashTag from "../../../../components/console/badges/BadgeHashTag";
import { useStore } from "vuex";
import swal from "../../../../helper/swal";
import InputMoneyV3 from "@/components/console/inputs/InputMoneyV3.vue";
import moment from "moment-timezone";
import BaseModalSide from "../../../../components/common/BaseModalSide.vue";
import { isSoldPlan, planListPrice, planPrice } from "@/helper/plan";
import ButtonAccordion from "@/components/console/buttons/ButtonAccordion.vue";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import InputBasicNew from "@/components/console/inputs/InputBasicNew.vue";

export default {
  name: "PlanModal",
  components: {
    ButtonBasic,
    ArrowIcon,
    ButtonText,
    ButtonAccordion,
    BaseModalSide,
    InputMoneyV3,
    BadgeHashTag,
    SelectableSessionItem,
    InputSwitch,
    InputBasic,
    InputBasicNew,
  },
  props: {
    club: {
      type: Object,
      required: true,
    },
    plan: {
      type: Object,
      required: false,
    },
  },
  emits: ["openPlanListModal", "saveComplete", "hideModal"],
  setup(props, { emit }) {
    const modalContainer = ref();

    const store = useStore();

    const state = reactive({
      title: computed(() => {
        return props.plan ? "티켓 수정" : "티켓 추가";
      }),
      description: computed(() => {
        return props.plan
          ? "여러 가지의 티켓을 만들어 각각 옵션을 다르게 설정할 수 있습니다."
          : "판매가를 설정하고 티켓 구매자들이 시청할 수 있는 콘텐츠를 선택할 수 있습니다.";
      }),
      planForm: {
        title: "",
        prices: {
          KRW: {
            currency: "KRW",
            listPrice: 0,
            price: 0,
            deliveryFee: 0,
            deliveryFeeUs: 0,
          },
          USD: {
            currency: "USD",
            listPrice: 0,
            price: 0,
            deliveryFee: 0,
            deliveryFeeUs: 0,
          },
          JPY: {
            currency: "JPY",
            listPrice: 0,
            price: 0,
            deliveryFee: 0,
            deliveryFeeUs: 0,
          },
          THB: {
            currency: "THB",
            listPrice: 0,
            price: 0,
            deliveryFee: 0,
            deliveryFeeUs: 0,
          },
        },
        contentExpiredDay: 90,
        descriptions: [],
        isPrimary: false,
        isEarlyBird: false,
        limitCount: null,
        contentResourceIds: [],
        meetingResourceIds: [],
        pollResourceIds: [],
        isDelivery: false,
        suffrageQuantity: 0,
        productDescription: null,
      },
      sessions: computed(() => {
        return store.getters["newClubs/newClubSessions"];
      }),
      isLimitCount: false,
      isSelectSession: false,
      isDuration: true,
      showDetailOptionsForm: false,
      discount: computed(() => {
        let listPrice = state.planForm.prices.KRW.listPrice;
        let price = state.planForm.prices.KRW.price;

        if (!!listPrice && !!price && Number(listPrice) > Number(price)) {
          let discount = ((listPrice - price) / listPrice) * 100;
          return Math.round(discount) + "%";
        }

        return "";
      }),
      usdDiscount: computed(() => {
        let listPrice = state.planForm.prices.USD.listPrice;
        let price = state.planForm.prices.USD.price;

        if (!!listPrice && !!price && Number(listPrice) > Number(price)) {
          let discount = ((listPrice - price) / listPrice) * 100;
          return Math.round(discount) + "%";
        }

        return "";
      }),
      jpyDiscount: computed(() => {
        let listPrice = state.planForm.prices.JPY.listPrice;
        let price = state.planForm.prices.JPY.price;

        if (!!listPrice && !!price && Number(listPrice) > Number(price)) {
          let discount = ((listPrice - price) / listPrice) * 100;
          return Math.round(discount) + "%";
        }

        return "";
      }),
      thbDiscount: computed(() => {
        let listPrice = state.planForm.prices.THB.listPrice;
        let price = state.planForm.prices.THB.price;

        if (!!listPrice && !!price && Number(listPrice) > Number(price)) {
          let discount = ((listPrice - price) / listPrice) * 100;
          return Math.round(discount) + "%";
        }

        return "";
      }),
      titleError: "",
      priceError: "",
      priceErrorUSD: "",
      priceErrorJPY: "",
      priceErrorTHB: "",
      tagsError: "",
      limitCountError: "",
      durationError: "",
      suffrageQuantityError: "",
      inputDescriptionTag: "",
      completeBeforeMount: false,
      showDescriptionInputGuide: false,
      isSoldPlan: computed(() => {
        return props.plan ? isSoldPlan(props.plan) : false;
      }),
      isSaving: false,
      isCurrencyUSD: false,
      isCurrencyJPY: false,
      isCurrencyTHB: false,
      isSelectedPoll: computed(() => {
        let pollResourceIds = state.planForm.pollResourceIds;
        return pollResourceIds.length > 0;
      }),
    });

    watch(
      () => state.tagsError,
      (value) => {
        if (value) {
          state.showDescriptionInputGuide = false;
        } else {
          state.showDescriptionInputGuide = true;
        }
      }
    );

    const checkIsCurrency = (currency) => {
      if (
        props.plan.prices[`${currency}`]?.["listPrice"] >= 0 ||
        props.plan.prices[`${currency}`]?.["deliveryFee"] ||
        props.plan.prices[`${currency}`]?.["deliveryFeeUs"]
      ) {
        state[`isCurrency${currency}`] = true;
      }
    };

    const validateEnteredCurrencyPrice = (currency) => {
      if (state[`isCurrency${currency}`]) {
        if (
          state.planForm.prices.KRW.price > 0 &&
          state.planForm.prices[`${currency}`].price <= 0
        ) {
          state[`priceError${currency}`] = "판매가를 입력해주세요.";
          state.isSaving = false;
          return false;
        }

        state[`priceError${currency}`] = "";

        if (
          state.planForm.prices[`${currency}`].price > 0 &&
          state.planForm.prices.KRW.price <= 0
        ) {
          state.priceError = "판매가를 입력해주세요.";
          state.isSaving = false;
          return false;
        }

        state.priceError = "";
      }
      return true;
    };

    const deleteCurrencyPrice = (currency) => {
      if (!state[`isCurrency${currency}`]) {
        delete state.planForm.prices[`${currency}`];
      }
    };

    onBeforeMount(() => {
      if (props.plan) {
        state.planForm.title = props.plan.title;
        state.planForm.descriptions = [...props.plan.descriptions];
        state.planForm.prices = {
          ...state.planForm.prices,
          ...props.plan.prices,
        };
        state.planForm.isEarlyBird = props.plan.isEarlyBird;
        state.planForm.limitCount = props.plan.limitCount;
        state.planForm.isDelivery = props.plan.isDelivery;
        state.planForm.suffrageQuantity = props.plan.suffrageQuantity;
        state.planForm.productDescription = props.plan.productDescription;

        checkIsCurrency("USD");
        checkIsCurrency("JPY");
        checkIsCurrency("THB");

        if (props.plan.contentExpiredDay) {
          state.isDuration = true;
          state.planForm.contentExpiredDay = props.plan.contentExpiredDay;
        }

        state.isLimitCount = props.plan.limitCount > 0;

        if (props.plan.contents.length > 0) {
          props.plan.contents.forEach((content) => {
            state.planForm.contentResourceIds.push(content.resourceId);
          });
        }

        if (props.plan.meetings.length > 0) {
          props.plan.meetings.forEach((meeting) => {
            state.planForm.meetingResourceIds.push(meeting.resourceId);
          });
        }

        if (props.plan.polls.length > 0) {
          props.plan.polls.forEach((poll) => {
            state.planForm.pollResourceIds.push(poll.resourceId);
          });
        }

        state.showDetailOptionsForm = true;
      }
      //note 플랜 폼 세팅
      else {
        state.planForm.isEarlyBird = false;
        state.isLimitCount = false;

        state.planForm.prices.KRW.price = 5000;
        // state.planForm.prices.KRW.price = props.club?.theme?.holderPriceKRW;
        state.isDuration = true;
      }

      state.completeBeforeMount = true;
    });

    watch(
      () => [state.isLimitCount],
      (changes) => {
        if (changes[0] && !state.planForm.limitCount) {
          state.planForm.limitCount = 100;
        }
        if (!changes[0]) {
          state.planForm.limitCount = null;
        }
      }
    );

    const isNumber = (value) => {
      return !isNaN(Number(value)) && value !== "";
    };

    const actions = {
      closeModal: () => {
        if (props.plan) {
          emit("openPlanListModal");
        } else {
          emit("hideModal");
        }
      },
      addPlanDescription: (data) => {
        if (state.planForm.descriptions.length < 3) {
          state.planForm.descriptions.push(data);
        } else {
          state.tagsError = "최대 3개 까지만 등록 가능 합니다.";
          setTimeout(() => {
            if (state.tagsError) {
              state.tagsError = "";
            }
          }, 2000);
        }
        state.inputDescriptionTag = "";
      },
      updatePlanDescriptions: (index) => {
        state.planForm.descriptions.splice(index, 1);
      },
      createEditPlan: (method) => {
        if (!state.isSaving) {
          state.isSaving = true;
          const isPost = method === "post";

          //공통
          // let listPrice = state.planForm.prices.KRW.listPrice;
          // let price = state.planForm.prices.KRW.price;

          //공통
          if (!state.planForm.title) {
            state.titleError = "티켓명은 필수입니다.";
            state.isSaving = false;
            return;
          }

          //공통
          if (state.planForm.descriptions.length === 0) {
            state.tagsError = "구성소개는 필수입니다.";
            state.isSaving = false;
            return;
          }

          //공통
          // note 진진님 요청으로 제한 해제
          // if (!price) {
          //   state.priceError = "판매가는 필수입니다.";
          //   state.isSaving = false;
          //   return;
          // }
          //
          // if (price < 5000) {
          //   state.priceError = "판매가는 5,000원 이상부터 가능합니다.";
          //   state.isSaving = false;
          //   return;
          // }
          //
          // if (!!listPrice && !!price && Number(listPrice) <= Number(price)) {
          //   state.priceError = "판매가는 정가보다 작아야합니다.";
          //   state.isSaving = false;
          //   return "";
          // }

          if (
            isPost &&
            state.isLimitCount &&
            (state.planForm.limitCount <= 0 || !state.planForm.limitCount)
          ) {
            state.limitCountError = " 1명 이상 입력하세요.";
            state.isSaving = false;
            return;
          }

          // note 수정시 진진님 요청으로 제한 해제
          // if (
          //   state.isLimitCount &&
          //   (state.planForm.limitCount <= props.plan.paymentOrderCount ||
          //     !state.planForm.limitCount)
          // ) {
          //   console.log("editPlan-state.isLimitCount");
          //   state.limitCountError = "1명 이상 입력하세요.";
          //   state.isSaving = false;
          //   return;
          // }

          //공통
          if (state.isDuration && !state.planForm.contentExpiredDay) {
            state.durationError = "시청기한은 1일 이상이어야 합니다.";
            state.isSaving = false;
            return;
          }

          // 공통
          const isUSDError = validateEnteredCurrencyPrice("USD");
          if (!isUSDError) {
            return;
          }

          const isJPYError = validateEnteredCurrencyPrice("JPY");
          if (!isJPYError) {
            return;
          }

          const isTHBError = validateEnteredCurrencyPrice("THB");
          if (!isTHBError) {
            return;
          }

          if (isPost) {
            //post
            if (state.planForm.pollResourceIds.length > 0) {
              if (!isNumber(state.planForm.suffrageQuantity)) {
                state.planForm.suffrageQuantityError = "숫자만 입력해주세요.";
                return;
              } else {
                state.planForm.suffrageQuantity = Number(
                  state.planForm.suffrageQuantity
                );
              }
            }

            //공통
            if (!state.isDuration) {
              state.planForm.contentExpiredDay = null;
            }

            //공통
            if (!state.isLimitCount) {
              state.planForm.limitCount = null;
            }

            //공통
            if (!state.isCurrencyUSD) {
              delete state.planForm.prices.USD;
            }

            if (!state.isCurrencyTHB) {
              delete state.planForm.prices.THB;
            }

            if (!state.isCurrencyJPY) {
              delete state.planForm.prices.JPY;
            }

            const payload = {
              ...state.planForm,
              ...{
                prices: JSON.stringify(state.planForm.prices),
                startAt: moment().toJSON(),
              },
            };

            store
              .dispatch("newClubs/postPlan", {
                clubResourceId: props.club.resourceId,
                data: payload,
              })
              .then(() => {
                state.titleError = "";
                state.priceError = "";

                swal.successToast("티켓이 생성되었습니다.");
                emit("saveComplete");
                state.isSaving = false;
              });
          } else {
            if (state.planForm.pollResourceIds.length > 0) {
              if (!isNumber(state.planForm.suffrageQuantity)) {
                state.planForm.suffrageQuantityError = "숫자만 입력해주세요.";
                return;
              }
            }

            //공통
            if (!state.isDuration) {
              state.planForm.contentExpiredDay = null;
            }

            //공통
            if (!state.isLimitCount) {
              state.planForm.limitCount = null;
            }

            //공통
            if (!state.isCurrencyUSD) {
              delete state.planForm.prices.USD;
            }

            if (!state.isCurrencyTHB) {
              delete state.planForm.prices.THB;
            }

            if (!state.isCurrencyJPY) {
              delete state.planForm.prices.JPY;
            }

            const payload = {
              ...state.planForm,
              ...{
                prices: JSON.stringify(state.planForm.prices),
              },
            };

            store
              .dispatch("newClubs/putPlan", {
                clubResourceId: props.club.resourceId,
                planResourceId: props.plan.resourceId,
                data: payload,
              })
              .then(() => {
                swal.successToast("수정이 완료되었습니다.");
                state.isSaving = false;
                emit("saveComplete");
              });
          }
        }
      },
      updatePrice: (currency, key, value) => {
        state.planForm.prices[`${currency}`][`${key}`] = value;
      },
      updatePlanTitle: (value) => {
        state.planForm.title = value;
      },
      connectSession: (session) => {
        if (session.content) {
          state.planForm.contentResourceIds.push(session.content.resourceId);
        }

        if (session.meeting) {
          state.planForm.meetingResourceIds.push(session.meeting.resourceId);
        }

        if (session.poll) {
          state.planForm.pollResourceIds.push(session.poll.resourceId);
        }
      },
      unConnectSession: (session) => {
        if (session.content) {
          const index = state.planForm.contentResourceIds.findIndex((item) => {
            return item === session.content.resourceId;
          });
          state.planForm.contentResourceIds.splice(index, 1);
        }

        if (session.meeting) {
          const index = state.planForm.meetingResourceIds.findIndex((item) => {
            return item === session.meeting.resourceId;
          });
          state.planForm.meetingResourceIds.splice(index, 1);
        }

        if (session.poll) {
          const index = state.planForm.pollResourceIds.findIndex((item) => {
            return item === session.poll.resourceId;
          });
          state.planForm.pollResourceIds.splice(index, 1);
        }
      },
      updatePlanLimitCount: (value) => {
        state.planForm.limitCount = value;
        state.limitCountError = "";
      },
      updatePlanDuration: (value) => {
        state.planForm.contentExpiredDay = value;
      },
      toggleDetailOptionForm: () => {
        state.showDetailOptionsForm = !state.showDetailOptionsForm;
      },
      updateDescriptionTag: (value) => {
        state.inputDescriptionTag = value;
      },
      updateSuffrageQuantity: (value) => {
        state.planForm.suffrageQuantity = value;
      },
      updateProductDescription: (value) => {
        state.planForm.productDescription = value;
      },
      focusEvent: () => {
        state.showDescriptionInputGuide = true;
      },
    };

    return {
      state,
      actions,
      modalContainer,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
