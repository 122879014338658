<template>
  <div class="community-item" :class="{ active: active }">
    <span class="sub-text-s2">{{ community.title }}</span>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  name: "CommunityItem",
  props: {
    community: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const state = reactive({});

    return { state };
  },
};
</script>

<style scoped>
.community-item {
  padding: 15px 16px;
  cursor: pointer;
  border-radius: 4px;
}

.community-item.active,
.community-item:hover {
  background: #f6f8fa;
}
</style>
