<template>
  <div class="comment-input">
    <avatar size="32" :avatar-img="state.avatar"></avatar>

    <textarea
      ref="textArea"
      class="text-area b-text-2"
      :placeholder="placeholder"
      :value="state.text"
      @input="actions.updateComment($event)"
      @keydown.enter.exact.prevent="actions.onEnterSaveComment()"
      @keyup.enter.exact.prevent="actions.onEnterInitComment($event)"
    ></textarea>

    <div class="button-wrapper">
      <button
        class="cancel sub-title-s3 text-gray-second"
        @click="actions.onClickInitComment()"
      >
        취소
      </button>
      <button
        class="save sub-title-s3"
        :disabled="state.disabled"
        @click="actions.onClickSaveComment()"
      >
        {{ state.isEdit ? "수정" : "등록" }}
      </button>
    </div>
  </div>
</template>
<script>
import Avatar from "../../../../components/console/avatars/Avatar";
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
export default {
  components: { Avatar },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    parentResourceId: {
      type: String,
      required: true,
    },
    parent: {
      type: String,
      required: true,
      validator(value) {
        return ["articles", "comments"].includes(value);
      },
    },
    comment: {
      type: Object,
      required: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const textArea = ref();

    const state = reactive({
      isEdit: !!props.comment,
      avatar: computed(() => {
        return store.getters["auth/user"].avatar;
      }),
      text: "",
      disabled: computed(() => {
        return state.text.trim().length === 0;
      }),
      storeEndPoint: computed(() => {
        if (props.parent === "articles") {
          return "comments/postArticleComment";
        }
        // props.parent === "comments"
        return "comments/postArticleCommentComment";
      }),
      updateEndPoint: computed(() => {
        if (props.parent === "articles") {
          return "comments/putArticleComment";
        }
        // props.parent === "comments"
        return "comments/putArticleComment";
      }),
      isSaving: false,
    });

    onMounted(() => {
      if (state.isEdit) {
        state.text = props.comment.content;
        // resize();
      }
    });

    // const resize = () => {
    //   console.log("?");
    //   textArea.value.style.height = "46px";
    //   let taLineHeight = 18;
    //   let taHeight = textArea.value.scrollHeight;
    //   let numberOfLines = Math.floor((taHeight - 28) / 18);
    //   textArea.value.style.height = `${28 + taLineHeight * numberOfLines}px`;
    // };

    const initComment = (e = null) => {
      if (state.isEdit) {
        emit("toggleEditModal");
      } else {
        state.text = "";
      }

      if (e) {
        e.target.value = "";
      }
    };

    const saveComment = () => {
      if (!state.disabled && !state.isSaving) {
        state.isSaving = true;

        if (state.isEdit) {
          store
            .dispatch(state.updateEndPoint, {
              commentResourceId: props.comment.resourceId,
              content: state.text,
            })
            .then(() => {
              state.isSaving = false;
              emit("toggleEditModal");
            });
        } else {
          store
            .dispatch(state.storeEndPoint, {
              content: state.text,
              model: props.parent,
              resourceId: props.parentResourceId,
            })
            .then(() => {
              state.isSaving = false;
              // textArea.value.style.height = "46px";
            });
        }
      }
    };

    const actions = {
      updateComment: (e) => {
        state.text = e.target.value;
      },
      onClickRegisterComment: () => {
        if (!state.disabled && !state.isSaving) {
          state.isSaving = true;

          if (state.isEdit) {
            store
              .dispatch(state.updateEndPoint, {
                commentResourceId: props.comment.resourceId,
                content: state.text,
              })
              .then(() => {
                state.text = "";
                state.isSaving = false;
                emit("toggleEditModal");
              });
          } else {
            store
              .dispatch(state.storeEndPoint, {
                content: state.text,
                model: props.parent,
                resourceId: props.parentResourceId,
              })
              .then(() => {
                state.text = "";
                state.isSaving = false;
                // textArea.value.style.height = "46px";
              });
          }
        } else {
          state.text = "";
        }
      },
      preventSubmit: (e) => {
        e.preventDefault();
      },
      onClickInitComment: () => {
        initComment();
      },
      onEnterInitComment: (e) => {
        initComment(e);
      },
      onEnterSaveComment: () => {
        saveComment();
      },
      onClickSaveComment: () => {
        saveComment();
        initComment();
      },
    };

    return { state, actions, textArea };
  },
};
</script>

<style scoped src="./style.css"></style>
