<template>
  <div ref="trigger"></div>
</template>
<script>
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

export default defineComponent({
  name: "Observe",
  setup(props, { emit }) {
    const trigger = ref();
    const options = {
      root: null,
      threshold: 0.1,
    };
    let observer = null;
    const handleIntersect = (entry) => {
      if (entry.isIntersecting) emit("triggerIntersected", trigger.value);
    };

    onMounted(() => {
      try {
        observer = new IntersectionObserver((entries) => {
          handleIntersect(entries[0]);
        }, options);
        observer.observe(trigger.value);
      } catch (err) {
        console.error(err);
      }
    });

    onUnmounted(() => {
      observer.disconnect();
    });

    return {
      trigger,
    };
  },
});
</script>
