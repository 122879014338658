<template>
  <div class="faq-item-wrapper">
    <div v-show="isSorting && !faq.isFixed" class="event-barrier"></div>
    <button
      v-if="!faq.isFixed"
      class="handler-btn"
      :class="{ handle: !faq.isFixed }"
    >
      <handler-icon fill-color="#9EA0A6"></handler-icon>
    </button>
    <div v-if="!faq.isFixed" class="menu-dropdown-btn-wrapper">
      <button-dropdown distance="0" placement="left">
        <template #button>
          <button class="menu-dropdown-btn">
            <more-verti-icon width="16" height="16"></more-verti-icon>
          </button>
        </template>
        <template #dropdownList>
          <ul class="menu-dropdown-list">
            <li>
              <button
                v-close-popper
                class="item-dropdown-btn sub-text-s2"
                @click="actions.updateFaq()"
              >
                수정
              </button>
            </li>
            <li>
              <button
                v-close-popper
                class="item-dropdown-btn sub-text-s2 text-red-50"
                @click="actions.openDeleteModal()"
              >
                삭제
              </button>
            </li>
          </ul>
        </template>
      </button-dropdown>
    </div>
    <div>
      <div>
        <button-accordion @toggleContents="actions.toggleAccordion()">
          <template #button>
            <div
              class="question-section bg-gray-01"
              :class="{ open: state.openAccordion, 'edit-faq': !faq.isFixed }"
            >
              <div class="question-title">
                <span class="q-bullet sub-title-s1 text-default">Q.</span>

                <span class="question sub-title-s1 text-default">
                  {{ faq.localizations[0].question }}
                </span>
              </div>

              <span
                class="arrow-icon"
                @toggleContents="actions.toggleAccordion()"
              >
                <arrow-icon
                  :class="{ active: state.openAccordion }"
                  direction="down"
                  stroke-size="normal"
                ></arrow-icon>
              </span>
            </div>
          </template>
          <template #contents>
            <div class="answer-card faq-editor bg-gray-01">
              <div class="ql-editor">
                <p v-html="state.answerHtml"></p>
              </div>
            </div>
          </template>
        </button-accordion>
      </div>
    </div>
  </div>
  <teleport to="#teleport">
    <warning-modal
      v-if="state.showFaqDeleteModal"
      warning-title="해당 FAQ 를 삭제하시겠어요?"
      confirm-text="삭제"
      :warning-text="`${faq.localizations[0].question}을 삭제합니다.`"
      @confirm="actions.deleteFaq()"
      @hideModal="actions.closeFaqDeleteModal()"
    >
    </warning-modal>
  </teleport>
</template>

<script>
import { computed, reactive } from "vue";
import ButtonAccordion from "@/components/console/buttons/ButtonAccordion";
import { useStore } from "vuex";
import ArrowIcon from "@/components/console/icons/ArrowIcon";
import HandlerIcon from "@/components/console/icons/HandlerIcon";
import helper from "@/helper";
import WarningModal from "@/components/console/modals/WarningModal";
import MoreVertiIcon from "@/components/console/icons/MoreVertiIcon.vue";
import ButtonDropdown from "@/components/console/buttons/ButtonDropdown.vue";

export default {
  name: "FaqItem",
  components: {
    ButtonDropdown,
    MoreVertiIcon,
    WarningModal,
    HandlerIcon,
    ArrowIcon,
    ButtonAccordion,
  },
  props: {
    faq: {
      type: Object,
      require: true,
    },
    clubResourceId: {
      type: String,
      required: true,
    },
    isSorting: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["updateFaqs", "updateFaq", "openEditFaqModal"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      openAccordion: false,
      showFaqDeleteModal: false,
      answerHtml: computed(() => {
        return helper
          .nl2br(props.faq.localizations[0].answerHtml)
          .replaceAll("\\", "");
      }),
    });

    const actions = {
      toggleAccordion: () => {
        state.openAccordion = !state.openAccordion;
      },
      openDeleteModal: () => {
        state.showFaqDeleteModal = true;
      },
      closeFaqDeleteModal: () => {
        state.showFaqDeleteModal = false;
      },
      deleteFaq: () => {
        store
          .dispatch("newClubs/deleteClubFaq", {
            resourceId: props.faq.resourceId,
            clubResourceId: props.clubResourceId,
          })
          .then(() => {
            state.showFaqDeleteModal = false;
            emit("updateFaqs");
          });
      },
      updateFaq: () => {
        emit("openEditFaqModal", props.faq);
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
