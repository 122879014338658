<template>
  <input
    ref="inputTags"
    aria-placeholder=""
    :style="{ height: `${height}px` }"
    :class="{ 'custom-look': isCustomStyle }"
  />
</template>

<script>
import Tagify from "@yaireo/tagify/dist/tagify.min.js";
import "@yaireo/tagify/dist/tagify.css";
import { ref, onMounted, reactive, computed, onBeforeUnmount } from "vue";

export default {
  name: "InputTags",
  props: {
    placeholder: {
      type: String,
      default: "#hash-tag",
    },
    tags: {
      type: Array,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
    //태그 최대 갯수
    maxTags: {
      type: Number,
      default: 100,
    },
    isCustomStyle: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const inputTags = ref(null);
    const tagify = ref(null);

    const state = reactive({
      defaultTags: computed(() => {
        return props.tags.reduce((result, current) => {
          if (current) {
            result += current.val + ",";
          }

          return result;
        }, "");
      }),
    });

    const options = {
      placeholder: props.placeholder,
      maxTags: props.maxTags,
      callbacks: {
        add(e) {
          // 초기 tags 값 세팅할 때, 중복된값이 있는경우 addTag emit 이벤트 방지
          if (!state.defaultTags.includes(e.detail.data.value)) {
            emit("addTag", {
              index: e.detail.index,
              val: e.detail.data.value,
            });
          }
        },
        remove(e) {
          emit("removeTag", {
            index: e.detail.index,
            val: e.detail.data.value,
          });
        },
      },
    };

    onMounted(() => {
      tagify.value = new Tagify(inputTags.value, options);

      tagify.value.on("add", (e) => {
        // 태그가 한개 이상일 경우 placeholder 제거
        if (e.detail.tagify.value.length > 0) {
          e.detail.tagify.DOM.input.setAttribute("data-placeholder", "");
        }
      });
      tagify.value.on("remove", (e) => {
        if (e.detail.tagify.value.length === 0) {
          e.detail.tagify.DOM.input.setAttribute(
            "data-placeholder",
            props.placeholder
          );
        }
      });

      // 초기 tags 값 세팅
      if (props.tags) {
        tagify.value.addTags(state.defaultTags); // options.callbacks.add 이벤트 발생!!
      }
    });

    onBeforeUnmount(() => {
      tagify.value.destroy();
    });

    return { inputTags };
  },
};
</script>

<style>
.custom-look {
  --tag-bg: #f6f8fa;
  --tags-border-color: #f6f8fa;
  --tag-pad: 0.5em;
  border: 1px solid #e6eaef;
  border-radius: 4px;
  align-items: center;
  gap: 8px;
  padding: 8px;
}

.custom-look .tagify__tag {
  margin: 0;
}

.custom-look .tagify__input {
  margin: 0;
  padding: 0;
}

.custom-look .tagify__tag > div {
  border: 1px solid #f6f8fa;
  border-radius: 2px;
  font-size: 14px;
  line-height: 17.5px;
  font-weight: 500;
}

.custom-look .tagify__tag > div::before {
  border: inherit;
}
</style>
