<template>
  <icon-base
    icon-name="chat-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <template v-if="!fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.47698 9.16679L3.24338 9.75079L3.1437 10H6C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2C3.79086 2 2 3.79086 2 6C2 7.04292 2.39782 7.99063 3.05174 8.70333L3.47698 9.16679ZM2.7437 11H1.66667L2.06667 10L2.31491 9.3794C1.49838 8.48949 1 7.30295 1 6C1 3.23858 3.23858 1 6 1C8.44835 1 10.4857 2.75976 10.9162 5.08344C13.2402 5.5137 15.0002 7.55117 15.0002 9.99973C15.0002 11.3027 14.5018 12.4892 13.6853 13.3791L13.9335 13.9997L14.3335 14.9997H13.2565H10.0002C7.58135 14.9997 5.56366 13.2821 5.10029 11H2.7437ZM6.1259 10.9984C6.5695 12.7243 8.13591 13.9997 10.0002 13.9997H12.8565L12.7568 13.7505L12.5232 13.1665L12.9485 12.7031C13.6024 11.9904 14.0002 11.0426 14.0002 9.99973C14.0002 8.13525 12.7246 6.56873 10.9985 6.12529C10.933 8.78698 8.78751 10.9327 6.1259 10.9984ZM4.5 6.08301C4.5 6.35915 4.27614 6.58301 4 6.58301C3.72386 6.58301 3.5 6.35915 3.5 6.08301C3.5 5.80687 3.72386 5.58301 4 5.58301C4.27614 5.58301 4.5 5.80687 4.5 6.08301ZM6.5 6.08301C6.5 6.35915 6.27614 6.58301 6 6.58301C5.72386 6.58301 5.5 6.35915 5.5 6.08301C5.5 5.80687 5.72386 5.58301 6 5.58301C6.27614 5.58301 6.5 5.80687 6.5 6.08301ZM8 6.58301C8.27614 6.58301 8.5 6.35915 8.5 6.08301C8.5 5.80687 8.27614 5.58301 8 5.58301C7.72386 5.58301 7.5 5.80687 7.5 6.08301C7.5 6.35915 7.72386 6.58301 8 6.58301ZM11.5002 10.0827C11.5002 10.3589 11.7241 10.5827 12.0002 10.5827C12.2764 10.5827 12.5002 10.3589 12.5002 10.0827C12.5002 9.80659 12.2764 9.58273 12.0002 9.58273C11.7241 9.58273 11.5002 9.80659 11.5002 10.0827ZM9.50021 10.0827C9.50021 10.3589 9.72407 10.5827 10.0002 10.5827C10.2764 10.5827 10.5002 10.3589 10.5002 10.0827C10.5002 9.80659 10.2764 9.58273 10.0002 9.58273C9.72407 9.58273 9.50021 9.80659 9.50021 10.0827Z"
        :fill="fillColor"
        :stroke="fillColor"
        :stroke-width="state.strokeWidth"
      />
    </template>

    <template v-if="fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 6C11 8.76142 8.76142 11 6 11H1.66667L2.31491 9.3794C1.49838 8.48949 1 7.30295 1 6C1 3.23858 3.23858 1 6 1C8.76142 1 11 3.23858 11 6ZM4 6.58301C4.27614 6.58301 4.5 6.35915 4.5 6.08301C4.5 5.80687 4.27614 5.58301 4 5.58301C3.72386 5.58301 3.5 5.80687 3.5 6.08301C3.5 6.35915 3.72386 6.58301 4 6.58301ZM6 6.58301C6.27614 6.58301 6.5 6.35915 6.5 6.08301C6.5 5.80687 6.27614 5.58301 6 5.58301C5.72386 5.58301 5.5 5.80687 5.5 6.08301C5.5 6.35915 5.72386 6.58301 6 6.58301ZM8.5 6.08301C8.5 6.35915 8.27614 6.58301 8 6.58301C7.72386 6.58301 7.5 6.35915 7.5 6.08301C7.5 5.80687 7.72386 5.58301 8 5.58301C8.27614 5.58301 8.5 5.80687 8.5 6.08301Z"
        :fill="fillColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.0001 12.0001C9.31381 12.0001 12.0001 9.31381 12.0001 6.0001C12.0001 5.79862 11.9902 5.59946 11.9708 5.40308C13.7524 6.16782 15.0003 7.93809 15.0003 9.99983C15.0003 11.3028 14.5019 12.4893 13.6854 13.3792L14.3337 14.9998H10.0003C7.9388 14.9998 6.16868 13.7522 5.40381 11.9708C5.59995 11.9902 5.79887 12.0001 6.0001 12.0001ZM12.0003 10.5828C11.7242 10.5828 11.5003 10.359 11.5003 10.0828C11.5003 9.80669 11.7242 9.58284 12.0003 9.58284C12.2765 9.58284 12.5003 9.80669 12.5003 10.0828C12.5003 10.359 12.2765 10.5828 12.0003 10.5828Z"
        :fill="fillColor"
      />
    </template>
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "ChatIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#818287",
    },
    fill: {
      type: Boolean,
      default: false,
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
