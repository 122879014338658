<template>
  <icon-base
    icon-name="pin-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <template v-if="!fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.76726 4.93268L6.36731 4H9.63269L9.23274 4.93268C9.07159 5.30849 9.08009 5.7291 9.25632 6.09897L10.6386 9H8H5.36144L6.74368 6.09897C6.91991 5.7291 6.92841 5.30849 6.76726 4.93268ZM8.53602 10H11.4634C11.8513 10 12.1107 9.62757 11.9532 9.29693L10.236 5.69283C10.1772 5.56954 10.1744 5.42934 10.2281 5.30407L10.9221 3.6857C11.0629 3.35726 10.8036 3 10.4244 3H5.57559C5.19637 3 4.93706 3.35726 5.0779 3.6857L5.77189 5.30407C5.82561 5.42934 5.82277 5.56954 5.76403 5.69284L4.0468 9.29693C3.88926 9.62757 4.14873 10 4.53663 10H7.46398L7.46398 13.5C7.46398 13.7761 7.70396 14 8 14C8.29604 14 8.53602 13.7761 8.53602 13.5V10Z"
        :fill="fillColor"
        :stroke="fillColor"
        :stroke-width="state.strokeWidth"
      />
    </template>

    <template v-if="fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.57559 3C5.19637 3 4.93706 3.35726 5.0779 3.6857L5.77189 5.30407C5.82561 5.42934 5.82277 5.56954 5.76403 5.69284L4.0468 9.29693C3.88926 9.62757 4.14873 10 4.53663 10H7.46398L7.46398 13.5C7.46398 13.7761 7.70396 14 8 14C8.29604 14 8.53602 13.7761 8.53602 13.5V10H11.4634C11.8513 10 12.1107 9.62757 11.9532 9.29693L10.236 5.69283C10.1772 5.56954 10.1744 5.42934 10.2281 5.30407L10.9221 3.6857C11.0629 3.35726 10.8036 3 10.4244 3H5.57559Z"
        :fill="fillColor"
      />
    </template>
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "PinIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
    fill: {
      type: Boolean,
      default: false,
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
