<template>
  <icon-base
    icon-name="memo-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.75 15C12.75 14.5858 13.0858 14.25 13.5 14.25H18V4.5H6V19.5H12.75V15ZM13.5 21H5.4C4.90294 21 4.5 20.5971 4.5 20.1V3.9C4.5 3.40294 4.90294 3 5.4 3H18.6C19.0971 3 19.5 3.40294 19.5 3.9V15L13.5 21ZM14.25 18.1287L16.6287 15.75H14.25V18.1287Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.25 7.5C8.25 7.08579 8.58579 6.75 9 6.75H15C15.4142 6.75 15.75 7.08579 15.75 7.5C15.75 7.91421 15.4142 8.25 15 8.25H9C8.58579 8.25 8.25 7.91421 8.25 7.5Z"
      :fill="fillColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.25 11.25C8.25 10.8358 8.58579 10.5 9 10.5H15C15.4142 10.5 15.75 10.8358 15.75 11.25C15.75 11.6642 15.4142 12 15 12H9C8.58579 12 8.25 11.6642 8.25 11.25Z"
      :fill="fillColor"
    />
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
export default {
  name: "MemoIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "2 0 20 24",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
  },
};
</script>
