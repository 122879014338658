<template>
  <base-modal-side :is-dimmed="true" @closeModal="actions.closeModal()">
    <template #modalHeader>
      <div class="header">
        <h3>FAQ</h3>
        <p class="sub-text-s3 text-gray-second">
          라이브, 콘텐츠, 티켓에 관한 FAQ를 작성해주세요.
        </p>
      </div>
    </template>

    <template #modalBody>
      <div class="faq-modal-body">
        <div class="input-item">
          <input-basic-new
            label="FAQ 질문"
            placeholder="FAQ에 등록하실 질문을 작성해주세요."
            :error-message="state.errorMessages.question"
            :default-value="state.faqForm.question"
            @updateData="(value) => actions.updateFaqQuestion(value)"
          >
          </input-basic-new>
        </div>
        <div class="input-item">
          <quill-editor
            editor-id="faq"
            label="FAQ 답변"
            placeholder="등록하신 질문의 답변을 작성해주세요."
            :value="state.faqForm.answer"
            editor-container-height="375"
            :embeddable-video="false"
            :embeddable-image="false"
            :embeddable-divider="false"
            @updateValue="(value) => actions.updateFaqAnswer(value)"
          >
          </quill-editor>
        </div>
      </div>
    </template>

    <template #modalFooter>
      <div class="button-wrapper">
        <button-basic
          class="cancel"
          color="#0d0d0d"
          bg-color="#ECF1F4"
          text="취소"
          text-size="sub-title-s1"
          @action="actions.closeModal()"
        ></button-basic>

        <button-basic
          class="save"
          :text="state.isEditMode ? '수정' : '등록'"
          :disabled="!state.activeBtn || state.isSaveLoading"
          :is-loading="state.isSaveLoading"
          text-size="sub-title-s1"
          @action="actions.createFaq()"
        ></button-basic>
      </div>
    </template>
  </base-modal-side>
</template>

<script>
import { computed, onBeforeMount, reactive } from "vue";
import BaseModalSide from "../../../../components/common/BaseModalSide.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import InputBasicNew from "@/components/console/inputs/InputBasicNew.vue";
import QuillEditor from "@/components/console/editors/QuillEditor.vue";
import { useStore } from "vuex";

export default {
  name: "FaqModal",
  components: {
    QuillEditor,
    InputBasicNew,
    ButtonBasic,
    BaseModalSide,
  },
  props: {
    faq: {
      type: Object,
      required: false,
    },
    clubResourceId: {
      type: String,
      required: true,
    },
  },
  emits: ["saveComplete", "hideModal"],
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      isSaveLoading: false,
      faqForm: {
        question: "",
        answer: "",
        lang: "ko",
      },
      errorMessages: {
        question: "",
      },
      isEditMode: computed(() => {
        return props.faq;
      }),
      activeBtn: computed(() => {
        return (
          state.faqForm.question.trim().length &&
          state.faqForm.answer.trim().length
        );
      }),
    });

    onBeforeMount(() => {
      if (state.isEditMode) {
        state.faqForm.question = props.faq.localizations[0].question;
        state.faqForm.answer = props.faq.localizations[0].answer;
        state.faqForm.lang = props.faq.localizations[0].lang;
      }
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      createFaq: () => {
        if (state.faqForm.question.trim().length === 0) {
          state.errorMessages.question = "FAQ에 등록하실 질문을 작성해주세요.";
        }

        state.isSaveLoading = true;
        let payload = {
          localizations: [
            {
              lang: state.faqForm.lang,
              question: state.faqForm.question,
              answer: state.faqForm.answer,
            },
          ],
          model: "clubs",
          resourceId: props.clubResourceId,
        };
        if (state.isEditMode) {
          payload.localizations[0]["resourceId"] =
            props.faq.localizations[0].resourceId;
          store
            .dispatch("newClubs/putClubFaq", {
              clubResourceId: props.clubResourceId,
              resourceId: props.faq.resourceId,
              data: payload,
            })
            .then(() => {
              emit("saveComplete");
            });
        } else {
          store
            .dispatch("newClubs/postClubFaq", {
              clubResourceId: props.clubResourceId,
              data: payload,
            })
            .then(() => {
              emit("saveComplete");
            });
        }
      },
      updateFaqQuestion: (value) => {
        state.faqForm.question = value;
      },
      updateFaqAnswer: (value) => {
        state.faqForm.answer = value;
      },
    };

    return {
      state,
      actions,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
