<template>
  <div class="comment-comment">
    <div v-if="!state.isEditMode">
      <div class="writer">
        <avatar size="32" :avatar-img="comment.user.avatar"></avatar>
        <span class="sub-title-s3">{{ state.writerName }}</span>
        <span class="sub-text-s3 text-gray-second">
          {{ comment.createdAtHumans }}
        </span>
      </div>

      <p
        ref="commentElement"
        class="b-text-2"
        :class="{ fold: state.isFoldComment }"
        v-html="comment.contentHtml"
      ></p>

      <div v-if="state.overMinHeight" class="folder">
        <button
          v-if="state.isFoldComment"
          class="sub-title-s2 text-gray-second"
          @click="actions.toggleIsFoldComment()"
        >
          자세히 보기
        </button>
        <button
          v-if="!state.isFoldComment"
          class="sub-title-s2 text-gray-second"
          @click="actions.toggleIsFoldComment()"
        >
          간략히 보기
        </button>
      </div>

      <span
        class="comment-popularity sub-text-s3"
        @click="actions.likeComment()"
      >
        <good-icon
          :fill="comment.isLiked"
          :fill-color="comment.isLiked ? '#8E1EFF' : '#0D0D10'"
        ></good-icon>
        {{ comment.likersCount }}
      </span>

      <div class="menu-btn">
        <button-dropdown distance="-15">
          <template #button>
            <button class="dropdown-btn">
              <more-verti-icon fill-color="#000000"></more-verti-icon>
            </button>
          </template>
          <template #dropdownList>
            <ul class="dropdown-list">
              <li v-if="state.myComment">
                <button v-close-popper @click="actions.toggleEditMode()">
                  수정
                </button>
              </li>
              <li
                v-if="state.myComment"
                @click="actions.openCommentDeleteModal()"
              >
                <button v-close-popper>삭제</button>
              </li>
              <li
                v-if="!state.myComment"
                @click="actions.openDeclarationModal()"
              >
                <button v-close-popper>신고</button>
              </li>
            </ul>
          </template>
        </button-dropdown>
      </div>
    </div>

    <div v-if="state.isEditMode">
      <comment-input
        placeholder="댓글을 입력해주세요"
        parent="articles"
        parent-resource-id="parentResourceId"
        :comment="comment"
        @toggleEditModal="actions.toggleEditMode()"
      ></comment-input>
    </div>

    <teleport to="#teleport">
      <warning-modal
        v-if="state.showCommentDeleteModal"
        warning-title="댓글을 삭제할까요?"
        warning-text="삭제 후에는 복구 할 수 없어요."
        confirm-text="삭제"
        @hideModal="actions.closeCommentDeleteModal()"
        @confirm="actions.deleteComment()"
      ></warning-modal>

      <declaration-modal
        v-if="state.showDeclarationModal"
        model="comments"
        :model-resource-id="comment.resourceId"
        @hideModal="actions.closeDeclarationModal()"
        @reportSuccess="
          [actions.successReport(), actions.closeDeclarationModal()]
        "
      ></declaration-modal>
    </teleport>
  </div>
</template>
<script>
import Avatar from "../../../../components/console/avatars/Avatar";
import { computed, onMounted, reactive, ref } from "vue";
import helper from "@/helper";
import GoodIcon from "../../../../components/console/icons/GoodIcon";
import { useStore } from "vuex";
import MoreVertiIcon from "../../../../components/console/icons/MoreVertiIcon";
import CommentInput from "../CommentInput/CommentInput";
import WarningModal from "@/components/console/modals/WarningModal.vue";
import swal from "@/helper/swal";
import ButtonDropdown from "../../../../components/console/buttons/ButtonDropdown";
import DeclarationModal from "../DeclarationModal/DeclarationModal";

export default {
  name: "TwoDimensionComment",
  components: {
    ButtonDropdown,
    WarningModal,
    CommentInput,
    MoreVertiIcon,
    GoodIcon,
    Avatar,
    DeclarationModal,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const commentElement = ref();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      writerName: computed(() => {
        return helper.displayUserName(props.comment.user);
      }),
      overMinHeight: false,
      isFoldComment: false,
      myComment: computed(() => {
        return state.user.resourceId === props.comment.user.resourceId;
      }),
      isEditMode: false,
      showCommentDeleteModal: false,
      showDeclarationModal: false,
    });

    onMounted(() => {
      if (commentElement.value && commentElement.value.clientHeight > 55) {
        state.overMinHeight = true;
        state.isFoldComment = true;
      }
    });
    const actions = {
      likeComment: () => {
        store.dispatch("comments/postArticleCommentLike", {
          mode: "toggle",
          model: "comment",
          resourceId: props.comment.resourceId,
        });
      },
      toggleIsFoldComment: () => {
        state.isFoldComment = !state.isFoldComment;
      },
      toggleEditMode: () => {
        state.isEditMode = !state.isEditMode;
      },
      openCommentDeleteModal: () => {
        state.showCommentDeleteModal = true;
      },
      closeCommentDeleteModal: () => {
        state.showCommentDeleteModal = false;
      },
      deleteComment: () => {
        store
          .dispatch("comments/deleteArticleComment", {
            commentResourceId: props.comment.resourceId,
          })
          .then(() => {
            swal.deleteCompleteToast();
          });
      },
      openDeclarationModal: () => {
        state.showDeclarationModal = true;
      },
      closeDeclarationModal: () => {
        state.showDeclarationModal = false;
      },
      successReport: () => {
        state.showReportConfirmModal = true;
      },
    };

    return { state, actions, commentElement };
  },
};
</script>

<style src="./style.css" scoped></style>
