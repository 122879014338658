<template>
  <div class="plan-item">
    <handler-icon
      class="handle handler-icon"
      fill-color="#191B1D"
      width="24"
      height="24"
    ></handler-icon>

    <div class="buttons">
      <button-basic
        bg-color="transparent"
        hover-bg-color="#F5F7FA"
        padding="2px"
        @action="actions.editPlan()"
      >
        <template #icon>
          <edit-icon fill-color="#0d0d0d"></edit-icon>
        </template>
      </button-basic>

      <button-basic
        bg-color="transparent"
        padding="2px"
        hover-bg-color="#F5F7FA"
        :disabled="state.isSoldPlan"
        @action="actions.deletePlan()"
      >
        <template #icon>
          <delete-icon fill-color="#0d0d0d"></delete-icon>
        </template>
      </button-basic>
    </div>

    <div class="plan-content">
      <span v-if="plan.isEarlyBird" class="text-red-50 e-title-2">
        EARLY BIRD
      </span>
      <h4>{{ plan.title }}</h4>
      <ul>
        <li
          v-for="(description, index) in plan.descriptions"
          :key="`plan-description-${index}`"
        >
          <img src="/assets/images/svg/ic_check.svg" />
          <span class="text-default">{{ description }}</span>
        </li>
      </ul>

      <div
        class="price-area"
        :class="{ 'exist-list__price': !!state.listPrice }"
      >
        <div
          v-if="!!state.listPrice"
          class="list-price sub-text-s2 text-gray-second"
        >
          <span>정상가</span>
          <span class="value">
            {{ state.listPrice }}
          </span>
        </div>
        <div class="price sub-text-s2 text-default">
          <span v-if="!!state.listPrice" class="b-text-1"> 혜택가 </span>
          <div class="values">
            <h5 v-if="!!state.listPrice" class="text-red-50">
              {{ state.discount }}
            </h5>
            <h5>{{ state.price }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import {
  planDiscount,
  planPrice,
  planListPrice,
  isSoldPlan,
} from "@/helper/plan";
import HandlerIcon from "@/components/console/icons/HandlerIcon.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import EditIcon from "@/components/console/icons/EditIcon.vue";
import DeleteIcon from "@/components/console/icons/DeleteIcon.vue";

export default {
  name: "PlanListModalItem",
  components: { DeleteIcon, EditIcon, ButtonBasic, HandlerIcon },
  props: {
    plan: {
      type: Object,
      required: true,
    },
  },
  emits: ["editPlan", "deletePlan"],
  setup(props, { emit }) {
    const state = reactive({
      price: computed(() => {
        return planPrice(props.plan);
      }),
      discount: computed(() => {
        return planDiscount(props.plan);
      }),
      planDescriptions: computed(() => {
        return props.plan.descriptions;
      }),
      listPrice: computed(() => {
        return planListPrice(props.plan);
      }),
      isSoldPlan: computed(() => {
        return isSoldPlan(props.plan);
      }),
    });

    const actions = {
      editPlan: () => {
        emit("editPlan", props.plan);
      },
      deletePlan: () => {
        emit("deletePlan", props.plan);
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
