<template>
  <base-modal-side :is-dimmed="true" @closeModal="actions.closeModal()">
    <template #modalHeader>
      <div class="header-section">
        <h3 class="title">{{ state.modalTitle }}</h3>
        <p class="sub-title sub-text-s2 text-gray-second">
          {{ state.modalDescription }}
        </p>
      </div>
    </template>

    <template #modalBody>
      <!-- 커뮤니티 목록 -->
      <div v-if="state.modalType === 'list'">
        <button class="add-btn sub-title-s1" @click="actions.showModalForm()">
          게시판 추가<plus-icon class="plus-icon"></plus-icon>
        </button>

        <div class="communities">
          <p class="sub-text-s2 text-gray-second">게시판</p>
          <ul>
            <draggable
              v-model="state.generalCommunitiesList"
              item-key="general-community-item"
              handle=".handle"
            >
              <template #item="{ element }">
                <li>
                  <div class="community-item" :title="element.title">
                    <handler-icon
                      fill-color="#818287"
                      class="handle handler-icon"
                    ></handler-icon>
                    <span class="name sub-text-s2">
                      {{ element.title }}
                    </span>
                    <edit-icon
                      class="pencil-icon"
                      fill-color="#0D0D10"
                      @click="actions.showModalForm(element)"
                    ></edit-icon>
                  </div>
                </li>
              </template>
            </draggable>
          </ul>
        </div>
      </div>

      <!--	커뮤니티 수정/생성 폼    -->
      <div v-if="state.modalType === 'form'">
        <community-form
          :community="state.selectedCommunity"
          @deleteCommunity="actions.showListForm()"
          @updateCommunityForm="
            (formData) => actions.setSelectedFormData(formData)
          "
        ></community-form>
      </div>
    </template>

    <template #modalFooter>
      <div class="btn-wrapper">
        <button class="cancel-btn sub-title-s1" @click="actions.cancel()">
          {{ state.footerButtonText1 }}
        </button>
        <button
          class="save-btn sub-title-s1 text-gray-reverse"
          :disabled="state.disabledSaveBtn"
          @click="actions.save()"
        >
          {{ state.footerButtonText2 }}
        </button>
      </div>
    </template>
  </base-modal-side>
</template>
<script>
import BaseModalSide from "../../../../components/common/BaseModalSide.vue";
import PlusIcon from "../../../../components/console/icons/PlusIcon";
import HandlerIcon from "../../../../components/console/icons/HandlerIcon";
import { computed, onMounted, reactive, watch } from "vue";
import EditIcon from "../../../../components/console/icons/EditIcon";
import draggable from "vuedraggable";
import { useStore } from "vuex";
import swal from "@/helper/swal";
import CommunityForm from "../CommunityForm/CommunityForm";

export default {
  name: "CommunitySettingModal",
  components: {
    CommunityForm,
    EditIcon,
    HandlerIcon,
    PlusIcon,
    BaseModalSide,
    draggable,
  },
  props: {
    communities: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const state = reactive({
      generalCommunitiesList: [],
      modalType: "list", // list or form
      selectedCommunity: null,
      modalTitle: computed(() => {
        if (state.modalType === "list") {
          return "게시판 설정";
        } else {
          return state.selectedCommunity ? "게시판 수정" : "게시판 추가";
        }
      }),
      modalDescription: computed(() => {
        if (state.modalType === "list") {
          return (
            "게시판의 이름, 설정, 순서 변경, 삭제 등 설정을 변경할 수 있어요.\n" +
            "라운지는 프리미엄 콘텐츠의 고유 커뮤니티이므로 개별 설정이 어려워요."
          );
        } else {
          return !state.selectedCommunity
            ? "일반 게시판 커뮤니티에 게시판을 추가합니다."
            : "게시판의 이름, 작성 권한, 공개 설정, 보기 형식을 수정할 수 있어요.";
        }
      }),
      footerButtonText1: computed(() => {
        return state.modalType === "form" ? "뒤로" : "취소";
      }),
      footerButtonText2: computed(() => {
        if (state.modalType === "list") {
          return "저장";
        } else {
          return state.selectedCommunity ? "수정" : "추가";
        }
      }),
      selectedFormData: {
        title: "",
        type: "list",
        isShow: false,
        isNotice: false,
      },
      disabledSaveBtn: computed(() => {
        return state.modalType === "form" && !state.selectedFormData.title;
      }),
    });

    watch(
      () => props.communities,
      (updatedCommunities) => {
        state.generalCommunitiesList = updatedCommunities.filter((item) => {
          return !item.clubResourceId;
        });
      }
    );

    onMounted(() => {
      state.generalCommunitiesList = props.communities.filter((item) => {
        return !item.clubResourceId;
      });
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      showModalForm: (community) => {
        if (community) {
          state.selectedCommunity = community;
        } else {
          state.selectedCommunity = null;
        }
        state.modalType = "form";
      },
      showListForm: () => {
        state.selectedCommunity = null;
        state.modalType = "list";
      },
      cancel: () => {
        if (state.modalType === "form") {
          state.selectedCommunity = null;
          state.modalType = "list";
        } else {
          emit("hideModal");
        }
      },
      setSelectedFormData: (formData) => {
        state.selectedFormData = { ...state.selectedFormData, ...formData };
      },
      save: async () => {
        if (state.modalType === "form") {
          if (!state.selectedFormData.title) {
            console.error("게시물 이름 필수값!!!");
            return;
          }

          if (state.selectedCommunity) {
            await store
              .dispatch("communities/putCommunity", {
                communityResourceId: state.selectedCommunity.resourceId,
                data: state.selectedFormData,
              })
              .then(() => {
                swal.editCompleteToast();
                state.modalType = "list";
              });
          } else {
            await store
              .dispatch("communities/postCommunity", state.selectedFormData)
              .then(() => {
                swal.createCompleteToast();
                state.modalType = "list";
              });
          }
        } else {
          let generalCommunityResourceIds = state.generalCommunitiesList.reduce(
            (result, current) => {
              result.push(current.resourceId);
              return result;
            },
            []
          );

          await store
            .dispatch("communities/updateCommunitiesOrder", {
              communityResourceIds: generalCommunityResourceIds,
            })
            .then(() => {
              swal.messageAlert("저장 되었습니다.");
              emit("hideModal");
            });
        }
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
