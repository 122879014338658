<template>
  <base-modal-side :is-dimmed="true" @closeModal="actions.closeModal()">
    <template #modalHeader>
      <div class="header">
        <h3>티켓 관리</h3>
        <p class="sub-text-s2 text-gray-second">
          여러 가지의 티켓을 만들어 각각 옵션을 다르게 설정할 수 있습니다.
        </p>
      </div>
    </template>

    <template #modalBody>
      <div class="plan-list-modal">
        <button-basic
          class="add-btn"
          text="티켓 추가"
          color="#0d0d0d"
          bg-color="#ffffff"
          border-color="#ecebf1"
          icon-position="front"
          :border="true"
          @action="actions.openPlanModal()"
        >
          <template #icon>
            <plus-icon></plus-icon>
          </template>
        </button-basic>

        <draggable
          v-model="state.plans"
          item-key="clubPlans"
          handle=".handle"
          @change="actions.sortPlans(state.plans)"
        >
          <template #item="{ element }">
            <plan-list-modal-item
              :plan="element"
              @editPlan="(plan) => actions.openPlanEditModal(plan)"
              @deletePlan="(plan) => actions.deletePlan(plan)"
            ></plan-list-modal-item>
          </template>
        </draggable>
      </div>
    </template>
    <template #modalFooter>
      <button-basic
        text="확인"
        color="#0d0d0d"
        bg-color="#ECF1F4"
        @action="actions.confirm()"
      ></button-basic>
    </template>
  </base-modal-side>
</template>

<script>
import { reactive, ref } from "vue";
import draggable from "vuedraggable";
import { useStore } from "vuex";
import swal from "@/helper/swal";
import BaseModalSide from "../../../../components/common/BaseModalSide.vue";
import PlanListModalItem from "../PlanListModalItem/PlanListModalItem";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import PlusIcon from "@/components/console/icons/PlusIcon.vue";

export default {
  name: "PlanListModal",
  components: {
    PlusIcon,
    ButtonBasic,
    PlanListModalItem,
    BaseModalSide,
    draggable,
  },
  props: {
    club: {
      type: Object,
      required: true,
    },
    plans: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const detailOptionForm = ref();
    const store = useStore();

    const state = reactive({
      plans: props.plans,
    });

    const actions = {
      confirm: () => {
        emit("hideModal");
      },
      sortPlans: (plans) => {
        let planResourceIds = [];

        plans.forEach((plan) => {
          planResourceIds.push(plan.resourceId);
        });
        store
          .dispatch("newClubs/putSortPlans", {
            clubResourceId: props.club.resourceId,
            data: {
              planResourceIds: planResourceIds,
            },
          })
          .then(() => {
            state.plans = store.getters["newClubs/newClub"].allPlans;
            swal.successToast("순서가 변경되었습니다.");
          });
      },
      openPlanModal: () => {
        emit("openPlanModal");
      },
      openPlanEditModal: (plan) => {
        emit("openPlanEditModal", plan);
      },
      deletePlan: (plan) => {
        const callback = () => {
          store
            .dispatch("newClubs/deleteClubPlan", {
              clubResourceId: props.club.resourceId,
              planResourceId: plan.resourceId,
            })
            .then(() => {
              state.plans = store.getters["newClubs/newClub"].allPlans;
              emit("deleteComplete", plan.resourceId);
              swal.successToast("삭제되었습니다.");
            });
        };

        swal.deleteAlert(callback);
      },
      closeModal: () => {
        emit("hideModal");
      },
    };

    return { state, actions, detailOptionForm };
  },
};
</script>

<style src="./style.css" scoped></style>
