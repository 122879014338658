<template>
  <div ref="communityPage" class="ent-club-articles">
    <div>
      <div class="page-wrapper">
        <div class="article">
          <div class="filter-section">
            <div class="search-area">
              <input-search
                v-model="state.filters.q"
                class="search"
                shape="round"
                @search="actions.getArticles({ q: state.filters.q })"
              ></input-search>
              <div class="community-pc">
                <button
                  class="create sub-title-s2"
                  @click="actions.goToCreateArticle()"
                >
                  글쓰기
                </button>
              </div>
            </div>

            <div class="order">
              <button-dropdown distance="0">
                <template #button>
                  <button class="dropdown-btn-pc sub-titles2">
                    {{ state.activeOrderingText }}
                    <arrow-icon
                      class="arrow-icon"
                      direction="down"
                      stroke-size="normal"
                    ></arrow-icon>
                  </button>
                </template>
                <template #dropdownList>
                  <ul class="dropdown-list">
                    <li
                      class="tab-item"
                      :class="{ active: state.filters.order === null }"
                    >
                      <button-dropdown-item
                        v-close-popper
                        text="최신순"
                        @click="actions.getArticles({ order: null })"
                      ></button-dropdown-item>
                    </li>
                    <li
                      class="tab-item"
                      :class="{ active: state.filters.order === 'old' }"
                    >
                      <button-dropdown-item
                        v-close-popper
                        text="오래된순"
                        @click="actions.getArticles({ order: 'old' })"
                      ></button-dropdown-item>
                    </li>
                    <li
                      class="tab-item"
                      :class="{
                        active: state.filters.order === 'comment',
                      }"
                    >
                      <button-dropdown-item
                        v-close-popper
                        text="최근 댓글순"
                        @click="actions.getArticles({ order: 'comment' })"
                      ></button-dropdown-item>
                    </li>
                  </ul>
                </template>
              </button-dropdown>
            </div>

            <div class="tab">
              <button-dropdown distance="0">
                <template #button>
                  <filter-icon
                    width="24"
                    height="24"
                    :fill="state.existTabFilter"
                    :fill-color="state.existTabFilter ? '#8E1EFF' : '#0D0D10'"
                  ></filter-icon>
                </template>

                <template #dropdownList>
                  <ul class="dropdown-list">
                    <li
                      class="tab-item"
                      :class="{ active: state.allTabActive }"
                    >
                      <button-dropdown-item
                        v-close-popper
                        text="전체"
                        @click="
                          actions.getArticles({
                            answer: false,
                            unanswered: false,
                            myWriting: false,
                            bookmark: false,
                            isPinned: false,
                            isNotice: false,
                          })
                        "
                      ></button-dropdown-item>
                    </li>
                    <li
                      class="tab-item"
                      :class="{ active: state.filters.unanswered }"
                    >
                      <button-dropdown-item
                        v-close-popper
                        text="미답변"
                        @click="
                          actions.getArticles({
                            unanswered: !state.filters.unanswered,
                          })
                        "
                      ></button-dropdown-item>
                    </li>
                    <li
                      class="tab-item"
                      :class="{ active: state.filters.answer }"
                    >
                      <button-dropdown-item
                        v-close-popper
                        text="답변 완료"
                        @click="
                          actions.getArticles({ answer: !state.filters.answer })
                        "
                      ></button-dropdown-item>
                    </li>
                    <li
                      class="tab-item"
                      :class="{ active: state.filters.myWriting }"
                    >
                      <button-dropdown-item
                        v-close-popper
                        text="내가 쓴 글"
                        @click="
                          actions.getArticles({
                            myWriting: !state.filters.myWriting,
                          })
                        "
                      ></button-dropdown-item>
                    </li>
                    <li
                      class="tab-item"
                      :class="{ active: state.filters.isNotice }"
                    >
                      <button-dropdown-item
                        v-close-popper
                        text="공지"
                        @click="
                          actions.getArticles({
                            isNotice: !state.filters.isNotice,
                          })
                        "
                      ></button-dropdown-item>
                    </li>
                    <li
                      class="tab-item"
                      :class="{ active: state.filters.isPinned }"
                    >
                      <button-dropdown-item
                        v-close-popper
                        text="필독"
                        @click="
                          actions.getArticles({
                            isPinned: !state.filters.isPinned,
                          })
                        "
                      ></button-dropdown-item>
                    </li>
                    <li
                      class="tab-item"
                      :class="{ active: state.filters.bookmark }"
                    >
                      <button-dropdown-item
                        v-close-popper
                        text="북마크"
                        @click="
                          actions.getArticles({
                            bookmark: !state.filters.bookmark,
                          })
                        "
                      ></button-dropdown-item>
                    </li>
                  </ul>
                </template>
              </button-dropdown>
            </div>

            <ul class="status-list">
              <li>
                <button
                  class="sub-title-s3"
                  :class="{ active: state.allTabActive }"
                  @click="
                    actions.getArticles({
                      answer: false,
                      unanswered: false,
                      myWriting: false,
                      bookmark: false,
                      isPinned: false,
                      isNotice: false,
                    })
                  "
                >
                  전체
                </button>
              </li>
              <li>
                <button
                  class="sub-title-s3"
                  :class="{ active: state.filters.unanswered }"
                  @click="
                    actions.getArticles({
                      unanswered: !state.filters.unanswered,
                    })
                  "
                >
                  미답변
                </button>
              </li>
              <li>
                <button
                  class="sub-title-s3"
                  :class="{ active: state.filters.answer }"
                  @click="
                    actions.getArticles({ answer: !state.filters.answer })
                  "
                >
                  답변 완료
                </button>
              </li>
              <li>
                <button
                  class="sub-title-s3"
                  :class="{ active: state.filters.myWriting }"
                  @click="
                    actions.getArticles({ myWriting: !state.filters.myWriting })
                  "
                >
                  내가 쓴 글
                </button>
              </li>
              <li>
                <button
                  class="sub-title-s3"
                  :class="{ active: state.filters.isNotice }"
                  @click="
                    actions.getArticles({ isNotice: !state.filters.isNotice })
                  "
                >
                  공지
                </button>
              </li>
              <li>
                <button
                  class="sub-title-s3"
                  :class="{ active: state.filters.isPinned }"
                  @click="
                    actions.getArticles({ isPinned: !state.filters.isPinned })
                  "
                >
                  필독
                </button>
              </li>
              <li>
                <button
                  class="sub-title-s3"
                  :class="{ active: state.filters.bookmark }"
                  @click="
                    actions.getArticles({ bookmark: !state.filters.bookmark })
                  "
                >
                  북마크
                </button>
              </li>
            </ul>

            <button class="writing-btn" @click="actions.goToCreateArticle()">
              <writing-icon width="24" height="24"></writing-icon>
            </button>
          </div>

          <page-loading
            v-if="state.getArticlesLoading"
            class="articles-loading"
          ></page-loading>

          <ul
            v-if="!state.getArticlesLoading && state.articles.length > 0"
            class="article-list"
          >
            <li
              v-for="article in state.articles"
              :key="`article-item-${article.resourceId}`"
            >
              <article-item
                :article="article"
                :club-resource-id="clubResourceId"
              ></article-item>
            </li>
            <li v-if="state.showObserve">
              <observe @triggerIntersected="actions.getMoreArticles"></observe>
            </li>
            <li v-if="!state.showObserve" class="last-message">
              <p class="sub-title-s1 text-gray-second">
                ------- 마지막 게시글 입니다 -------
              </p>
            </li>
          </ul>

          <div v-if="!!state.filters.q && state.articles.length === 0">
            <empty-list
              :text="`'${state.filters.q}'로 검색된 게시글이 없습니다.`"
            ></empty-list>
          </div>

          <div v-if="!state.filters.q && state.articles.length === 0">
            <empty-list
              icon-image="/assets/images/articles/article_empty.png"
              :is-button="true"
              btn-text="첫번째 글 남기기"
              :text="`커뮤니티에 작성된 글이 없습니다.<br />팔로워 분들에게 글을 남겨보세요!`"
              @btnAction="actions.goToCreateArticle()"
            >
              <template #buttonIcon>
                <arrow-icon></arrow-icon>
              </template>
            </empty-list>
          </div>
        </div>
      </div>
    </div>

    <teleport :disabled="true">
      <community-modal
        v-if="state.showCommunityModal"
        :community-list="state.communities"
        @hideModal="actions.closeCommunityModal()"
        @filterCommunity="
          (community) =>
            actions.getArticles({
              communityResourceId: community ? community.resourceId : '',
            })
        "
        @toggleCommunitySettingModal="actions.openCommunitySettingModal()"
      ></community-modal>

      <community-setting-modal
        v-if="state.showCommunitySettingModal"
        :communities="state.communities"
        @hideModal="actions.closeCommunitySettingModal()"
      ></community-setting-modal>
    </teleport>
  </div>
</template>

<script>
import { computed, onBeforeMount, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { savedAt } from "@/helper/date";
import InputSearch from "../../../../components/console/inputs/InputSearch";
import ArrowIcon from "../../../../components/console/icons/ArrowIcon";
import ArticleItem from "../../Articles/ArticleItem/ArticleItem";
import WritingIcon from "../../../../components/console/icons/WritingIcon";
import CommunityModal from "../../Articles/CommunityModal/CommunityModal";
import FilterIcon from "../../../../components/console/icons/FilterIcon";
import Observe from "../../Articles/Observe/Observe";
import CommunitySettingModal from "../../Articles/CommunitySettingModal/CommunitySettigModal";
import EmptyList from "../../../../components/console/empties/EmptyList";
import ButtonDropdown from "../../../../components/console/buttons/ButtonDropdown";
import ButtonDropdownItem from "../../../../components/console/buttons/ButtonDropdownItem";

export default {
  name: "EntClubArticles",
  components: {
    ButtonDropdownItem,
    ButtonDropdown,
    EmptyList,
    CommunitySettingModal,
    Observe,
    FilterIcon,
    CommunityModal,
    WritingIcon,
    ArticleItem,
    ArrowIcon,
    InputSearch,
  },
  props: {
    communityResourceId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const communityPage = ref();

    // 클럽 목록 > 모집중 탭 > 클럽 카드 > 라운지 클릭시 파라미터노 clubResourceId 보냄
    const clubResourceId = route.params.clubResourceId;

    const state = reactive({
      communityResourceId: computed(() => {
        return store.getters["newClubs/newClub"]?.community.resourceId;
      }),
      communities: computed(() => {
        return store.getters["communities/communities"];
      }),
      generalCommunities: computed(() => {
        return state.communities.filter((item) => {
          if (!item.clubResourceId) {
            return item;
          }
        });
      }),
      clubCommunities: computed(() => {
        return state.communities.filter((item) => {
          if (item.clubResourceId) {
            return item;
          }
        });
      }),
      activeCommunity: computed(() => {
        if (store.getters["communities/community"].resourceId) {
          return store.getters["communities/community"];
        }
        return null;
      }),
      articles: computed(() => {
        return store.getters["articles/articles"];
      }),
      articlesMeta: computed(() => {
        return store.getters["articles/articlesMeta"];
      }),
      articleCount: computed(() => {
        return state.communities.reduce((sum, item) => {
          return sum + item.articleCount;
        }, 0);
      }),
      showObserve: computed(() => {
        return (
          state.articlesMeta.pagination.currentPage !==
          state.articlesMeta.pagination.lastPage
        );
      }),
      filters: {
        communityResourceId: store.getters["communities/community"].resourceId,
        answer: false,
        unanswered: false,
        myWriting: false,
        bookmark: false,
        isPinned: false,
        isNotice: false,
        order: null,
        q: "",
        page: 1,
      },
      activeOrderingText: computed(() => {
        if (state.filters.order === "old") {
          return "오래된 순";
        }
        if (state.filters.order === "comment") {
          return "최근 댓글순";
        }
        return "최신순";
      }),
      allTabActive: computed(() => {
        return (
          !state.filters.answer &&
          !state.filters.unanswered &&
          !state.filters.myWriting &&
          !state.filters.bookmark &&
          !state.filters.isPinned &&
          !state.filters.isNotice
        );
      }),
      existTabFilter: computed(() => {
        return (
          state.filters.answer ||
          state.filters.unanswered ||
          state.filters.myWriting ||
          state.filters.bookmark ||
          state.filters.isNotice ||
          state.filters.isPinned
        );
      }),
      showArticleFilterModal: false,
      showCommunitiesSortModal: false,
      getArticlesLoading: false,
      isArticleForm: computed(() => {
        return (
          route.name === "console.articles.create" ||
          route.name === "console.articles.edit"
        );
      }),
      showMoreButton: computed(() => {
        let pagination = store.state.articles.articlesMeta.pagination;
        return pagination.currentPage !== pagination.lastPage;
      }),
      showCommunityModal: false,
      showCommunitySettingModal: false,
    });

    onBeforeMount(async () => {
      state.filters.communityResourceId = props.communityResourceId;
    });

    const actions = {
      closeArticleFilterModal: () => {
        state.showArticleFilterModal = false;
      },
      openArticleFilterModal: () => {
        state.showArticleFilterModal = true;
      },
      getArticles: async (filter) => {
        if (state.showCommunityModal) {
          state.showCommunityModal = false;
        }

        state.getArticlesLoading = true;

        if (
          filter.answer ||
          filter.unanswered ||
          filter.myWriting ||
          filter.bookmark ||
          filter.isPinned ||
          filter.isNotice
        ) {
          state.filters = {
            ...state.filters,
            ...{
              answer: false,
              unanswered: false,
              myWriting: false,
              bookmark: false,
              isPinned: false,
              isNotice: false,
            },
          };
        }

        state.filters = { ...state.filters, ...filter, ...{ page: 1 } };

        if (state.filters.communityResourceId) {
          let community = state.communities.find((item) => {
            return item.resourceId === state.filters.communityResourceId;
          });
          await store.dispatch("communities/setCommunity", community);
        } else {
          await store.dispatch("communities/setCommunity");
        }

        await store.dispatch("articles/getArticles", state.filters).then(() => {
          state.getArticlesLoading = false;
        });
      },
      getMoreArticles: async () => {
        state.filters.page = state.filters.page + 1;
        await store.dispatch("articles/getMoreArticles", state.filters);
      },
      openCommunitiesSortModal: () => {
        state.showCommunitiesSortModal = true;
      },
      changeCommunity: (community) => {
        if (community) {
          store.dispatch("communities/setCommunity", community);
        } else {
          store.dispatch("communities/setCommunity");
        }
      },
      openCommunityModal: () => {
        state.showCommunityModal = true;
      },
      closeCommunityModal: () => {
        state.showCommunityModal = false;
      },
      openCommunitySettingModal: () => {
        state.showCommunitySettingModal = true;
      },
      closeCommunitySettingModal: () => {
        state.showCommunitySettingModal = false;
      },
      goToCreateArticle: () => {
        router.push({
          name: "console.clubs.articles.create",
          params: {
            clubResourceId: store.getters["newClubs/newClub"].resourceId,
          },
        });
      },
    };
    return {
      state,
      actions,
      savedAt,
      communityPage,
      clubResourceId,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
