<template>
  <div class="session-card">
    <div class="check-box-wrapper">
      <input-checkbox
        :default-value="state.selected"
        @updateData="(value) => actions.setSelected(value)"
      ></input-checkbox>
    </div>

    <div class="featured-img-wrapper">
      <div ref="sessionFeaturedImage" class="featured-img"></div>
    </div>
    <div class="session-text">
      <!--      v-if="!!club.theme"-->
      <badge
        :color="state.sessionType.color"
        :text="state.sessionType.text"
      ></badge>
      <h6 class="title">
        {{ `${state.sessionResource.title}` }}
      </h6>
      <p
        class="description b-text-3 text-gray-second"
        v-html="state.sessionResource.description"
      ></p>
    </div>

    <div class="meeting-schedule">
      <span v-if="!!session.meeting" class="sub-text-s3 text-gray-second">
        {{ state.liveSchedule }}
      </span>
      <span v-if="state.pollSchedule" class="sub-text-s3 text-gray-second">
        {{ state.pollSchedule }}
      </span>
    </div>
  </div>
</template>

<script>
import InputCheckbox from "../../../../components/console/inputs/InputCheckbox";
import { computed, getCurrentInstance, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import moment from "moment-timezone";
import Badge from "../../../../components/console/badges/Badge.vue";

export default {
  name: "SelectableSessionItem",
  components: { Badge, InputCheckbox },
  props: {
    session: {
      type: Object,
      required: true,
    },
    number: {
      type: Number,
      required: true,
    },
    plan: {
      type: Object,
      required: false,
    },
    club: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const sessionFeaturedImage = ref();
    const { proxy } = getCurrentInstance();

    const state = reactive({
      sessions: computed(() => {
        return store.getters["newClubs/newClubSessions"];
      }),
      selected: true,
      isClub: computed(() => {
        let club = props.club;
        return !!club;
      }),
      sessionType: computed(() => {
        if (state.isClub) {
          if (props.session.meeting) {
            if (props.session.meeting.type === "streaming") {
              return proxy.$const.meetingTypes.streaming;
            } else if (props.session.meeting.type === "seminar") {
              return proxy.$const.meetingTypes.seminar;
            } else {
              return proxy.$const.meetingTypes.conference;
            }
          } else if (props.session.content) {
            return proxy.$const.sessionTypes.content;
          } else {
            return proxy.$const.sessionTypes.poll;
          }
        } else {
          if (props.meeting) {
            if (props.meeting.type === "streaming") {
              return proxy.$const.meetingTypes.streaming;
            } else if (props.meeting.type === "seminar") {
              return proxy.$const.meetingTypes.seminar;
            } else {
              return proxy.$const.meetingTypes.conference;
            }
          }
        }
        return proxy.$const.sessionTypes.content;
      }),
      liveSchedule: computed(() => {
        if (props.session.meeting) {
          return `${moment(props.session.meeting.scheduledAt)
            .lang("ko")
            .format("MM DD(ddd) HH:mm")} - ${moment(
            props.session.meeting.scheduledFinishAt
          ).format("HH:mm")}`;
        }
        return "";
      }),
      pollSchedule: computed(() => {
        let poll = props.session?.poll;

        if (poll) {
          return `${
            poll.startAt ? moment(poll.startAt).format("YYYY.MM.DD hh:mm") : ""
          }~${
            poll.finishAt
              ? moment(poll.finishAt)
                  .tz(moment.tz.guess())
                  .format("YYYY.MM.DD hh:mm z")
              : ""
          }`;
        } else {
          return "";
        }
      }),
      sessionResource: computed(() => {
        let session = props.session;
        if (session.content) {
          return session.content;
        } else if (session.meeting) {
          return session.meeting;
        } else {
          return session.poll;
        }
      }),
    });

    onMounted(() => {
      // 생성 할 때, props.plan 없음.
      if (props.plan) {
        if (props.session.content) {
          state.selected =
            props.plan.contents.findIndex((item) => {
              return item.resourceId === props.session.content.resourceId;
            }) >= 0;
        }

        if (props.session.meeting) {
          state.selected =
            props.plan.meetings.findIndex((item) => {
              return item.resourceId === props.session.meeting.resourceId;
            }) >= 0;
        }

        if (props.session.poll) {
          state.selected =
            props.plan.polls.findIndex((item) => {
              return item.resourceId === props.session.poll.resourceId;
            }) >= 0;
        }
      } else {
        emit("connectSession");
      }

      sessionFeaturedImage.value.style.backgroundImage = `url(${state.sessionResource.featuredImage})`;
    });

    const actions = {
      setSelected: (value) => {
        state.selected = value;

        if (state.selected) {
          emit("connectSession");
        } else {
          emit("unConnectSession");
        }
      },
    };

    return { state, actions, sessionFeaturedImage };
  },
};
</script>

<style src="./style.css" scoped></style>
