<template>
  <div class="article-item">
    <div class="community">
      <list-icon fill-color="#818287"></list-icon>

      <span class="title sub-text-s3 text-gray-second">
        {{ article.community.title }}
      </span>

      <pin-icon
        v-if="article.isPinned"
        class="pin-icon"
        fill-color="#FF522D"
        :fill="true"
      ></pin-icon>
    </div>

    <div class="writer">
      <avatar :avatar-img="state.writerAvatar"></avatar>
      <div class="info">
        <p class="name sub-text-s2">{{ state.writerName }}</p>
        <p class="sub-text-s2 text-gray-second">{{ state.createdAt }}</p>
      </div>
    </div>

    <div class="content">
      <div class="title-section">
        <h4 v-if="article.isNotice" class="text-red-50">공지</h4>

        <locked-icon
          v-if="article.isScret"
          width="20"
          height="20"
          fill-color="#191B1D"
          :fill="true"
        ></locked-icon>

        <h4 class="title">{{ state.title }}</h4>
      </div>
      <div
        ref="contentElement"
        class="article-editor"
        :class="{ fold: state.isFoldContent }"
      >
        <p class="quill-editor" v-html="state.contentHtml"></p>
      </div>

      <div v-if="state.overMinHeight" class="folder">
        <button
          v-if="state.isFoldContent"
          class="sub-title-s2 text-gray-second"
          @click="actions.toggleIsFoldContent()"
        >
          자세히 보기
        </button>
        <button
          v-if="!state.isFoldContent"
          class="sub-title-s2 text-gray-second"
          @click="actions.toggleIsFoldContent()"
        >
          간략히 보기
        </button>
      </div>

      <div v-if="article.attachments.length > 0" class="attachments">
        <ul>
          <li
            v-for="attachment in article.attachments"
            :key="`attachment-${attachment.resourceId}`"
          >
            <attachment-item :attachment="attachment"></attachment-item>
          </li>
        </ul>
      </div>
    </div>

    <div class="content-popularity">
      <span
        class="sub-text-s3"
        :class="{ active: article.isLiked }"
        @click="actions.likeArticle()"
      >
        <good-icon
          :fill="article.isLiked"
          :fill-color="article.isLiked ? '#8E1EFF' : '#0D0D10'"
        ></good-icon>
        {{ article.likersCount }}
      </span>

      <span
        class="sub-text-s3"
        :class="{ active: state.showCommentList }"
        @click="actions.toggleCommentList()"
      >
        <chat-icon
          :fill="state.showCommentList"
          :fill-color="state.showCommentList ? '#8E1EFF' : '#0D0D10'"
        ></chat-icon>
        {{ article.commentCount }}
      </span>

      <span
        class="sub-text-s3"
        :class="{ active: article.isFavorited }"
        @click="actions.bookMarkArticle()"
      >
        <bookmark-icon
          :fill="article.isFavorited"
          :fill-color="article.isFavorited ? '#8E1EFF' : '#0D0D10'"
        ></bookmark-icon>
      </span>
    </div>

    <div class="comments">
      <div
        v-if="!state.showCommentList && state.comments.length > 0"
        class="prev-comment"
        @click="actions.toggleCommentList()"
      >
        <avatar size="32" :avatar-img="state.comments[0].user.avatar"></avatar>
        <p class="b-text-2">{{ state.comments[0].content }}</p>
      </div>

      <div v-if="state.showCommentList" class="comments-wrapper">
        <comment-input
          placeholder="댓글을 입력해주세요"
          parent="articles"
          :parent-resource-id="article.resourceId"
        ></comment-input>

        <ul v-if="state.comments.length > 0" class="comment-list">
          <li
            v-for="comment in state.comments"
            :key="`article-comment-${comment.resourceId}`"
          >
            <article-comment
              :parent-resource-id="article.resourceId"
              :comment="comment"
            ></article-comment>
          </li>
        </ul>
      </div>
    </div>

    <div class="menu-btn">
      <button-dropdown distance="-15">
        <template #button>
          <button class="dropdown-btn">
            <more-verti-icon fill-color="#000000"></more-verti-icon>
          </button>
        </template>
        <template #dropdownList>
          <ul class="dropdown-list">
            <li v-if="state.myArticle">
              <button v-close-popper @click="actions.goToArticleEdit()">
                수정
              </button>
            </li>
            <li>
              <button
                v-close-popper
                :class="{ active: article.isPinned }"
                @click="actions.togglePinned()"
              >
                {{ article.isPinned ? "필독 해제" : "필독 설정" }}
              </button>
            </li>
            <li>
              <button
                v-close-popper
                :class="{ active: article.isNotice }"
                @click="actions.toggleNotice()"
              >
                {{ article.isNotice ? "공지 해제" : "공지 설정" }}
              </button>
            </li>
            <li v-if="!state.myArticle">
              <button v-close-popper @click="actions.openDeclarationModal()">
                신고
              </button>
            </li>
            <li v-if="state.myArticle">
              <button v-close-popper @click="actions.openArticleDeleteModal()">
                삭제
              </button>
            </li>
          </ul>
        </template>
      </button-dropdown>
    </div>

    <teleport :disabled="true">
      <warning-modal
        v-if="state.showDeleteModal"
        warning-title="게시글을 삭제할까요?"
        warning-text="해당 게시글에 속한 댓글이 모두 삭제되며, 삭제 후에는 복구할 수 없어요."
        confirm-text="삭제"
        @hideModal="actions.closeArticleDeleteModal()"
        @confirm="actions.deleteArticle()"
      ></warning-modal>

      <declaration-modal
        v-if="state.showDeclarationModal"
        model="articles"
        :model-resource-id="article.resourceId"
        @hideModal="actions.closeDeclarationModal()"
        @reportSuccess="
          [actions.successReport(), actions.closeDeclarationModal()]
        "
      ></declaration-modal>

      <warning-modal
        v-if="state.showReportConfirmModal"
        warning-title="신고가 접수되었어요"
        warning-text="신고된 게시물은 확인 후 부적절한 게시물로 판별될 경우 삭제됩니다."
        :show-cancel-button="false"
        confirm-text="확인"
        @confirm="actions.closeReportConfirmModal()"
      ></warning-modal>
    </teleport>
  </div>
</template>

<script>
import ListIcon from "../../../../components/console/icons/ListIcon";
import MoreVertiIcon from "../../../../components/console/icons/MoreVertiIcon";
import Avatar from "../../../../components/console/avatars/Avatar";
import { computed, onMounted, reactive, ref } from "vue";
import helper from "@/helper";
import { savedAt } from "../../../../helper/date";
import GoodIcon from "../../../../components/console/icons/GoodIcon";
import ChatIcon from "../../../../components/console/icons/ChatIcon";
import BookmarkIcon from "../../../../components/console/icons/BookmarkIcon";
import ArticleComment from "../ArticleComment/ArticleComment";
import { useStore } from "vuex";
import CommentInput from "../CommentInput/CommentInput";
import LockedIcon from "../../../../components/console/icons/LockedIcon";
import AttachmentItem from "../../../../components/console/items/AttachmentItem/AttachmentItem";
import WarningModal from "../../../../components/console/modals/WarningModal";
import PinIcon from "../../../../components/console/icons/PinIcon";
import DeclarationModal from "../DeclarationModal/DeclarationModal";
import { useRouter } from "vue-router";
import swal from "@/helper/swal";
import ButtonDropdown from "../../../../components/console/buttons/ButtonDropdown";

export default {
  name: "ArticleItem",
  components: {
    ButtonDropdown,
    DeclarationModal,
    PinIcon,
    AttachmentItem,
    LockedIcon,
    CommentInput,
    ArticleComment,
    BookmarkIcon,
    ChatIcon,
    GoodIcon,
    Avatar,
    MoreVertiIcon,
    ListIcon,
    WarningModal,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    clubResourceId: {
      type: String,
      default: "",
      required: false,
    },
  },
  setup(props) {
    const contentElement = ref();
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      writerName: computed(() => {
        return helper.displayUserName(props.article.user);
      }),
      writerAvatar: computed(() => {
        return props.article.user?.avatar;
      }),
      createdAt: computed(() => {
        return savedAt(props.article.createdAt);
      }),
      title: computed(() => {
        return props.article.title;
      }),
      comments: computed(() => {
        return props.article.comments;
      }),
      myArticle: computed(() => {
        return state.user.resourceId === props.article.user.resourceId;
      }),
      contentHtml: computed(() => {
        return helper.nl2br(props.article.contentHtml);
      }),
      overMinHeight: false,
      isFoldContent: false,
      showCommentList: false,
      showDeleteModal: false,
      showDeclarationModal: false,
      showReportConfirmModal: false,
    });

    onMounted(() => {
      if (contentElement.value && contentElement.value.clientHeight > 280) {
        state.overMinHeight = true;
        state.isFoldContent = true;
      }
    });

    const actions = {
      toggleIsFoldContent: () => {
        state.isFoldContent = !state.isFoldContent;
      },
      toggleCommentList: () => {
        state.showCommentList = !state.showCommentList;
      },
      likeArticle: () => {
        store.dispatch("articles/postArticleLike", {
          mode: "toggle",
          model: "article",
          resourceId: props.article.resourceId,
        });
      },
      bookMarkArticle: () => {
        store.dispatch("articles/postArticleFavorite", {
          mode: "toggle",
          model: "article",
          resourceId: props.article.resourceId,
        });
      },
      togglePinned: () => {
        store.dispatch("articles/postArticleState", {
          articleResourceId: props.article.resourceId,
          state: "pinned",
        });
      },
      toggleNotice: () => {
        store.dispatch("articles/postArticleState", {
          articleResourceId: props.article.resourceId,
          state: "notice",
        });
      },
      openArticleDeleteModal: () => {
        state.showDeleteModal = true;
      },
      closeArticleDeleteModal: () => {
        state.showDeleteModal = false;
      },
      openDeclarationModal: () => {
        state.showDeclarationModal = true;
      },
      closeDeclarationModal: () => {
        state.showDeclarationModal = false;
      },
      successReport: () => {
        state.showReportConfirmModal = true;
      },
      closeReportConfirmModal: () => {
        state.showReportConfirmModal = false;
      },
      goToArticleEdit: () => {
        if (props.clubResourceId) {
          router.push({
            name: "console.clubs.articles.edit",
            params: {
              clubResourceId: props.clubResourceId,
              articleResourceId: props.article.resourceId,
            },
          });
        } else {
          router.push({
            name: "console.articles.edit",
            params: {
              articleResourceId: props.article.resourceId,
            },
          });
        }
      },
      deleteArticle: () => {
        store
          .dispatch("articles/deleteArticle", {
            articleResourceId: props.article.resourceId,
          })
          .then(() => {
            swal.deleteCompleteToast();
            state.showDeleteModal = false;
          });
      },
    };

    return { state, actions, contentElement };
  },
};
</script>

<style src="./style.css" scoped></style>
