<template>
  <div class="club-member">
    <div class="type-section">
      <button-dropdown placement="bottom-end">
        <template #button>
          <button class="select-btn">
            {{ state.memberType }} <i class="fa-solid fa-caret-down"></i>
          </button>
        </template>

        <template #dropdownList>
          <ul class="member-type-selector-list">
            <li>
              <button
                :disabled="!state.isStaff || !clubMember.user"
                @click="actions.openUpdateMemberRoleModal(!clubMember.isStaff)"
              >
                일반
              </button>
            </li>

            <li>
              <button
                :disabled="state.isStaff || !clubMember.user"
                @click="actions.openUpdateMemberRoleModal(!clubMember.isStaff)"
              >
                스탭
              </button>
            </li>
          </ul>
        </template>
      </button-dropdown>
    </div>

    <div class="name-section">
      <h4>{{ state.userName }}</h4>
    </div>

    <div
      class="sub-text-s2 text-gray-second email-section"
      :title="clubMember.user ? clubMember.user?.email : ''"
    >
      {{ clubMember.user ? clubMember.user?.email : "" }}
    </div>

    <div class="ticket-section">
      <text-divider
        class="divider"
        width="2px"
        height="36px"
        color="#0D0D10"
        margin="0 12px 0 0"
      ></text-divider>
      <p class="sub-text-s2">
        {{ state.paidTicket }}
      </p>
    </div>

    <div class="payment-section">
      <span class="txt sub-text-s3 text-gray-second">결제 금액</span>

      <span v-if="state.paidPrice.KRW > 0" class="sub-text-s3">
        {{ "₩" + state.paidPrice.KRW }}
      </span>
      <span v-if="state.paidPrice.USD > 0" class="sub-text-s3">
        {{ "$" + state.paidPrice.USD }}
      </span>
    </div>

    <div class="point-section">
      <span class="txt sub-text-s3 text-gray-second">이용 포인트</span>
      <span class="sub-text-s3">
        {{ state.usedPoint }}<span class="text-purple-50">P</span>
      </span>
    </div>

    <div class="paid-date-section">
      <span class="txt sub-text-s3 text-gray-second">구매 일시</span>
      <span class="date sub-text-s3">
        {{ state.paidDate }} <br />
        {{ state.paidDateTime }}
      </span>
    </div>

    <div class="recent-access-section">
      <span class="txt sub-text-s3 text-gray-second">최근 접속 일시</span>
      <span class="date sub-text-s3">
        {{ state.recentAccessDate }} <br />
        {{ state.recentAccessDateTime }}
      </span>
    </div>

    <teleport to="#teleport">
      <warning-modal
        v-if="state.showUpdateMemberRoleModal"
        :warning-title="`${clubMember.user ? state.userName : '-'}님의 ${
          state.memberRole.role
        } 권한을 ${state.memberRole.value ? 'on' : 'off'} 하시겠습니까?`"
        warning-text="지금까지 작성한 내용은 저장되지 않아요"
        confirm-text="변경"
        cancel-text="취소"
        @hideModal="actions.closeUpdateMemberRoleModal()"
        @confirm="actions.updateMemberRole()"
      ></warning-modal>
    </teleport>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import helper from "@/helper";
import ButtonDropdown from "../../../../components/console/buttons/ButtonDropdown";
import TextDivider from "../../../../components/console/dividers/TextDivider";
import moment from "moment-timezone";
import { useStore } from "vuex";
import swal from "@/helper/swal";
import WarningModal from "@/components/console/modals/WarningModal.vue";

export default {
  name: "ClubMemberItem",
  components: { WarningModal, TextDivider, ButtonDropdown },
  props: {
    clubMember: {
      type: Object,
      required: true,
    },
    club: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const state = reactive({
      memberType: computed(() => {
        if (props.clubMember.isStaff) {
          return "스탭";
        }
        return "일반";
      }),
      userName: computed(() => {
        return helper.displayUserName(props.clubMember.user);
      }),
      paidTicket: computed(() => {
        if (props.clubMember.user?.paymentOrders.length > 0) {
          return props.clubMember.user.paymentOrders[0].title;
        } else {
          return "-";
        }
      }),
      paidPrice: computed(() => {
        let krwPrice = props.clubMember.user
          ? props.clubMember.user.paymentOrders.reduce((sum, current) => {
              if (current.currency === "KRW") {
                sum += current.price;
              }
              return sum;
            }, 0)
          : 0;

        let usdPrice = props.clubMember.user
          ? props.clubMember.user.paymentOrders.reduce((sum, current) => {
              if (current.currency === "USD") {
                sum += current.price;
              }
              return sum;
            }, 0)
          : 0;

        return {
          KRW: krwPrice,
          USD: usdPrice,
        };
      }),
      usedPoint: computed(() => {
        return props.clubMember.user
          ? props.clubMember.user.paymentOrders.reduce((sum, current) => {
              sum += current.discountAmountPoint;
              return sum;
            }, 0)
          : 0;
      }),
      paidDate: computed(() => {
        if (props.clubMember.user?.paymentOrders.length > 0) {
          return moment(props.clubMember.user.paymentOrders[0].paidAt).format(
            "YYYY.MM.DD"
          );
        }
        return "";
      }),
      paidDateTime: computed(() => {
        if (props.clubMember.user?.paymentOrders.length > 0) {
          return moment(props.clubMember.user.paymentOrders[0].paidAt)
            .tz(moment.tz.guess())
            .format("HH:mm z");
        }
        return "-";
      }),
      recentAccessDate: computed(() => {
        return props.clubMember.user
          ? moment(props.clubMember.user.updatedAt).format("YYYY.MM.DD")
          : "-";
      }),
      recentAccessDateTime: computed(() => {
        if (props.clubMember.user?.paymentOrders.length > 0) {
          return moment(props.clubMember.user.updatedAt)
            .tz(moment.tz.guess())
            .format("HH:mm z");
        }
        return "-";
      }),
      isStaff: computed(() => {
        return props.clubMember.isStaff;
      }),
      showUpdateMemberRoleModal: false,
      memberRole: {
        // note memberRole 업데이트하는 api의 포스트맨에서 parameter 인할 것.
        role: "staff",
        value: null,
      },
    });

    const actions = {
      openUpdateMemberRoleModal: (isStaff) => {
        state.memberRole.value = isStaff;
        state.showUpdateMemberRoleModal = true;
      },
      closeUpdateMemberRoleModal: () => {
        state.memberRole.value = null;
        state.showUpdateMemberRoleModal = false;
      },
      updateMemberRole: (data, user) => {
        const params = {
          role: state.memberRole.role,
          value: state.memberRole.value ? "on" : "off",
        };
        store
          .dispatch("newClubs/updateClubMemberRole", {
            clubResourceId: props.club.resourceId,
            userResourceId: props.clubMember.user.resourceId,
            params: params,
          })
          .then(() => {
            state.showUpdateMemberRoleModal = false;
            swal.memberRoleUpdateComplete(user.name, data.role);
            state.memberRole.value = null;
          });
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
