<template>
  <div>
    <input-basic
      class="input-basic"
      :default-value="state.formData.title"
      label="게시판 이름"
      placeholder="게시판의 이름을 작성해주세요"
      :disabled="state.isClubCommunity"
      :max-length="51"
      :error-message="state.titleErrorMsg"
      :caption="`(${state.formData.title.length}/51)`"
      @updateData="(value) => actions.setCommunityTitle(value)"
    ></input-basic>

    <input-select
      class="input-select"
      label="작성 권한"
      :list="state.noticeTypes"
      :selected-item="state.selectedNoticeType"
      @select="(item) => actions.setNoticeType(item)"
    ></input-select>
    <input-select
      v-if="!state.isClubCommunity"
      class="input-select"
      label="공개 설정"
      :list="state.publicList"
      :selected-item="state.selectedPublicStatus"
      @select="(item) => actions.setPublicType(item)"
    ></input-select>

    <div v-if="state.isEdit && !state.isClubCommunity" class="delete-section">
      <button
        class="delete-btn sub-title-s2"
        @click="actions.openDeleteWarningModal()"
      >
        게시판 삭제 <delete-icon></delete-icon>
      </button>
    </div>

    <teleport to="#teleport">
      <warning-modal
        v-if="state.showDeleteWaringModal"
        warning-title="게시판을 삭제할까요?"
        warning-text="해당 게시판에 속한 글이 모두 삭제되며, 삭제 후에는 복구할 수 없어요."
        confirm-text="삭제"
        @confirm="actions.deleteCommunity()"
        @hideModal="actions.closeDeleteWarningModal()"
      ></warning-modal>
    </teleport>
  </div>
</template>

<script>
import InputBasic from "../../../../components/console/inputs/InputBasic";
import { computed, getCurrentInstance, onMounted, reactive, watch } from "vue";
import InputSelect from "../../../../components/console/inputs/InputSelect";
import DeleteIcon from "../../../../components/console/icons/DeleteIcon";
import WarningModal from "@/components/console/modals/WarningModal.vue";
import { useStore } from "vuex";
import swal from "@/helper/swal";

export default {
  name: "CommunityForm",
  components: { WarningModal, DeleteIcon, InputSelect, InputBasic },
  props: {
    community: {
      type: Object,
      required: false,
    },
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const store = useStore();

    const state = reactive({
      isEdit: !!props.community,
      isClubCommunity: !!props.community && !!props.community.clubResourceId,
      noticeTypes: [
        proxy.$const.communityNoticeType.all,
        proxy.$const.communityNoticeType.creator,
      ],
      selectedNoticeType: proxy.$const.communityNoticeType.all,
      publicList: [
        proxy.$const.communityPublicStatus.show,
        proxy.$const.communityPublicStatus.hide,
      ],
      selectedPublicStatus: proxy.$const.communityPublicStatus.show,
      formData: {
        title: "",
        type: "feed",
        isShow: computed(() => {
          return state.selectedPublicStatus.value;
        }),
        isNotice: computed(() => {
          return state.selectedNoticeType.value;
        }),
      },
      titleErrorMsg: "",
      showDeleteWaringModal: false,
    });

    watch(
      () => state.formData,
      (newFormData) => {
        emit("updateCommunityForm", newFormData);
      },
      { deep: true }
    );

    onMounted(() => {
      if (state.isEdit) {
        state.formData.title = props.community.title
          ? props.community.title
          : "";

        state.selectedNoticeType = props.community.isNotice
          ? proxy.$const.communityNoticeType.creator
          : proxy.$const.communityNoticeType.all;

        state.selectedPublicStatus = props.community.isShow
          ? proxy.$const.communityPublicStatus.show
          : proxy.$const.communityPublicStatus.hide;
      }
    });

    const actions = {
      setCommunityTitle: (value) => {
        state.formData.title = value;
        if (!state.formData.title) {
          state.titleErrorMsg = "게시판 이름은 필수 입니다.";
        } else {
          state.titleErrorMsg = "";
        }
      },
      setNoticeType: (item) => {
        state.selectedNoticeType = item;
      },
      setPublicType: (item) => {
        state.selectedPublicStatus = item;
      },
      openDeleteWarningModal: () => {
        state.showDeleteWaringModal = true;
      },
      closeDeleteWarningModal: () => {
        state.showDeleteWaringModal = false;
      },
      deleteCommunity: () => {
        state.showDeleteWaringModal = false;
        store
          .dispatch("communities/deleteCommunity", {
            communityResourceId: props.community.resourceId,
          })
          .then(() => {
            swal.deleteCompleteToast();
            emit("deleteCommunity");
          });
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
