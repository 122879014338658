<template>
  <icon-base
    icon-name="writing-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.5 13C3.22386 13 3 12.7761 3 12.5L3 4.5C3 4.22386 3.22386 4 3.5 4L6.5 4C6.77614 4 7 3.77614 7 3.5C7 3.22386 6.77614 3 6.5 3L3.5 3C2.67157 3 2 3.67157 2 4.5L2 12.5C2 13.3284 2.67157 14 3.5 14L11.5 14C12.3284 14 13 13.3284 13 12.5L13 9.5C13 9.22386 12.7761 9 12.5 9C12.2239 9 12 9.22386 12 9.5L12 12.5C12 12.7761 11.7761 13 11.5 13L3.5 13Z"
      :fill="fillColor"
      :stroke="fillColor"
      :stroke-width="state.strokeWidth"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.64238 10.4817H5.51834V8.35762L8.82094 5.05502L10.945 7.17906L7.64238 10.4817ZM11.6651 6.45898L12.7708 5.35328L10.6467 3.22924L9.54102 4.33494L11.6651 6.45898ZM4.5 10.9908V8.14672C4.5 8.01168 4.55364 7.88217 4.64913 7.78668L10.2867 2.14913C10.4855 1.95029 10.8079 1.95029 11.0068 2.14913L13.8509 4.99325C14.0497 5.19209 14.0497 5.51448 13.8509 5.71332L8.21332 11.3509C8.11783 11.4464 7.98832 11.5 7.85328 11.5H5.00917C4.72796 11.5 4.5 11.272 4.5 10.9908Z"
      :fill="fillColor"
      :stroke="fillColor"
      :stroke-width="state.strokeWidth"
    />
  </icon-base>
</template>
<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "WritingIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
