<template>
  <div
    ref="wrapperElement"
    class="club-info-card bg-gray-01"
    :style="{ 'transition-duration': state.transitionDuration }"
  >
    <div
      ref="headerElement"
      class="header"
      :class="{ 'active-cursor': ableToggle }"
      @click="actions.toggleBox()"
    >
      <div class="info">
        <check-icon :fill-color="state.checkIconColor"></check-icon>
        <h3>{{ title }}</h3>
      </div>
      <div class="icon">
        <arrow-icon
          :class="{ active: state.isActive }"
          direction="down"
          stroke-size="normal"
        ></arrow-icon>
      </div>
    </div>
    <div ref="mainElement" class="main">
      <div class="content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUpdated, reactive, ref } from "vue";
import CheckIcon from "@/components/console/icons/CheckIcon.vue";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";

export default {
  name: "ClubInfoCard",
  components: { ArrowIcon, CheckIcon },
  props: {
    title: {
      type: String,
      default: "",
    },
    ableToggle: {
      type: Boolean,
      default: false,
    },
    isFilledOut: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const wrapperElement = ref();
    const headerElement = ref();
    const mainElement = ref();

    const state = reactive({
      isActive: false,
      checkIconColor: computed(() => {
        let isFilledOut = props.isFilledOut;
        return isFilledOut ? "#8E1EFF" : "#dcdcdc";
      }),
      headerElementHeight: computed(() => {
        return Number(
          getComputedStyle(headerElement.value).height.replace("px", "")
        );
      }),
      mainElementHeight: computed(() => {
        return Number(
          getComputedStyle(mainElement.value).height.replace("px", "")
        );
      }),
      transitionDuration: "0.7s",
    });

    onMounted(() => {
      wrapperElement.value.style.height = `${
        state.headerElementHeight + state.mainElementHeight
      }px`;
      // todo 코드개선, computed로 바라보게 수정 필요
      let floorQuotient = Math.floor(
        Number(getComputedStyle(mainElement.value).height.replace("px", "")) /
          300
      );
      state.transitionDuration = `${0.7 + 0.2 * floorQuotient}s`;
    });

    onUpdated(() => {
      // todo 코드개선, actions.toggleBox()와 동작이 겹침 하나의 함수로 처리하도록 수정
      if (!state.isActive) {
        wrapperElement.value.style.height = `${
          Number(
            getComputedStyle(headerElement.value).height.replace("px", "")
          ) +
          Number(getComputedStyle(mainElement.value).height.replace("px", ""))
        }px`;
      }
    });

    const actions = {
      toggleBox: () => {
        if (props.ableToggle) {
          if (state.isActive) {
            wrapperElement.value.style.height = `${
              state.headerElementHeight + state.mainElementHeight
            }px`;
          } else {
            wrapperElement.value.style.height = `${state.headerElementHeight}px`;
          }
          state.isActive = !state.isActive;
        }

        console.log(wrapperElement.value.style.height);
      },
    };

    return { state, actions, wrapperElement, headerElement, mainElement };
  },
};
</script>

<style src="./style.css" scoped></style>
