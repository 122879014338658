<template>
  <icon-base
    icon-name="bookmark-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <template v-if="!fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 3H4V12.2338L7.22826 10.2969C7.70328 10.0118 8.29672 10.0118 8.77174 10.2969L12 12.2338V3ZM13 3C13 2.44772 12.5523 2 12 2H4C3.44772 2 3 2.44772 3 3V13.6468C3 13.8022 3.16959 13.8982 3.3029 13.8183L7.74275 11.1543C7.90109 11.0593 8.09891 11.0593 8.25725 11.1543L12.6971 13.8183C12.8304 13.8982 13 13.8022 13 13.6468V3Z"
        :fill="fillColor"
        :stroke="fillColor"
        :stroke-width="state.strokeWidth"
      />
    </template>

    <template v-if="fill">
      <path
        d="M3 3C3 2.44772 3.44772 2 4 2H12C12.5523 2 13 2.44772 13 3V13.6468C13 13.8022 12.8304 13.8982 12.6971 13.8183L8.25725 11.1543C8.09891 11.0593 7.90109 11.0593 7.74275 11.1543L3.3029 13.8183C3.16959 13.8982 3 13.8022 3 13.6468V3Z"
        :fill="fillColor"
      />
    </template>
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "BookmarkIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
    fill: {
      type: Boolean,
      default: false,
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
