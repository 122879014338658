<template>
  <base-modal-side :is-dimmed="true" @closeModal="actions.closeModal()">
    <template #modalHeader>
      <h3 class="text-default">콘텐츠 등록</h3>
      <p class="text-gray-second sub-text-s3">
        판매하고 싶은 콘텐츠를 등록해주세요.
      </p>
    </template>

    <template #modalBody>
      <div>
        <button-basic
          v-if="!state.isEdit"
          class="add-btn"
          :text="state.isMaking ? '목록 불러오기' : '콘텐츠 생성'"
          color="#0d0d0d"
          bg-color="#ffffff"
          border-color="#ecebf1"
          icon-position="front"
          :border="true"
          @action="actions.switchToForm()"
        >
          <template v-if="!state.isMaking" #icon>
            <plus-icon></plus-icon>
          </template>
        </button-basic>
      </div>
      <template v-if="!state.isEdit && !state.isMaking">
        <div>
          <ul>
            <li
              v-for="(item, index) in state.contentsList"
              :key="index"
              class="contents-item"
            >
              <session-contents-modal-item
                :content="item"
                :selected-content-resource-id="state.selectedContentResourceId"
                @select-content="
                  (contentId) => actions.selectContent(contentId)
                "
              ></session-contents-modal-item>
            </li>
          </ul>
        </div>
      </template>
      <template v-if="state.isEdit || state.isMaking">
        <!--  contents 첨부파일    -->
        <div class="contents-type">
          <label class="sub-text-s2">콘텐츠 타입</label>
          <div class="input-radio-section">
            <input-radio-groups
              :items="types"
              :initial-value="state.contentsForm.type"
              text-size="s2"
              @update-value="(statusObj) => actions.updateType(statusObj)"
            ></input-radio-groups>
          </div>
        </div>

        <input-select-small
          v-model="state.contentsForm.channelCode"
          label="채널"
          :list="channelList"
        ></input-select-small>

        <div class="input-publishedAt">
          <div>
            <label class="sub-text-s2"
              >공개일시<span class="text-purple-50">*</span></label
            >
          </div>
          <input-date-picker
            :date="state.contentsForm.publishedAt"
            :is-deletable="true"
            format="YYYY-MM-DD HH:mm"
            @updateDate="(date) => actions.updatePublishedAt(date)"
          ></input-date-picker>
        </div>

        <!--	url 업로드 영역  -->
        <div>
          <!--        :disabled=""-->
          <input-basic-new
            label="URL"
            type="url"
            placeholder="주소를 입력해주세요."
            :default-value="state.contentsForm.url"
            :disabled="state.isGettingContentMeta"
            :error-message="state.urlErrorMessage"
            @updateData="(value) => actions.updateContentsUrl(value)"
          ></input-basic-new>

          <div class="empty-meta">
            <div class="inner">
              <p class="text-gray-third sub-text-s3">
                유튜브나 vimeo 영상의 URL을 입력해주세요.<br />
                URL을 입력 후 아래 버튼을 누르면 영상 정보를 가져옵니다.
              </p>
              <button-basic
                text="링크에서 정보 가져오기"
                text-size="s2"
                padding="14px"
                @action="actions.getMetaFromUrl()"
              >
                링크에서 정보 가져오기
              </button-basic>
            </div>
          </div>
        </div>

        <!--     drm-start -->
        <div>
          <input-basic-new
            label="URL-dash"
            :default-value="state.contentsForm.urlDash"
            @updateData="(value) => actions.updateUrlDash(value)"
          ></input-basic-new>
          <input-basic-new
            label="URL-hls"
            :default-value="state.contentsForm.urlHls"
            @updateData="(value) => actions.updateUrlHls(value)"
          ></input-basic-new>
          <input-basic-new
            label="drm-cid"
            :default-value="state.contentsForm.drmCid"
            @updateData="(value) => actions.updateDrmCid(value)"
          ></input-basic-new>
        </div>
        <!--     drm-end -->

        <!--	파일 업로드 영역  -->
        <div class="file-upload-form">
          <div>
            <label class="sub-text-s2"> 첨부 파일</label>
          </div>
          <div
            class="file-upload-card"
            @click="actions.openFileStackForContentFile()"
          >
            <i class="fa-solid fa-arrow-up-from-bracket text-default"></i>
            <div class="info">
              <h4 class="title">파일 업로드</h4>
              <p class="sub-text-s3 text-gray-second info-setting">
                <span> * 유료로 판매할 콘텐츠 파일을 등록해주세요. </span>
                <span> * 업로드 가능한 파일 : {{ state.mimeTypeString }} </span>
              </p>
              <div
                v-if="!state.isUploadedFile"
                class="empty bg-gray-005 sub-text-s3 text-gray-third"
              >
                등록된 파일이 없습니다.
              </div>
            </div>
          </div>

          <div
            v-if="state.isUploadedFile && state.uploadedFile"
            class="uploaded-file"
          >
            <memo-icon></memo-icon>
            <span class="name">{{ state.uploadedFile?.name }}</span>
            <button
              class="delete-btn"
              @click.stop="actions.deleteUploadedFile()"
            >
              <delete-icon></delete-icon>
            </button>
          </div>
        </div>

        <div>
          <div class="thumbnail">
            <label>
              <span class="sub-text-s2">썸네일</span>
            </label>

            <div class="thumb-content">
              <img class="session-featured" :src="state.thumbnailImage" />
              <div class="buttons featured-file-overlay">
                <button-basic
                  class="upload"
                  shape="round"
                  bg-color="#E6EAEF"
                  padding="8px"
                  @action="actions.openFileStack()"
                >
                  <template #icon>
                    <upload-icon></upload-icon>
                  </template>
                </button-basic>

                <button-basic
                  class="delete"
                  shape="round"
                  bg-color="#E6EAEF"
                  padding="8px"
                  @action="actions.deleteContentImage()"
                >
                  <template #icon>
                    <delete-icon></delete-icon>
                  </template>
                </button-basic>
              </div>
            </div>
          </div>

          <input-basic-new
            label="제목(세션명)"
            placeholder="제목을 입력해주세요."
            :required="true"
            :default-value="state.contentsForm.title"
            @updateData="(value) => actions.updateContentTitle(value)"
          ></input-basic-new>

          <input-text-box-new
            label="소개"
            placeholder="콘텐츠에 대한 설명을 간단하게 작성해주세요."
            :required="true"
            :rows="10"
            :height-resize="false"
            :model-value="state.contentsForm.description"
            @update:modelValue="
              (value) => {
                actions.updateContentDescription(value);
              }
            "
          ></input-text-box-new>
          <div>
            <quill-editor
              editor-id="session-content"
              label="콘텐츠 본문 내용"
              placeholder="본문 내용 입력하세요."
              :value="state.contentsForm.text"
              editor-container-height="375"
              :embeddable-video="false"
              :is-align-toolbar="true"
              :limit-copypast-text-foramtting="false"
              @updateValue="(value) => actions.updateContentText(value)"
            ></quill-editor>
          </div>
        </div>
      </template>
    </template>

    <template #modalFooter>
      <div class="btn-wrapper">
        <button-basic
          class="cancel"
          text="취소"
          color="#0d0d0d"
          bg-color="#E6EAEF"
          @action="actions.closeModal()"
        ></button-basic>
        <button-basic
          class="save"
          :text="content ? '수정' : '등록'"
          :is-loading="state.isSaveLoading"
          :disabled="!state.activeRegisterBtn || state.isSaveLoading"
          @action="actions.registerContentSession()"
        ></button-basic>
        <!--        :disabled="!state.activeRegisterBtn || state.isSaveLoading"-->
      </div>
    </template>
  </base-modal-side>
</template>

<script>
import BaseModalSide from "../../../../components/common/BaseModalSide.vue";
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  reactive,
  ref,
} from "vue";
import { useStore } from "vuex";
import ApiService from "@/api";
import moment from "moment-timezone";
import FileStackService from "../../../../services/FileStackService";
import MemoIcon from "../../../../components/console/icons/MemoIcon";
import DeleteIcon from "../../../../components/console/icons/DeleteIcon";
import UploadIcon from "../../../../components/console/icons/UploadIcon";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import QuillEditor from "@/components/console/editors/QuillEditor.vue";
import InputBasicNew from "@/components/console/inputs/InputBasicNew.vue";
import InputTextBoxNew from "@/components/console/inputs/InputTextBoxNew.vue";
import InputRadioGroups from "@/components/console/inputs/InputRadioGroups.vue";
import InputSelectSmall from "@/components/console/inputs/InputSelectSmall.vue";
import InputDatePicker from "@/components/console/inputs/InputDatePicker.vue";
import swal from "@/helper/swal";
import SessionContentsModalItem from "@/pages/console/EntClubShow/SessionContentsModalItem/SessionContentsModalItem.vue";
import PlusIcon from "@/components/console/icons/PlusIcon.vue";

export default {
  name: "SessionContentModal",
  components: {
    PlusIcon,
    SessionContentsModalItem,
    InputDatePicker,
    InputSelectSmall,
    InputRadioGroups,
    InputTextBoxNew,
    InputBasicNew,
    QuillEditor,
    ButtonBasic,
    UploadIcon,
    DeleteIcon,
    MemoIcon,
    BaseModalSide,
  },
  props: {
    club: {
      type: Object,
      required: true,
    },
    contentType: {
      type: String,
      default: "pdf",
    },
    content: {
      type: Object,
      required: false,
    },
    clubResourceId: {
      type: String,
      default: "",
    },
  },
  emits: ["hideModal", "saveComplete"],
  setup(props, { emit }) {
    const store = useStore();
    const fileSelector = ref();

    const { proxy } = getCurrentInstance();

    const types = (() => {
      let contentTypes = Object.values(proxy.$const.contentType);
      contentTypes[0].type = 0;
      return contentTypes.map((item) => {
        return { label: item.label, value: item.type };
      });
    })();

    const channelList = [
      {
        value: "",
        text: "채널을 선택해주세요.",
      },
      {
        value: "youtube",
        text: "youtube",
      },
      {
        value: "brunch",
        text: "brunch",
      },
      {
        value: "vimeo",
        text: "vimeo",
      },
      {
        value: "ncloud",
        text: "ncloud",
      },
    ];

    const state = reactive({
      isGettingContentMeta: false,
      successGetMetaFromUrl: false,
      url: "",
      isUploadedFile: false,
      contentsForm: {
        channelCode: "",
        description: "",
        etag: "",
        featuredImage: "",
        identifier: "",
        text: "",
        textHtml: "",
        file: "",
        publishedAt: "",
        isPaid: true,
        attachments: [],
        title: "",
        type: 0,
        url: "",
        urlDash: "",
        urlHls: "",
        drmCid: "",
      },
      thumbnailImage: proxy.$const.blankImage,
      uploadedFile: null,
      fileStackUploadedData: null,
      mimeTypes: computed(() => {
        return ["application/pdf"];
      }),
      mimeTypeOptions: [
        { value: "image/png", label: "png" },
        { value: "image/jpeg", label: "jpg,jpeg" },
        { value: "image/gif", label: "gif" },
        { value: "image/svg+xml", label: "svg" },
        { value: "image/webp", label: "webp" },
        { value: "video/x-msvideo", label: "avi" },
        { value: "video/mpeg", label: "mp4" },
        { value: "video/webm", label: "webm" },
        { value: "audio/x-aac", label: "aac" },
        { value: "audio/midi", label: "midi" },
        { value: "audio/ogg", label: "ogg" },
        { value: "audio/wav", label: "wav" },
        { value: "audio/mpeg", label: "mp3" },
        { value: "audio/mp4", label: "m4a" },
        { value: "text/plain", label: "txt" },
        { value: "application/pdf", label: "pdf" },
      ],
      mimeTypeString: computed(() => {
        let mimeTypeArr = state.mimeTypes.reduce((result, current) => {
          let mimetype = state.mimeTypeOptions.find((item) => {
            return item.value === current;
          });
          if (mimetype) {
            result.push(mimetype.label);
          }
          return result;
        }, []);
        return mimeTypeArr.join(", ");
      }),
      activeRegisterBtn: computed(() => {
        if (state.isMaking || state.isEdit) {
          return (
            state.contentsForm.title.length > 0 &&
            !!state.contentsForm.publishedAt
          );
        } else {
          return !!state.selectedContentResourceId;
        }
      }),
      urlErrorMessage: "",
      titleErrorMessage: computed(() => {
        return !state.contentsForm.title ? "재목은 필수입니다." : "";
      }),
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      isSaveLoading: false,
      isEdit: false,
      isMaking: false,
      selectedContentResourceId: "",
      contentsList: [],
      clubResourceId: "",
    });

    onBeforeMount(async () => {
      let content = props.content;
      if (content) {
        state.successGetMetaFromUrl = true;
        state.contentsForm.channelCode = content.channelCode;
        state.contentsForm.description = content.description;
        state.contentsForm.etag = content.etag;
        state.contentsForm.featuredImage = content.featuredImage
          ? content.featuredImage
          : "";
        state.thumbnailImage = content.featuredImage
          ? content.featuredImage
          : proxy.$const.blankImage;
        state.contentsForm.publishedAt = content.publishedAt;
        state.contentsForm.identifier = content.identifier;
        state.contentsForm.publishedAt = content.publishedAt;
        state.contentsForm.isPaid = content.isPaid;
        state.contentsForm.text = content.text;
        state.contentsForm.textHtml = content.textHtml;
        state.contentsForm.title = content.title;
        state.contentsForm.type = content.type;
        state.contentsForm.url = content.url;
        state.contentsForm.urlDash = content.urlDash;
        state.contentsForm.urlHls = content.urlHls;
        state.contentsForm.drmCid = content.drmCid;
        // attachments
        if (content?.attachments.length > 0) {
          state.isUploadedFile = true;
          state.uploadedFile = content.attachments[0];
        }

        state.isEdit = true;
      } else {
        state.isEdit = false;
      }

      try {
        let paramsData = { clubResourceId: props.clubResourceId };
        console.log(paramsData);
        const result = await ApiService.getClubContents(paramsData);
        const data = result.data.data;
        console.log(data);
        state.contentsList = data;
      } catch (e) {
        swal.messageErrorAlert(e.message);
        // console.log(e);
      }

      // ApiService.getClubContents().then((res) => {
      //   console.log(res);
      //   let data = res.data.data;
      //   state.contentsList = data;
      // });
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      updateType: (statusObj) => {
        state.contentsForm.type = statusObj.value;
      },
      getMetaFromUrl: () => {
        state.isGettingContentMeta = true;
        ApiService.getContentMetaFromUrl({ url: state.contentsForm.url })
          .then((res) => {
            if (res.data.success) {
              state.urlErrorMessage = "";
              state.contentsForm = { ...state.contentsForm, ...res.data.data };
              state.successGetMetaFromUrl = true;
              setTimeout(() => {
                state.thumbnailImage = res.data.data.featuredImage;
                state.contentsForm.featuredImage = res.data.data.featuredImage;
              }, 10);
            } else {
              state.urlErrorMessage = "유효하지않은 URL 입니다.";
              state.successGetMetaFromUrl = false;
            }
          })
          .catch((e) => {
            console.log(e);
            state.urlErrorMessage = "유효하지않은 URL 입니다.";
            state.successGetMetaFromUrl = false;
          });
        state.isGettingContentMeta = false;
      },
      updateContentDescription: (value) => {
        state.contentsForm.description = value;
      },
      updateContentsUrl: (value) => {
        state.contentsForm.url = value;
      },
      registerContentSession: async () => {
        state.isSaveLoading = true;

        console.log("state.isMaking", state.isMaking);
        console.log("state.isEdit", state.isEdit);
        if (state.isMaking || state.isEdit) {
          console.log("폼 작성해서 만들기");
          if (state.activeRegisterBtn) {
            if (state.isEdit) {
              console.log("수정");
              await store
                .dispatch("newClubs/putClubSessionContent", {
                  clubResourceId: props.clubResourceId,
                  contentResourceId: props.content.resourceId,
                  data: state.contentsForm,
                })
                .then(() => {
                  emit("saveComplete");
                });
            } else {
              console.log("생성");
              await store
                .dispatch("newClubs/postClubSessionContent", {
                  clubResourceId: props.clubResourceId,
                  data: state.contentsForm,
                })
                .then(() => {
                  emit("saveComplete");
                });
            }
          }
        } else {
          console.log("콘텐츠 골라서 생성 로직");
          await store
            .dispatch("newClubs/postClubSessions", {
              clubResourceId: props.clubResourceId,
              data: {
                model: "content",
                resourceId: state.selectedContentResourceId,
              },
            })
            .then(() => {
              emit("saveComplete");
            });
          // try {
          //   let payload = {
          //     contentResourceId: state.selectedContentResourceId,
          //   };
          //
          //   const result = await ApiService.postClubSessionContentConnect(
          //     props.clubResourceId,
          //     payload
          //   );
          //
          //   console.log(result);
          //
          //   emit("saveComplete");
          // } catch (e) {
          //   console.log(e);
          //   swal.messageErrorAlert(e.message);
          //   state.isSaveLoading = false;
          // }
        }
      },
      openFileUrl: (attachmentUrl) => {
        window.open(attachmentUrl, "_blank");
      },
      updateContentTitle: (value) => {
        state.contentsForm.title = value;
      },
      updateContentText: (value) => {
        state.contentsForm.text = value;
      },
      openFileStack: () => {
        const fileStack = new FileStackService();
        fileStack.options.transformations.crop.aspectRatio = 16 / 9;
        fileStack.options.onFileUploadFinished = (fileMetaData) => {
          state.thumbnailImage = fileMetaData.url;
          state.contentsForm.featuredImage = fileMetaData.url;
        };

        fileStack.open(fileStack.options);
      },
      openFileStackForContentFile: () => {
        const fileStack = new FileStackService();
        fileStack.options.transformations.crop = false;
        fileStack.options.transformations.circle = false;
        fileStack.options.transformations.rotate = false;
        fileStack.options.accept = state.mimeTypes;
        fileStack.options.onFileUploadFinished = (fileMetaData) => {
          state.contentsForm.file = fileMetaData.url;
          state.contentsForm.filename = fileMetaData.filename;
          state.uploadedFile = fileMetaData.originalFile;
          state.isUploadedFile = true;
        };

        fileStack.open(fileStack.options);
      },
      updateUrlDash: (value) => {
        state.contentsForm.urlDash = value;
      },
      updateUrlHls: (value) => {
        state.contentsForm.urlHls = value;
      },
      updateDrmCid: (value) => {
        state.contentsForm.drmCid = value;
      },
      updatePublishedAt: (value) => {
        state.contentsForm.publishedAt = value ? moment(value) : "";
      },
      deleteContentImage: () => {
        state.contentsForm.file = "";
        state.thumbnailImage = proxy.$const.blankImage;
        state.contentsForm.featuredImage = "";
      },
      deleteUploadedFile: () => {
        state.contentsForm.file = null;
        state.contentsForm.filename = null;
        state.uploadedFile = null;
      },
      switchToForm: () => {
        !state.isMaking && (state.selectedContentResourceId = "");
        state.isMaking = !state.isMaking;
      },
      selectContent: (contentRerouceId) => {
        state.selectedContentResourceId = contentRerouceId;
      },
    };

    return {
      state,
      actions,
      fileSelector,
      types,
      channelList,
    };
  },
};
</script>

<style scoped src="./style.css"></style>
