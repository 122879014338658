<template>
  <div class="badge-hash__tag">
    <span class="sub-text-s2">{{ text }}</span>

    <close-icon
      v-if="showCloseButton"
      class="close-icon"
      @click="actions.deleteHashTag()"
    ></close-icon>
  </div>
</template>

<script>
import CloseIcon from "@/components/console/icons/CloseIcon.vue";

export default {
  name: "BadgeHashTag",
  components: { CloseIcon },
  props: {
    text: {
      type: String,
      required: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const actions = {
      deleteHashTag: () => {
        emit("delete");
      },
    };

    return { actions };
  },
};
</script>

<style scoped>
.badge-hash__tag {
  width: 100%;
  padding: 11px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f6f8fa;
  border-radius: 20px;
}

.close-icon {
  cursor: pointer;
}
</style>
