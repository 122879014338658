<template>
  <base-modal-side @closeModal="actions.closeModal()">
    <template #modalHeader>
      <h3>게시판</h3>
    </template>
    <template #modalBody>
      <button
        class="all-community-btn sub-text-s2"
        :class="{ active: !state.activeCommunity }"
        @click="actions.filterCommunityArticles()"
      >
        전체 게시글
        <span class="text-red-50">
          {{ state.articleCount }}
        </span>
      </button>

      <p class="sub-text-s2 text-gray-second">일반</p>
      <ul class="community-list">
        <li
          v-for="community in state.generalCommunities"
          :key="`community-${community.resourceId}`"
        >
          <community-item
            :community="community"
            :active="
              state.activeCommunity &&
              community.resourceId === state.activeCommunity.resourceId
            "
            @click="actions.filterCommunityArticles(community)"
          ></community-item>
        </li>
      </ul>

      <p class="sub-text-s2 text-purple-20">클럽 라운지</p>
      <ul class="community-list">
        <li
          v-for="community in state.clubCommunities"
          :key="`community-${community.resourceId}`"
        >
          <community-item
            :community="community"
            :active="
              state.activeCommunity &&
              community.resourceId === state.activeCommunity.resourceId
            "
            @click="actions.filterCommunityArticles(community)"
          ></community-item>
        </li>
      </ul>
    </template>

    <template #modalFooter>
      <div class="btn-wrapper">
        <button
          class="setting sub-title-s1"
          @click="actions.toggleCommunitySettingModal()"
        >
          게시판 설정
        </button>
        <button
          class="create sub-title-s1 text-gray-reverse"
          @click="actions.goToCreateArticle()"
        >
          글쓰기
        </button>
      </div>
    </template>
  </base-modal-side>
</template>

<script>
import BaseModalSide from "../../../../components/common/BaseModalSide.vue";
import { reactive, computed } from "vue";
import CommunityItem from "../CommunityItem/CommunityItem";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  components: { CommunityItem, BaseModalSide },
  props: {
    communityList: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      generalCommunities: computed(() => {
        return props.communityList.filter((item) => {
          if (!item.clubResourceId) {
            return item;
          }
        });
      }),
      clubCommunities: computed(() => {
        return props.communityList.filter((item) => {
          if (item.clubResourceId) {
            return item;
          }
        });
      }),
      articlesMeta: computed(() => {
        return store.getters["articles/articlesMeta"];
      }),
      activeCommunity: computed(() => {
        if (store.getters["communities/community"].resourceId) {
          return store.getters["communities/community"];
        }
        return null;
      }),
      articleCount: computed(() => {
        return props.communityList.reduce((sum, item) => {
          return sum + item.articleCount;
        }, 0);
      }),
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      filterCommunityArticles: async (community) => {
        if (community) {
          await store.dispatch("communities/setCommunity", community);
          emit("filterCommunity", community);
        } else {
          await store.dispatch("communities/setCommunity");
          emit("filterCommunity");
        }
      },
      toggleCommunitySettingModal: () => {
        emit("toggleCommunitySettingModal");
      },
      goToCreateArticle: () => {
        router.push({ name: "console.articles.create" });
      },
    };

    return { state, actions };
  },
};
</script>

<style scoped src="./style.css"></style>
