<template>
  <div
    class="page-container-new enter-club-show"
    :class="{ 'active-pc-preview': state.isPcPreview }"
  >
    <page-loading v-if="state.pageLoading"></page-loading>

    <div v-if="!state.pageLoading" class="page-content">
      <div>
        <page-header title="상세 정보" :back-button="true"></page-header>

        <div class="club-detail-wrapper">
          <club-card :club="state.club"></club-card>

          <div class="tab-wrapper">
            <button-tab
              text="기본 정보"
              :active="state.activeTab === 'info'"
              @action="actions.changeTab('info')"
            ></button-tab>
            <button-tab
              text="세션"
              :active="state.activeTab === 'session'"
              @action="actions.changeTab('session')"
            ></button-tab>
            <button-tab
              text="티켓"
              :active="state.activeTab === 'ticket'"
              @action="actions.changeTab('ticket')"
            ></button-tab>
            <button-tab
              text="FAQ"
              :active="state.activeTab === 'faq'"
              @action="actions.changeTab('faq')"
            ></button-tab>
            <button-tab
              text="멤버"
              :active="state.activeTab === 'member'"
              @action="actions.changeTab('member')"
            ></button-tab>
            <button-tab
              text="라운지"
              :active="state.activeTab === 'community'"
              @action="actions.changeTab('community')"
            ></button-tab>
            <button-tab
              text="기대평"
              :active="state.activeTab === 'expect-articles'"
              @action="actions.changeTab('expect-articles')"
            ></button-tab>
          </div>

          <!--        클럽 정보 폼-->
          <div v-show="state.activeTab === 'info'" class="info-tab">
            <club-info-card
              class="info-form"
              title="기본 설정"
              :able-toggle="true"
            >
              <template #content>
                <div class="input-radio-form club-status">
                  <label class="sub-text-s2">상태</label>
                  <div class="input-radio-section">
                    <input-radio-groups
                      :items="state.clubStatus"
                      :initial-value="state.clubForm.status"
                      text-size="s2"
                      @update-value="
                        (statusObj) => actions.updateClubStatus(statusObj)
                      "
                    ></input-radio-groups>
                  </div>
                </div>
                <div class="input-select-form">
                  <input-switch-new
                    v-model="state.clubForm.isPublic"
                    label="공개 여부"
                    placement="left"
                    text-size="s2"
                  ></input-switch-new>
                </div>
                <div v-if="state.clubForm.isPublic" class="club-published-at">
                  <label class="sub-text-s2">공개일 설정</label>
                  <input-date-picker
                    class="input-item"
                    :date="state.clubForm.publishedAt"
                    format="YYYY-MM-DD HH:mm"
                    @updateDate="
                      (value) => actions.updateClubPublishedAt(value)
                    "
                  ></input-date-picker>
                </div>

                <input-image-new
                  class="featured-img"
                  title="썸네일"
                  :accepts="featuredImageAccepts"
                  size-comment="*권장 사이즈 : 600x600"
                  :src="state.prevFeaturedImage"
                  @open-file-selector="actions.openFileStack('pc')"
                  @delete-image="actions.deleteImage('pc')"
                ></input-image-new>

                <input-basic-new
                  class="input-item"
                  label="제목"
                  placeholder="오픈하고 싶은 클럽명을 입력해주세요."
                  :max-length="40"
                  :caption="`${state.clubForm?.title?.length}/40`"
                  :default-value="state.clubForm.title"
                  @updateData="(value) => actions.updateClubTitle(value)"
                ></input-basic-new>
                <input-basic-new
                  class="input-item"
                  label="헤드라인"
                  :default-value="state.clubForm.headline"
                  @updateData="(value) => actions.updateClubHeadline(value)"
                ></input-basic-new>
                <!--            description-->
                <div class="input-item input-item--editor">
                  <quill-editor
                    editor-id="club-description"
                    label="내용"
                    placeholder="클럽을 소개해주세요."
                    :value="state.clubForm.description"
                    :editor-container-height="0"
                    :club-id="state.club.resourceId"
                    @updateValue="
                      (value) => actions.updateClubDescription(value)
                    "
                  >
                  </quill-editor>
                </div>
              </template>
            </club-info-card>
            <club-info-card
              class="info-form"
              title="고급 설정"
              :able-toggle="true"
            >
              <template #content>
                <div class="input-select-form">
                  <input-switch-new
                    v-model="state.clubForm.isComing"
                    label="커밍순으로 설정"
                    placement="left"
                    text-size="s2"
                  ></input-switch-new>
                </div>

                <div class="input-select-form is-temporary">
                  <input-switch-new
                    v-model="state.clubForm.isTemporary"
                    label="임시저장 여부"
                    placement="left"
                    text-size="s2"
                  ></input-switch-new>
                </div>

                <div class="input-select-form">
                  <input-switch-new
                    v-model="state.clubForm.isDuration"
                    label="기간 설정 여부"
                    placement="left"
                    text-size="s2"
                  ></input-switch-new>
                </div>
                <div v-if="state.clubForm.isDuration" class="club-duration">
                  <div>
                    <label class="sub-text-s2">시작일</label>
                    <input-date-picker
                      class="input-item"
                      label="시작일"
                      :date="state.clubForm.startAt"
                      format="YYYY-MM-DD HH:mm"
                      @updateDate="(value) => actions.updateClubStartAt(value)"
                    ></input-date-picker>
                  </div>
                  <div>
                    <label class="sub-text-s2">종료일</label>
                    <input-date-picker
                      class="input-item"
                      label="종료일"
                      :date="state.clubForm.finishAt"
                      format="YYYY-MM-DD HH:mm"
                      @updateDate="(value) => actions.updateClubFinishAt(value)"
                    ></input-date-picker>
                  </div>
                </div>
                <!-- category / displayTags-->
                <div class="category">
                  <div class="label-area">
                    <label class="sub-text-s2">카테고리</label>
                    <span
                      v-if="state.isSelectError"
                      class="sub-text-s2 text-red-50"
                      >최대 5개까지 선택할 수 있어요.</span
                    >
                  </div>
                  <div class="displays-card bg-gray-03">
                    <ul>
                      <li
                        v-for="displayTag in state.clubMeta.displayTags"
                        :key="`display-tag-${displayTag.id}`"
                      >
                        <button
                          class="tag-btn"
                          :class="
                            state.selectedDisplayTags.findIndex((item) => {
                              return item.id === displayTag.id;
                            }) >= 0
                              ? 'active'
                              : ''
                          "
                          @click="actions.setDisplayTag(displayTag)"
                        >
                          {{ `${displayTag.name}` }}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div>
                    <label class="b-text-3 text-gray-second">
                      <div>
                        최대 5개까지 선택할 수 있어요. 드래그 기능을 통해 순서를
                        변경할 수 있습니다.
                      </div>
                      <div>두 번째 태그까지만 클럽 뱃지로 노출됩니다.</div>
                    </label>
                  </div>
                  <draggable
                    v-model="state.selectedDisplayTags"
                    class="draggable-area"
                    item-key="id"
                    handle=".handle"
                  >
                    <template #item="{ element }">
                      <div class="selected-tag bg-gray-studio">
                        <button class="handle">
                          <handler-icon fill-color="#9EA0A6"></handler-icon>
                          <span class="sub-title-s2">
                            {{ element.name }}
                          </span>
                        </button>
                        <button
                          class="pop"
                          @click="actions.popSelectedDisplayTags(element.id)"
                        >
                          <close-icon fill-color="#818287"></close-icon>
                        </button>
                      </div>
                    </template>
                  </draggable>
                </div>
                <!-- hashtag-->
                <div class="tag-section">
                  <div>
                    <span class="sub-text-s2">해시태그</span>
                    <p class="sub-text-s3 text-gray-second">
                      구성은 최대 3개까지 작성할 수 있습니다.
                    </p>
                  </div>

                  <input-tags
                    class="tags-input"
                    :is-custom-style="true"
                    placeholder="태그를 입력해 주세요."
                    :tags="state.clubKeywords"
                    :max-tags="3"
                    @addTag="(tag) => actions.addClubKeyword(tag)"
                    @removeTag="(tag) => actions.removeClubKeyword(tag)"
                  ></input-tags>
                </div>
                <!--description-target-->
                <div class="description-target">
                  <label class="sub-text-s2"> 추천 대상 </label>

                  <input-tags
                    class="tags-input"
                    :is-custom-style="true"
                    placeholder="추천 대상을 입력해 주세요."
                    :tags="state.descriptionTarget"
                    @addTag="(tag) => actions.addDescriptionTarget(tag)"
                    @removeTag="(tag) => actions.removeDescriptionTarget(tag)"
                  ></input-tags>
                </div>
                <!--            intro_host-->
                <input-text-box-new
                  label="호스트 소개"
                  :rows="3"
                  :height-resize="false"
                  :model-value="state.clubForm.introHost"
                  @update:modelValue="
                    (value) => {
                      actions.updateClubIntroHost(value);
                    }
                  "
                ></input-text-box-new>
                <!--            intro_session-->
                <input-text-box-new
                  label="세션 소개"
                  :rows="3"
                  :height-resize="false"
                  :model-value="state.clubForm.introSession"
                  @update:modelValue="
                    (value) => {
                      actions.updateClubIntroSession(value);
                    }
                  "
                ></input-text-box-new>
                <!--            intro_schedule-->
                <input-text-box-new
                  label="스케줄 소개"
                  :rows="3"
                  :height-resize="false"
                  :model-value="state.clubForm.introSchedule"
                  @update:modelValue="
                    (value) => {
                      actions.updateClubIntroSchedule(value);
                    }
                  "
                ></input-text-box-new>
              </template>
            </club-info-card>

            <club-info-card
              class="info-form"
              title="디자인 추가 설정"
              :able-toggle="true"
            >
              <template #content>
                <input-image-new
                  class="featured-img"
                  title="모바일 이미지"
                  :accepts="featuredImageAccepts"
                  size-comment="*권장 사이즈 : x"
                  :src="state.prevMfeaturedImage"
                  @open-file-selector="actions.openFileStack('mo')"
                  @delete-image="actions.deleteImage('mo')"
                ></input-image-new>

                <input-image-new
                  class="featured-img"
                  title="커버 이미지"
                  :accepts="featuredImageAccepts"
                  size-comment="*권장 사이즈 : x"
                  :src="state.prevCover"
                  @open-file-selector="actions.openFileStack('cover')"
                  @delete-image="actions.deleteImage('cover')"
                ></input-image-new>

                <input-image-new
                  class="featured-img"
                  title="공유용 이미지"
                  :accepts="featuredImageAccepts"
                  size-comment="*권장 사이즈 : x"
                  :src="state.prevShareImage"
                  @open-file-selector="actions.openFileStack('share')"
                  @delete-image="actions.deleteImage('share')"
                ></input-image-new>

                <input-image-new
                  class="featured-img"
                  title="SEO 이미지"
                  :accepts="featuredImageAccepts"
                  size-comment="*권장 사이즈 : x"
                  :src="state.prevSeoShareImage"
                  @open-file-selector="actions.openFileStack('seo')"
                  @delete-image="actions.deleteImage('seo')"
                ></input-image-new>
              </template>
            </club-info-card>

            <club-info-card
              class="info-form"
              title="약관 설정"
              :able-toggle="true"
            >
              <template #content>
                <div class="input-select-form">
                  <input-switch-new
                    v-model="state.clubForm.isPolicy"
                    label="약관 사용 여부"
                    placement="left"
                    text-size="s2"
                  ></input-switch-new>
                </div>
                <div>
                  <input-text-box-new
                    label="약관 내용"
                    placeholder="약관 내용을 입력하세요."
                    :rows="10"
                    :height-resize="false"
                    :model-value="state.clubForm.policy"
                    @update:modelValue="
                      (value) => {
                        actions.updateClubPolicy(value);
                      }
                    "
                  ></input-text-box-new>
                </div>
              </template>
            </club-info-card>

            <div class="btn-section">
              <button-basic
                class="put-btn"
                padding="14px 0"
                text="저장하기"
                :disabled="state.isClubSaveLoading"
                :is-loading="state.isClubSaveLoading"
                loading-icon-width="20"
                loading-icon-height="20"
                loading-iconview-box="0 0 24 24"
                @click="actions.putClub()"
              ></button-basic>
            </div>
          </div>

          <!--  티켓 탭      -->
          <div v-show="state.activeTab === 'ticket'" class="ticket-section">
            <!--	Ticket Empty UI  -->
            <div
              v-if="state.club.allPlans.length === 0"
              class="session-empty-card"
            >
              <!--            <button class="add-btn" @click="actions.openPlanModal()">-->
              <!--              티켓 추가-->
              <!--            </button>켓-->
              <div class="directory-img">
                <img src="/assets/images/club/session_empty.png" width="72" />
              </div>
              <p class="sub-text-s2 text-gray-second">
                생성된 티켓이 없습니다.<br />
                티켓 생성 버튼을 통해 티켓을 추가해보세요!
              </p>
              <button class="creat-btn" @click="actions.openPlanModal()">
                <span class="sub-title-s2 text-default">티켓 추가하기</span>
                <arrow-icon class="arrow-icon"></arrow-icon>
              </button>
            </div>

            <div v-if="state.club.allPlans.length > 0">
              <div class="btn-section">
                <button-basic
                  class="create-btn"
                  padding="14px"
                  text="티켓 추가"
                  @click="actions.openPlanModal()"
                >
                </button-basic>
                <!--              <button-->
                <!--                class="add-plan-btn sub-title-s1 bg-gray-020"-->
                <!--                @click="actions.openPlanModal()"-->
                <!--              >-->
                <!--                티켓 추가-->
                <!--              </button>-->
                <button-basic
                  class="create-btn"
                  padding="14px"
                  text="티켓 관리"
                  @click="actions.openPlanListModal()"
                ></button-basic>
              </div>
              <p class="sub-text-s3 text-gray-second ticket-selling-notice">
                이미 판매된 콘텐츠의 티켓은 수정할 수 없습니다. 필요 시 우측
                하단 고객센터로 문의주세요.
              </p>
              <ul v-if="false" class="ticket-list-wrapper">
                <li
                  v-for="plan in state.club.allPlans"
                  :key="`plan-${plan.resourceId}`"
                  class="ticket-item"
                >
                  <plan-list-item :plan="plan"></plan-list-item>
                </li>
                <li class="ticket-item empty">
                  <div class="ticket-card">
                    <div class="content">
                      <ticket-icon
                        width="48"
                        height="48"
                        fill-color="#ffffff"
                      ></ticket-icon>
                      <p class="text-default sub-text-s2">
                        티켓을 추가해 보세요!<br />
                        (판매가 시작된 이후에는 추가할 수 없습니다.)
                      </p>
                    </div>
                    <div class="price-section"></div>
                  </div>
                </li>
              </ul>

              <div>
                <div class="ticket-info">
                  <div class="ticket-info-card pc">
                    <div class="header b-text-3 text-gray-third">
                      <span class="col1">티켓명</span>
                      <span class="col2">티켓별 매출</span>
                      <span class="col3">티켓상태</span>
                      <span class="col4">티켓 가격</span>
                      <span class="col5">판매 수량</span>
                      <span class="col6">남은 수량</span>
                    </div>

                    <ul>
                      <li
                        v-for="plan in state.club.allPlans"
                        :key="`ticket-${plan.resourceId}`"
                      >
                        <club-plan-item
                          :plan="plan"
                          :club-resource-id="state.club.resourceId"
                        ></club-plan-item>
                      </li>
                    </ul>
                  </div>
                  <div class="ticket-info-card mo">
                    <ul>
                      <li
                        v-for="plan in state.club.allPlans"
                        :key="`ticket-${plan.resourceId}`"
                      >
                        <club-plan-item-mobile
                          :plan="plan"
                        ></club-plan-item-mobile>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--          클럽 세션 탭-->
          <div v-if="state.activeTab === 'session'" class="session-tab">
            <div v-if="state.clubSessions.length > 0" class="btn-section">
              <button-basic
                class="create-btn"
                padding="14px"
                text="투표 추가"
                @action="actions.openSessionPollModal()"
              ></button-basic>
              <button-basic
                class="create-btn"
                padding="14px"
                text="라이브 추가"
                @action="actions.openSessionMeetingModal()"
              ></button-basic>
              <button-basic
                class="create-btn"
                padding="14px"
                text="콘텐츠 추가"
                @action="actions.openContentSessionModal()"
              ></button-basic>
            </div>
            <div class="session-header sub-text-s3 text-gray-second">
              <span class="col1">세션</span>
              <span class="col2">기능</span>
            </div>

            <!--  클럽 세션  -->
            <div
              v-if="state.clubSessions.length > 0"
              class="session-list-wrapper"
            >
              <div>{{ state.deletingSessionResourceId }}</div>

              <ul>
                <draggable
                  v-model="state.clubSessions"
                  handle=".handle"
                  item-key="resourceId"
                  @change="actions.sortClubSessions(state.clubSessions)"
                >
                  <template #item="{ element, index }">
                    <li>
                      <session-item
                        :draggable="true && state.clubSessions.length > 1"
                        :index="index"
                        :session="element"
                        :club="state.club"
                        :is-sorting="state.isSortingSessions"
                        @openEditMeetingModal="
                          (session) => actions.openSessionMeetingModal(session)
                        "
                        @openDeleteMeetingModal="
                          (session) =>
                            actions.actionSessionDeleteModal(
                              'meeting',
                              'open',
                              session
                            )
                        "
                        @openEditContentModal="
                          (session) => actions.openSessionContentModal(session)
                        "
                        @openDeleteContentModal="
                          (session) =>
                            actions.actionSessionDeleteModal(
                              'content',
                              'open',
                              session
                            )
                        "
                        @openLiveFinishModal="actions.openLiveFinishModal()"
                        @edit-session-poll="
                          (session) => actions.editSessionPoll(session)
                        "
                        @openDeletePollModal="
                          (session) =>
                            actions.actionSessionDeleteModal(
                              'poll',
                              'open',
                              session
                            )
                        "
                      ></session-item>
                    </li>
                  </template>
                </draggable>
              </ul>
            </div>

            <!--	session empty  -->
            <div
              v-if="state.clubSessions.length === 0"
              class="session-empty-card"
            >
              <div class="directory-img">
                <img
                  src="/assets/images/channels/contents-empty.png"
                  width="72"
                />
              </div>
              <p class="sub-text-s2 text-gray-second">
                생성된 세션이 없습니다.<br />
                세션 생성 버튼을 통해 세션을 구성해보세요!
              </p>
              <button
                class="creat-btn live"
                @click="actions.openSessionMeetingModal()"
              >
                <span class="sub-title-s2 text-default"
                  >라이브 세션 추가하기</span
                >
                <arrow-icon class="arrow-icon"></arrow-icon>
              </button>
              <button
                class="creat-btn"
                @click="actions.openContentSessionModal()"
              >
                <span class="sub-title-s2 text-default"
                  >콘텐츠 세션 추가하기</span
                >
                <arrow-icon class="arrow-icon"></arrow-icon>
              </button>
              <button class="creat-btn" @click="actions.openSessionPollModal()">
                <span class="sub-title-s2 text-default"
                  >투표 세션 추가하기</span
                >
                <arrow-icon class="arrow-icon"></arrow-icon>
              </button>
            </div>
          </div>

          <!--          faq 탭-->
          <div v-show="state.activeTab === 'faq'">
            <div class="faq-section">
              <div class="btn-section">
                <p class="description sub-text-s2 text-gray-second">
                  라이브, 콘텐츠, 티켓에 관한 FAQ를 작성해주세요.
                </p>
                <button-basic
                  class="create-btn"
                  padding="14px 14px"
                  text="FAQ 추가"
                  @action="actions.openFaqModal()"
                >
                </button-basic>
              </div>
              <!--              <p class="description sub-text-s2 text-gray-second">-->
              <!--                라이브, 콘텐츠, 티켓에 관한 FAQ를 작성해주세요.-->
              <!--              </p>-->

              <ul class="faq-list">
                <li
                  v-for="faq in state.fixedFaqs"
                  :key="`faq-item-${faq.resourceId}`"
                  :class="{ 'fixed-faq': faq.isFixed }"
                >
                  <faq-item
                    :faq="faq"
                    :club-resource-id="state.club.resourceId"
                  ></faq-item>
                </li>
                <draggable
                  v-model="state.clubFaqs"
                  item-key="clubsFaq"
                  handle=".handle"
                  @change="actions.sortFaq(state.clubFaqs)"
                >
                  <template #item="{ element }">
                    <li>
                      <faq-item
                        :faq="element"
                        :club-resource-id="state.club.resourceId"
                        :is-sorting="state.isSortingFaqs"
                        @updateFaqs="actions.updateClubFaqs()"
                        @openEditFaqModal="(faq) => actions.openFaqModal(faq)"
                      ></faq-item>
                    </li>
                  </template>
                </draggable>
              </ul>
            </div>
          </div>

          <!--	멤버 탭  -->
          <div v-show="state.activeTab === 'member'">
            <div class="member-search-wrapper">
              <input-search-new
                v-model="state.searchKey"
                shape="round"
                placeholder="검색어를 입력해주세요."
                search-type="pressEnter"
                @search="getClubMembers()"
              ></input-search-new>
            </div>

            <div class="member-header sub-text-s3 text-gray-second">
              <span class="col1">유형</span>
              <span class="col2">닉네임</span>
              <span class="col3">이메일</span>
              <span class="col4">결제 티켓</span>
              <span class="col5">결제 금액</span>
              <span class="col6"> 이용 포인트 </span>
              <span class="col7">구매 일시</span>
              <span class="col8"> 최근 접속 일시 </span>
            </div>

            <div
              v-if="state.clubMembers.length > 0"
              class="member-list-wrapper"
            >
              <ul>
                <li
                  v-for="clubMember in state.clubMembers"
                  :key="`club-member-${clubMember.resourceId}`"
                >
                  <club-member-item
                    :club="state.club"
                    :club-member="clubMember"
                  ></club-member-item>
                </li>
              </ul>

              <div
                v-if="state.clubMembersMeta.pagination.lastPage !== 1"
                class="member-pagination"
              >
                <pagination
                  :pagination="state.clubMembersMeta.pagination"
                  @getPageData="(page) => getClubMembers({ page: page })"
                ></pagination>
              </div>
            </div>

            <div
              v-if="state.clubMembers.length === 0"
              class="member-empty-card"
            >
              <div class="directory-img">
                <img src="/assets/images/club/member_empty.png" width="72" />
              </div>
              <p class="sub-text-s2 text-gray-second">아직 멤버가 없습니다.</p>
            </div>
          </div>

          <!--          클럽 커뮤니티 - 클럽을 결제한 유저만 이용-->
          <div v-show="state.activeTab === 'community'">
            <ent-club-articles
              :community-resource-id="state.communityResourceId"
            ></ent-club-articles>
          </div>
          <!-- 클럽 기대평 - 클럽을 결제하지 않아도 이용할 수 있는 곳-->
          <div v-show="state.activeTab === 'expect-articles'">
            <div class="list-section">
              <ul v-if="state.isArticles" class="review-list">
                <li
                  v-for="(article, index) in state.club.community.articles"
                  :key="`lecture-article-${article.resourceId}`"
                >
                  <review-item
                    :index="index"
                    :article="article"
                    @successArticleReport="
                      actions.showArticleReportSuccessModal()
                    "
                  ></review-item>
                </li>
              </ul>

              <empty-list
                v-if="!state.isArticles"
                icon-image="/assets/images/empties/empty_heart.jpeg"
                text="아직 멤버가 남긴 글이 없어요"
              ></empty-list>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="iframe" :class="{ 'active-pc-preview': state.isPcPreview }">
          <div class="header text-gray-reverse sub-text-s2">
            {{
              state.isPcPreview
                ? "PC 미리보기 화면입니다."
                : "모바일 미리보기 화면입니다."
            }}
            <button
              class="sub-text-s2"
              style="padding: 0; color: #ff1b94; margin-left: 20px"
              @click="actions.togglePreviewSize()"
            >
              <div
                v-show="!state.isPcPreview"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 8px;
                "
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M28 6H4V20H28V6ZM4 4C2.89543 4 2 4.89543 2 6V20C2 21.1046 2.89543 22 4 22H28C29.1046 22 30 21.1046 30 20V6C30 4.89543 29.1046 4 28 4H4Z"
                    fill="#FF1B94"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15 26L15 22L17 22L17 26L15 26Z"
                    fill="#FF1B94"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21 27H11V25H21V27Z"
                    fill="#FF1B94"
                  />
                </svg>
                PC 사이즈로 보기
              </div>
              <div v-show="state.isPcPreview">MO 사이즈로 보기</div>
            </button>
          </div>
          <iframe
            id="ent-club-iframe"
            class="body"
            :src="state.clubURl"
          ></iframe>
        </div>
      </div>
    </div>

    <teleport :disabled="true">
      <warning-modal
        v-if="state.showLiveFinishWarningModal"
        :show-cancel-button="false"
        warning-title="종료된 라이브 입니다."
        confirm-text="확인"
        @confirm="actions.confirmLiveWarning()"
        @hideModal="actions.closeLiveFinishModal()"
      ></warning-modal>

      <warning-modal
        v-if="state.showSessionDeleteModal.meeting"
        warning-title="라이브를 삭제하시겠습니까?"
        warning-text="삭제 후 복구는 불가능합니다."
        confirm-text="삭제"
        @hideModal="actions.actionSessionDeleteModal('meeting', 'close')"
        @confirm="actions.actionSessionDeleteModal('meeting', 'delete')"
      ></warning-modal>

      <warning-modal
        v-if="state.showSessionDeleteModal.content"
        warning-title="콘텐츠를 삭제하시겠습니까?"
        warning-text="삭제 후 복구는 불가능합니다."
        confirm-text="삭제"
        @hideModal="actions.actionSessionDeleteModal('content', 'close')"
        @confirm="actions.actionSessionDeleteModal('content', 'delete')"
      ></warning-modal>

      <warning-modal
        v-if="state.showSessionDeleteModal.poll"
        warning-title="투표를 삭제하시겠습니까?"
        warning-text="삭제 후 복구는 불가능합니다."
        confirm-text="삭제"
        @hideModal="actions.actionSessionDeleteModal('poll', 'close')"
        @confirm="actions.actionSessionDeleteModal('poll', 'delete')"
      ></warning-modal>

      <faq-modal
        v-if="state.showFaqModal"
        :faq="state.editingFaq"
        :club-resource-id="state.club.resourceId"
        @hideModal="actions.closeFaqModal()"
        @save-complete="actions.createFaqComplete()"
      ></faq-modal>

      <plan-modal
        v-if="state.showPlanModal"
        :plan="state.editingPlan"
        :club="state.club"
        @hideModal="actions.closePlanModal()"
        @openPlanListModal="actions.openPlanListModal()"
        @saveComplete="actions.planCreateComplete()"
      ></plan-modal>

      <plan-list-modal
        v-if="state.showPlanListModal"
        :club="state.club"
        :plans="state.club.allPlans"
        @hideModal="actions.closePlanListModal()"
        @openPlanModal="actions.openPlanModal()"
        @openPlanEditModal="(plan) => actions.openPlanModal(plan)"
        @deleteComplete="
          (planResourceId) => actions.setSelectedPlan(planResourceId)
        "
      ></plan-list-modal>

      <session-content-modal
        v-if="state.showSessionContentModal"
        :club-resource-id="state.club.resourceId"
        :content="state.editingSession?.content"
        @hideModal="actions.closeSessionContentModal()"
        @saveComplete="actions.contentCreateComplete()"
      ></session-content-modal>

      <session-meeting-modal
        v-if="state.showSessionLiveModal"
        relation="club-session"
        :club="state.club"
        :meeting="state.editingSession?.meeting"
        :session-id="state.editingSession?.resourceId"
        @hideModal="actions.closeSessionLiveModal()"
        @saveComplete="actions.liveCreateComplete()"
        @updateMeetingStatus="actions.updateMeetingStatus()"
      ></session-meeting-modal>

      <session-poll-modal
        v-if="state.showSessionPollModal"
        :club="state.club"
        :polls-sessions="state.clubPollSessions"
        @hideModal="actions.closeSessionPollModal()"
        @saveComplete="actions.pollCreateComplete()"
      ></session-poll-modal>
    </teleport>
  </div>
</template>

<script>
import PageHeader from "../../../components/console/headers/PageHeader";
import PageLoading from "../../../components/console/loadings/PageLoading";
import { computed, getCurrentInstance, onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";
import ClubCard from "./ClubCard/ClubCard";
import { useRoute, useRouter } from "vue-router";
import ButtonTab from "../../../components/console/buttons/ButtonTab";
import ClubMemberItem from "./ClubMemberItem/ClubMemberItem";
import SessionItem from "../../../components/console/sessions/SessionItem/SessionItem";
import ArrowIcon from "../../../components/console/icons/ArrowIcon";
import WarningModal from "../../../components/console/modals/WarningModal";
import Pagination from "../../../components/console/pagination/Pagination";
import InputBasicNew from "../../../components/console/inputs/InputBasicNew";
import InputDatePicker from "../../../components/console/inputs/InputDatePicker";
import FileStackService from "@/services/FileStackService";
import ButtonBasic from "../../../components/console/buttons/ButtonBasic";
import SessionContentModal from "./SessionContentModal/SessionContentModal";
import SessionMeetingModal from "../../../components/console/sessions/SessionMeetingModal/SessionMeetingModal";
import SessionPollModal from "@/pages/console/EntClubShow/SessionPollModal/SessionPollModal.vue";
import { planListPrice, planPrice, planDiscount } from "@/helper/plan";
import PlanListModal from "./PlanListModal/PlanListModal";
import PlanModal from "./PlanModal/PlanModal";
import PlanListItem from "./PlanListItem/PlanListItem";
import InputTags from "../../../components/console/inputs/InputTags";
import moment from "moment-timezone";
import { cloneDeep } from "lodash";
import ClubPlanItem from "./ClubPlanItem/ClubPlanItem";
import swal from "@/helper/swal";
import EntClubArticles from "./EntClubArticles/EntClubArticles";
import ApiService from "@/api";
import InputSearchNew from "@/components/console/inputs/InputSearchNew.vue";
import ClubInfoCard from "@/pages/console/EntClubShow/ClubInfoCard/ClubInfoCard.vue";
import InputImageNew from "@/components/console/inputs/InputImageNew.vue";
import QuillEditor from "@/components/console/editors/QuillEditor.vue";
import FaqItem from "@/pages/console/EntClubShow/FaqItem/FaqItem.vue";
import draggable from "vuedraggable";
import FaqModal from "@/pages/console/EntClubShow/FaqModal/FaqModal.vue";
import ReviewItem from "@/pages/console/ClubShow/ReviewItem/ReviewItem.vue";
import EmptyList from "@/components/console/empties/EmptyList.vue";
import ClubPlanItemMobile from "@/pages/console/EntClubs/ClubPlanItemMobile/ClubPlanItemMobile.vue";
import InputSwitchNew from "@/components/console/inputs/InputSwitchNew.vue";
import InputTextBoxNew from "@/components/console/inputs/InputTextBoxNew.vue";
import InputRadioGroups from "@/components/console/inputs/InputRadioGroups.vue";
import CloseIcon from "@/components/console/icons/CloseIcon.vue";
import HandlerIcon from "@/components/console/icons/HandlerIcon.vue";

export default {
  name: "EntClubShow",
  components: {
    HandlerIcon,
    CloseIcon,
    InputRadioGroups,
    InputTextBoxNew,
    ClubPlanItemMobile,
    EmptyList,
    ReviewItem,
    FaqModal,
    draggable,
    FaqItem,
    QuillEditor,
    InputImageNew,
    ClubInfoCard,
    InputSearchNew,
    EntClubArticles,
    InputDatePicker,
    InputBasicNew,
    Pagination,
    WarningModal,
    ArrowIcon,
    SessionItem,
    ClubMemberItem,
    ButtonTab,
    ClubCard,
    PageLoading,
    PageHeader,
    ButtonBasic,
    SessionContentModal,
    SessionMeetingModal,
    PlanListModal,
    PlanModal,
    PlanListItem,
    InputTags,
    ClubPlanItem,
    InputSwitchNew,
    SessionPollModal,
  },
  props: {},
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const clubResourceId = route.params.clubResourceId;
    const { proxy } = getCurrentInstance();
    const featuredImageAccepts = ["jpg", "jpeg", "png"];

    const state = reactive({
      pageLoading: true,
      club: computed(() => {
        return store.getters["newClubs/newClub"];
      }),
      clubMeta: computed(() => {
        return store.getters["newClubs/newClubMeta"];
      }),
      clubSessions: [],
      clubMeetingSessions: [],
      clubContentSessions: [],
      clubPollSessions: [],
      clubMembers: computed(() => {
        return store.getters["newClubs/newClubMembers"].filter((item) => {
          return !item.isHost;
        });
      }),
      clubMembersMeta: computed(() => {
        return store.getters["newClubs/newClubMembersMeta"];
      }),
      searchKey: "",
      allChecked: false,
      activeTab: "info",
      showLiveFinishWarningModal: false,
      prevFeaturedImage: computed(() => {
        return state.clubForm.featuredImage;
      }),
      prevMfeaturedImage: computed(() => {
        return state.clubForm.mFeaturedImage;
      }),
      prevShareImage: computed(() => {
        return state.clubForm.shareImage;
      }),
      prevSeoShareImage: computed(() => {
        return state.clubForm.seoShareImage;
      }),
      prevCover: computed(() => {
        return state.clubForm.cover;
      }),
      clubForm: {
        status: 0,
        type: 0,
        typeLabel: "",
        title: "",
        headline: "",
        description: "",
        descriptionTarget: "",
        featuredImage: proxy.$const.blankImage,
        mFeaturedImage: proxy.$const.blankImage,
        shareImage: proxy.$const.blankImage,
        seoShareImage: proxy.$const.blankImage,
        displayTags: [],
        tags: [],
        attachments: [],
        keywords: [],
        cover: proxy.$const.blankImage,
        introHost: "",
        introSchedule: "",
        introSession: "",
        isDuration: false,
        isPolicy: false,
        policy: "",
        isComing: true,
        isPublic: false,
        publishedAt: "",
        isTemporary: false,
        startAt: "",
        finishAt: "",
        // note 미노출, 제거할 데이터
        introTicket: "",
        chargeTickets: 0,
        lang: "",
        isFunding: false,
        personnel: 15,
      },
      clubKeywords: [],
      descriptionTarget: [],
      selectedDisplayTags: [],
      isSelectError: false,
      isLiveSession: computed(() => {
        return state.club.contentType === "meeting";
      }),
      showSessionLiveModal: false,
      showSessionContentModal: false,
      showSessionPollModal: false,

      //note 티켓관련
      showPlanModal: false,
      showPlanListModal: false,
      editingPlan: null,
      selectedPlan: null,
      selectedPlanListPrice: computed(() => {
        return planListPrice(state.selectedPlan);
      }),
      selectedPlanPrice: computed(() => {
        return planPrice(state.selectedPlan);
      }),
      selectedPlanDiscount: computed(() => {
        return planDiscount(state.selectedPlan);
      }),
      editingSession: null,
      editingPollSession: null,
      showSessionDeleteModal: {
        content: false,
        meeting: false,
        poll: false,
      },
      deletingSessionResourceId: "",
      //todo 라운지 설정 store 정리하기
      communities: computed(() => {
        return store.getters["communities/communities"];
      }),
      filters: {
        communityResourceId: store.getters["communities/community"].resourceId,
        answer: false,
        unanswered: false,
        myWriting: false,
        bookmark: false,
        isPinned: false,
        isNotice: false,
        order: null,
        q: "",
        page: 1,
      },
      communityResourceId: "",
      isClubSaveLoading: false,
      clubURl: computed(() => {
        return `${process.env.VUE_APP_ENTER_URL}/${store.getters["auth/user"].aliasName}/clubs/${clubResourceId}`;
      }),
      fixedFaqs: computed(() => {
        return store.getters["newClubs/newClubFaqs"].filter((faq) => {
          return faq.isFixed;
        });
      }),
      clubFaqs: [],
      showFaqModal: false,
      editingFaq: null,
      isSortingSessions: false,
      isSortingFaqs: false,
      isArticles: computed(() => {
        return (
          state.club?.community?.articles.length > 0 &&
          state.club?.community.boardType === "EXPECT"
        );
      }),
      isPcPreview: false,
      clubStatus: [
        {
          label: proxy.$const.clubStatus.open.label,
          value: proxy.$const.clubStatus.open.value,
        },
        {
          label: proxy.$const.clubStatus.reservation.label,
          value: proxy.$const.clubStatus.reservation.value,
        },
        {
          label: proxy.$const.clubStatus.finished.label,
          value: proxy.$const.clubStatus.finished.value,
        },
        {
          label: proxy.$const.clubStatus.hidden.label,
          value: proxy.$const.clubStatus.hidden.value,
        },
      ],
    });
    // TODO 우선 기존 api 로 데이터 불러오기
    onBeforeMount(async () => {
      await setClubShowPage();
      activateTab();
    });

    // todo 임시임, 라우터별로 분리해야함.
    const activateTab = () => {
      let backRouter = router.options.history.state.back;
      if (backRouter && backRouter.includes("/articles/")) {
        state.activeTab = "community";
      }
    };

    const setClubShowPage = () => {
      store
        .dispatch("newClubs/getClub", { clubResourceId: clubResourceId })
        .then(async () => {
          await setUpClubSessions();
          await setUpClubMeetingSessions();
          await setUpClubContentSessions();
          await setUpClubPollSessions();
          await setUpClubForm();
          await setUpCommunity();
          await setUpClubFaqs();
          state.pageLoading = false;
        });

      getClubMembers();
    };

    const getClubMembers = (data) => {
      store.dispatch("newClubs/getClubMembers", {
        clubResourceId: clubResourceId,
        params: {
          page: data ? data.page : 1,
          q: state.searchKey,
        },
      });
    };

    const setUpClubSessions = () => {
      state.clubSessions = [
        ...state.clubSessions,
        ...store.getters["newClubs/newClub"].sessions,
      ];
    };

    const setUpClubMeetingSessions = () => {
      let clubMeetingSessions = store.getters[
        "newClubs/newClub"
      ].sessions.filter((session) => {
        return session?.meeting;
      });

      state.clubMeetingSessions = [
        ...state.clubMeetingSessions,
        ...clubMeetingSessions,
      ];
    };

    const setUpClubContentSessions = () => {
      let clubContentSessions = store.getters[
        "newClubs/newClub"
      ].sessions.filter((session) => {
        return session?.content;
      });

      state.clubContentSessions = [
        ...state.clubContentSessions,
        ...clubContentSessions,
      ];
    };

    const setUpClubPollSessions = () => {
      let clubPollSessions = store.getters["newClubs/newClub"].sessions.filter(
        (session) => {
          return session?.poll;
        }
      );

      state.clubPollSessions = [...state.clubPollSessions, ...clubPollSessions];
    };

    const setUpCommunity = async () => {
      await store.dispatch("communities/getCommunities").then(() => {
        let clubResourceId = store.getters["newClubs/newClub"].resourceId;
        if (clubResourceId) {
          let community = state.communities.find((item) => {
            return item.clubResourceId === clubResourceId;
          });
          state.filters.communityResourceId = community?.resourceId;
          state.communityResourceId = community?.resourceId;
          store.dispatch("communities/setCommunity", community);
        }
      });
      await store.dispatch("articles/getArticles", state.filters);
    };

    const setUpClubFaqs = () => {
      state.clubFaqs = [
        ...state.clubFaqs,
        ...store.getters["newClubs/newClubFaqs"].filter((faq) => {
          return !faq.isFixed;
        }),
      ];
    };

    const updateClubSessions = (session) => {
      let sessionId = session.resourceId;
      const newSessionIndex = store.getters[
        "newClubs/newClub"
      ].sessions.findIndex((el) => el.resourceId === sessionId);
      state.clubSessions[newSessionIndex] =
        store.getters["newClubs/newClub"].sessions[newSessionIndex];
    };

    const checkNullDate = (obj, key) => {
      if (typeof obj[`${key}`] !== "string") {
        obj[`${key}`] = "";
      }
    };

    const setUpClubForm = () => {
      let copyClub = cloneDeep(state.club);
      checkNullDate(copyClub, "publishedAt");
      checkNullDate(copyClub, "startAt");
      checkNullDate(copyClub, "finishAt");

      state.clubForm = { ...state.clubForm, ...copyClub };

      if (copyClub.displayTags.length > 0) {
        // 데이터 초기화 한 후 업데이트
        state.selectedDisplayTags = [];
        state.clubForm.displayTags = [];
        copyClub.displayTags.forEach((element, index) => {
          state.selectedDisplayTags[index] = element;
        });
      }

      if (copyClub.keywords.length > 0) {
        let keywords = [];

        for (let i = 0; i < copyClub.keywords.length; i++) {
          keywords[i] = { index: i, val: copyClub.keywords[i] };
        }

        state.clubKeywords = keywords;
      } else {
        state.clubKeywords = copyClub.keywords;
      }

      if (copyClub.descriptionTarget.length > 0) {
        let descriptionTarget = [];

        for (let i = 0; i < copyClub.descriptionTarget.length; i++) {
          descriptionTarget[i] = {
            index: i,
            val: copyClub.descriptionTarget[i],
          };
        }
        state.descriptionTarget = descriptionTarget;
      } else {
        state.descriptionTarget = copyClub.descriptionTarget;
      }
    };

    const setUpKeywords = () => {
      let keywords = state.clubKeywords.reduce((result, current, index) => {
        if (state.clubKeywords[0]) {
          if (state.clubKeywords.length - 1 === index) {
            result += current.val;
          } else {
            result += current.val + ",";
          }
        }
        return result;
      }, "");

      state.clubForm.keywords = keywords;
    };

    const setUpDescriptionTarget = () => {
      let string = state.descriptionTarget.reduce((result, current, index) => {
        if (state.descriptionTarget.length - 1 === index) {
          result += current.val;
        } else {
          result += current.val + ",";
        }
        return result;
      }, "");

      state.clubForm.descriptionTarget = string;
    };

    const reloadIframe = () => {
      document.getElementById("ent-club-iframe").src =
        document.getElementById("ent-club-iframe").src;
    };

    const deleteSession = (model) => {
      ApiService.deleteClubSession(
        state.club.resourceId,
        state.deletingSessionResourceId
      ).then(async () => {
        state.deletingSessionResourceId = "";
        state.showSessionDeleteModal[model] = false;
        await store
          .dispatch("newClubs/getClub", {
            clubResourceId: state.club.resourceId,
          })
          .then(() => {
            state.clubSessions = cloneDeep(
              store.getters["newClubs/newClubSessions"]
            );
          });
        swal.successToast("세션을 삭제했습니다.");
      });
    };

    const postAttachmentImageUrl = async (stateData, imageUrl) => {
      const formData = new FormData();
      formData.append("file", imageUrl);
      await ApiService.postAttachment(formData).then((res) => {
        if (res.data.success) {
          state.clubForm[stateData] = res.data.data.url;
        }
      });
    };

    const actions = {
      changeTab: (tab) => {
        state.activeTab = tab;
      },
      putClub: () => {
        setUpKeywords();
        setUpDescriptionTarget();
        state.isClubSaveLoading = true;
        // todo 클럽 theme 에 색깔이 들어간 이미지가 있어서 에러남.
        if (state.clubForm.theme?.description) {
          delete state.clubForm.theme?.description;
        }

        if (state.selectedDisplayTags.length > 0) {
          state.selectedDisplayTags.forEach((element, index) => {
            state.clubForm.displayTags[index] = element.name;
          });
        }

        store
          .dispatch("newClubs/putClub", {
            clubResourceId: clubResourceId,
            data: state.clubForm,
          })
          .then(() => {
            setUpClubForm();
            reloadIframe();
            swal.successToast("수정되었습니다.");
            state.isClubSaveLoading = false;
          });
      },
      openFileStack: (type) => {
        const mimeTypes = featuredImageAccepts.map((item) => {
          return proxy.$const.mimeTypes[item].value;
        });
        let filestack = new FileStackService();

        filestack.options.accept = mimeTypes;

        filestack.options.transformations.crop.aspectRatio = 1 / 1;
        filestack.options.onFileUploadFinished = async (fileMetaData) => {
          if (type === "pc") {
            await postAttachmentImageUrl("featuredImage", fileMetaData.url);
          } else if (type === "mo") {
            await postAttachmentImageUrl("mFeaturedImage", fileMetaData.url);
          } else if (type === "share") {
            await postAttachmentImageUrl("shareImage", fileMetaData.url);
          } else if (type === "seo") {
            await postAttachmentImageUrl("seoShareImage", fileMetaData.url);
          } else {
            await postAttachmentImageUrl("cover", fileMetaData.url);
          }
        };

        filestack.open(filestack.options);
      },
      deleteImage: (type) => {
        if (type === "pc") {
          state.clubForm.featuredImage = null;
        } else if (type === "mo") {
          state.clubForm.mFeaturedImage = null;
        } else if (type === "share") {
          state.clubForm.shareImage = null;
        } else if (type === "seo") {
          state.clubForm.seoShareImage = null;
        } else {
          state.clubForm.cover = null;
        }
      },
      setDisplayTag: (displayTag) => {
        let displayTagIndex = state.selectedDisplayTags.findIndex((item) => {
          return item.id === displayTag.id;
        });
        if (displayTagIndex < 0) {
          if (state.selectedDisplayTags.length >= 5) {
            state.isSelectError = true;
            return;
          } else {
            state.isSelectError = false;
          }
          state.selectedDisplayTags.push(displayTag);
        } else {
          state.isSelectError = false;
          state.selectedDisplayTags.splice(displayTagIndex, 1);
        }
      },
      popSelectedDisplayTags: (tagId) => {
        const index = state.selectedDisplayTags.findIndex((tag) => {
          return tag.id === tagId;
        });
        state.selectedDisplayTags.splice(index, 1);
      },
      updateClubTitle: (value) => {
        state.clubForm.title = value;
      },
      updateClubHeadline: (value) => {
        state.clubForm.headline = value;
      },
      updateClubDescription: (value) => {
        state.clubForm.description = value;
      },
      updateClubPolicy: (value) => {
        state.clubForm.policy = value;
      },
      updateClubStatus: (statusObj) => {
        state.clubForm.status = statusObj.value;
        state.clubForm.label = statusObj.label;
      },
      updateClubPublishedAt: (value) => {
        state.clubForm.publishedAt = moment(value);
      },
      updateClubIntroHost: (value) => {
        state.clubForm.introHost = value;
      },
      updateClubIntroSchedule: (value) => {
        state.clubForm.introSchedule = value;
      },
      updateClubIntroSession: (value) => {
        state.clubForm.introSession = value;
      },
      updateClubStartAt: (value) => {
        state.clubForm.startAt = moment(value);
      },
      updateClubFinishAt: (value) => {
        state.clubForm.finishAt = moment(value);
      },
      openLiveFinishModal: () => {
        state.showLiveFinishWarningModal = true;
      },
      closeLiveFinishModal: () => {
        state.showLiveFinishWarningModal = false;
      },
      confirmLiveWarning: () => {
        setClubShowPage();
      },
      openContentSessionModal: () => {
        state.showSessionContentModal = true;
      },
      openSessionMeetingModal: (session) => {
        if (!session) {
          state.editingSession = null;
        } else {
          state.editingSession = session;
        }
        state.showSessionLiveModal = true;
      },
      openSessionContentModal: (session) => {
        if (!session) {
          state.editingSession = null;
        } else {
          state.editingSession = session;
        }
        state.showSessionContentModal = true;
      },
      openSessionPollModal: (session = null) => {
        if (!session) {
          state.editingPollSession = null;
        } else {
          state.editingPollSession = session;
        }
        state.showSessionPollModal = true;
      },
      closeSessionContentModal: () => {
        state.showSessionContentModal = false;
        if (state.editingSession) {
          state.editingSession = null;
        }
      },
      closeSessionLiveModal: () => {
        state.showSessionLiveModal = false;
        if (state.editingSession) {
          state.editingSession = null;
        }
      },
      closeSessionPollModal: () => {
        state.showSessionPollModal = false;
        if (state.editingPollSession) {
          state.editingPollSession = null;
        }
      },
      liveCreateComplete: async () => {
        await store
          .dispatch("newClubs/getClub", {
            clubResourceId: state.club.resourceId,
          })
          .then(async () => {
            if (state.editingSession) {
              // saveComplete
              await updateClubSessions(state.editingSession);
              swal.successToast(`LIVE가 수정되었습니다.`);
              state.showSessionLiveModal = false;
            } else {
              if (state.clubSessions.length > 0) {
                state.clubSessions[state.club.sessions.length - 1] =
                  state.club.sessions[state.club.sessions.length - 1];
              } else {
                state.clubSessions[0] = state.club.sessions[0];
              }
              swal.successToast(`LIVE가 생성되었습니다.`);
              state.showSessionLiveModal = false;
            }
            state.showSessionLiveModal = false;
          });
      },
      contentCreateComplete: async () => {
        await store
          .dispatch("newClubs/getClub", {
            clubResourceId: state.club.resourceId,
          })
          .then(async () => {
            console.log("contentCreateComplete");
            if (state.editingSession) {
              // saveComplete
              await updateClubSessions(state.editingSession);
              swal.successToast(`CONTENT가 수정되었습니다.`);
              state.editingSession = null;
              state.showSessionContentModal = false;
            } else {
              if (state.clubSessions.length > 0) {
                state.clubSessions[state.club.sessions.length - 1] =
                  state.club.sessions[state.club.sessions.length - 1];
              } else {
                state.clubSessions[0] = state.club.sessions[0];
              }
              swal.successToast(`CONTENT가 생성되었습니다.`);
              state.showSessionContentModal = false;
            }
            state.showSessionContentModal = false;
          });
        // const newSession = store.getters["newClubs/newClubSessions"].filter(
        //     (session) => {
        //       return (
        //           state.clubSessions.findIndex((item) => {
        //             return item.resourceId === session.resourceId;
        //           }) === -1
        //       );
        //     }
        // );
        // state.clubSessions = [...state.clubSessions, ...newSession];
        // swal.successToast(`VOD가 생성되었습니다.`);
        state.showSessionContentModal = false;
        // sessionWrapper.value.scrollIntoView({ behavior: "smooth" });
        // finishUpdateStatus();
      },
      pollCreateComplete: async () => {
        await store
          .dispatch("newClubs/getClub", {
            clubResourceId: state.club.resourceId,
          })
          .then(async () => {
            if (state.editingPollSession) {
              // saveComplete
              await updateClubSessions(state.editingPollSession);
              swal.successToast(`POLL이 수정되었습니다.`);
              state.showSessionPollModal = false;
            } else {
              if (state.clubSessions.length > 0) {
                state.clubSessions[state.club.sessions.length - 1] =
                  state.club.sessions[state.club.sessions.length - 1];
              } else {
                state.clubSessions[0] = state.club.sessions[0];
              }
              swal.successToast(`POLL이 생성되었습니다.`);
              state.showSessionPollModal = false;
            }
            state.showSessionPollModal = false;
          });
      },
      actionSessionDeleteModal: (model, action, session = null) => {
        if (action === "open") {
          state.deletingSessionResourceId = session.resourceId;
          state.showSessionDeleteModal[model] = true;
        }
        if (action === "close") {
          state.deletingSessionResourceId = "";
          state.showSessionDeleteModal[model] = false;
        }
        if (action === "delete") {
          deleteSession(model);
        }
      },
      editSessionPoll: (session) => {
        router.push({
          name: "console.polls.edit",
          params: {
            pollResourceId: session.poll.resourceId,
          },
        });
      },
      //note 티켓 관련
      openPlanModal: (plan) => {
        if (!plan) {
          state.editingPlan = null;
        } else {
          state.editingPlan = plan;
        }
        state.showPlanListModal = false;
        state.showPlanModal = true;
      },
      closePlanModal: () => {
        state.showPlanModal = false;
      },
      openPlanListModal: () => {
        state.showPlanModal = false;
        state.showPlanListModal = true;
      },
      planCreateComplete: () => {
        // state.selectedPlan = state.club.allPlans[0];
        state.showPlanModal = false;
        setTimeout(() => {
          state.showPlanListModal = true;
        }, 200);
      },
      closePlanListModal: () => {
        state.showPlanListModal = false;
      },
      setStartAt: (date, type) => {
        let dateString = "";
        let timeString = "";
        let result = "";

        if (type === "date") {
          dateString = moment(date).format("YYYY-MM-DD");
          if (state.startAt) {
            timeString = moment(state.startAt).format("HH:mm");
          } else {
            timeString = moment().format("HH:mm");
          }
        }

        if (type === "time") {
          if (state.startAt) {
            dateString = moment(state.startAt).format("YYYY-MM-DD");
          } else {
            dateString = moment().format("YYYY-MM-DD");
          }
          timeString = moment(date).format("HH:mm");
        }

        result = `${dateString} ${timeString}`;

        state.clubForm.startAt = moment(result);
      },
      //태그
      addClubKeyword: (keyword) => {
        state.clubKeywords.push(keyword);
      },
      removeClubKeyword: (keyword) => {
        const keywordIndex = state.clubKeywords.findIndex((item) => {
          return item.index === keyword.index;
        });

        state.clubKeywords.splice(keywordIndex, 1);
      },
      addDescriptionTarget: (keyword) => {
        state.descriptionTarget.push(keyword);
      },
      removeDescriptionTarget: (keyword) => {
        const keywordIndex = state.descriptionTarget.findIndex((item) => {
          return item.index === keyword.index;
        });

        state.descriptionTarget.splice(keywordIndex, 1);
      },
      updateMeetingStatus: () => {
        // todo 고도화 필요 - ui만 업데이트 시킴, 스토어에서 업데이트 시키도록 수정해야함. - todo-1
        swal.successToast(`LIVE 상태가 변경되었습니다.`);
      },
      closeFaqModal: () => {
        if (state.editingFaq) {
          state.editingFaq = null;
        }
        state.showFaqModal = false;
      },
      createFaqComplete: () => {
        if (state.editingFaq) {
          let clubFaqsIndex = state.clubFaqs.findIndex((item) => {
            return item.id === state.editingFaq.id;
          });

          let storeClubFaqsIndex = store.getters[
            "newClubs/newClubFaqs"
          ].findIndex((item) => {
            return item.id === state.editingFaq.id;
          });

          state.clubFaqs[clubFaqsIndex] =
            store.getters["newClubs/newClubFaqs"][storeClubFaqsIndex];
          swal.successToast(`FAQ가 수정되었습니다.`);
        } else {
          if (state.clubFaqs.length === 0) {
            state.clubFaqs = store.getters["newClubs/newClubFaqs"].filter(
              (item) => {
                return !item.isFixed;
              }
            );
          } else {
            state.clubFaqs[state.clubFaqs.length] =
              store.getters["newClubs/newClubFaqs"][
                store.getters["newClubs/newClubFaqs"].length - 1
              ];
          }
          swal.successToast(`FAQ가 생성되었습니다.`);
        }
        state.showFaqModal = false;
      },
      sortFaq: (faqList) => {
        let fixedFaqResourceIds = [];
        let faqResourceIds = [];

        state.fixedFaqs.forEach((faq) => {
          fixedFaqResourceIds.push(faq.resourceId);
        });

        faqList.forEach((faq) => {
          faqResourceIds.push(faq.resourceId);
        });

        state.isSortingFaqs = true;
        store
          .dispatch("newClubs/putClubFaqSort", {
            clubResourceId: state.club.resourceId,
            data: {
              faqResourceIds: [...fixedFaqResourceIds, ...faqResourceIds],
            },
          })
          .then(() => {
            swal.successToast("순서가 변경되었습니다.");
            state.isSortingFaqs = false;
          });
      },
      cancelFaqWriting: () => {
        state.showFaqForm = false;
      },
      updateClubFaq: (faq) => {
        state.editingFaq = faq;
        state.showFaqModal = true;
      },
      openFaqModal: (faq) => {
        if (faq) {
          state.editingFaq = faq;
        } else {
          state.editingFaq = null;
        }
        state.showFaqModal = true;
      },
      updateClubFaqs: () => {
        state.clubFaqs = [
          ...store.getters["newClubs/newClubFaqs"].filter((faq) => {
            return !faq.isFixed;
          }),
        ];
        state.showFaqForm = false;
      },
      showArticleReportSuccessModal: () => {
        swal.successToast("신고 되었습니다.");
      },
      sortClubSessions: (sessions) => {
        state.isSortingSessions = true;
        let sessionResourceIds = [];

        sessions.forEach((session) => {
          sessionResourceIds.push(session.resourceId);
        });

        store
          .dispatch("newClubs/sortSessions", {
            clubResourceId: state.club.resourceId,
            data: {
              sessionResourceIds: sessionResourceIds,
            },
          })
          .then(() => {
            state.club.sessions.forEach((session, index) => {
              state.clubSessions[index] = session;
            });
            state.isSortingSessions = false;
            swal.successToast("순서가 변경되었습니다.");
          });
      },
      togglePreviewSize: () => {
        state.isPcPreview = !state.isPcPreview;
      },
    };

    return {
      state,
      actions,
      getClubMembers,
      featuredImageAccepts,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
