<template>
  <!-- todo SelectableSessionItem 의 코드를 복사하여 사용, 리팩토링시 SelectableSessionItem과 함께 엮어서 사용 가능할지 고민. -->
  <div class="session-card">
    <div :class="{ disable: poll.isSelected, able: !poll.isSelected }"></div>
    <div class="check-box-wrapper">
      <input-checkbox-new
        :default-value="state.selected"
        @updateData="(value) => actions.setSelected(value)"
      ></input-checkbox-new>
    </div>

    <div class="featured-img-wrapper">
      <div
        class="featured-img"
        :style="`background-image: url(${state.poll.featuredImage})`"
      ></div>
    </div>
    <div class="session-text">
      <badge
        :color="proxy.$const.sessionTypes.poll.color"
        :text="proxy.$const.sessionTypes.poll.text"
      ></badge>
      <div class="text-gray-second c-text">
        {{ state.votingPeriod }}
      </div>
      <h6 class="title">
        {{ state.poll.title }}
      </h6>
      <p class="description b-text-3 text-gray-second">
        {{ state.poll.description }}
      </p>
    </div>

    <div class="meeting-schedule">
      <span class="sub-text-s3 text-gray-second"> </span>
    </div>
  </div>
</template>

<script>
import InputCheckbox from "../../../../components/console/inputs/InputCheckbox";
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  onBeforeUpdate,
  reactive,
} from "vue";
import { useStore } from "vuex";
import Badge from "../../../../components/console/badges/Badge.vue";
import InputCheckboxNew from "@/components/console/inputs/InputCheckboxNew.vue";
import helper from "@/helper";
import moment from "moment-timezone";

export default {
  name: "SessionPollModalItem",
  components: { InputCheckboxNew, Badge },
  props: {
    poll: {
      type: Object,
      required: true,
    },
    selectedPollResourceId: {
      type: String,
      default: "",
    },
  },
  emits: ["selectPoll"],
  setup(props, { emit }) {
    const store = useStore();
    const { proxy } = getCurrentInstance();

    const state = reactive({
      poll: {},
      sessions: computed(() => {
        return store.getters["newClubs/newClubSessions"];
      }),
      selected: false,
      isClub: computed(() => {
        let club = props.club;
        return !!club;
      }),
      selectedPollResourceId: computed(() => {
        let id = props.selectedPollResourceId;
        return id;
      }),
      votingPeriod: "",
      // liveSchedule: computed(() => {
      //   if (props.session.meeting) {
      //     return `${moment(props.session.meeting.scheduledAt)
      //       .lang("ko")
      //       .format("MM DD(ddd) HH:mm")} - ${moment(
      //       props.session.meeting.scheduledFinishAt
      //     ).format("HH:mm")}`;
      //   }
      //   return "";
      // }),
    });

    onBeforeMount(() => {
      state.poll = props.poll;
      state.votingPeriod = state.poll.finishAt
        ? `${moment(state.poll.startAt).format("YYYY.MM.DD hh:mm")}
      - ${helper.dateTimeFormat(state.poll.finishAt)}`
        : `${helper.dateTimeFormat(state.poll.startAt)}`;
    });

    onBeforeUpdate(() => {
      state.selected = state.selectedPollResourceId === state.poll.resourceId;
    });

    const actions = {
      setSelected: (value) => {
        state.selected = value;
        console.log(state.selected);
        if (state.selected) {
          emit("selectPoll", state.poll.resourceId);
        }
      },
    };

    return { state, actions, proxy };
  },
};
</script>

<style src="./style.css" scoped></style>
