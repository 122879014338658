<template>
  <base-modal
    title="신고하기"
    sub-title="유형을 선택 후 신고해 주세요. 신고 시 해당 게시물이 삭제될 수 있어요."
    @closeModal="actions.closeModal()"
  >
    <template #modalBody>
      <ul class="reason-list">
        <li>
          <input-checkbox
            class="check-box"
            label="타인에 대한 욕설/비방"
            :default-value="false"
            @updateData="(checked) => actions.setReasonType1(checked)"
          ></input-checkbox>
        </li>
        <li>
          <input-checkbox
            class="check-box"
            label="명예훼손/허위사실 유포"
            :default-value="false"
            @updateData="(checked) => actions.setReasonType2(checked)"
          ></input-checkbox>
        </li>
        <li>
          <input-checkbox
            class="check-box"
            label="개인정보 유츌/사생활 침해"
            :default-value="false"
            @updateData="(checked) => actions.setReasonType3(checked)"
          ></input-checkbox>
        </li>
        <li>
          <input-checkbox
            class="check-box"
            label="음란성 내용"
            :default-value="false"
            @updateData="(checked) => actions.setReasonType4(checked)"
          ></input-checkbox>
        </li>
        <li>
          <input-checkbox
            class="check-box"
            label="상업적 광고/홍보/스팸"
            :default-value="false"
            @updateData="(checked) => actions.setReasonType5(checked)"
          ></input-checkbox>
        </li>
        <li>
          <input-checkbox
            class="check-box"
            label="지적 저작권 침해"
            :default-value="false"
            @updateData="(checked) => actions.setReasonType6(checked)"
          ></input-checkbox>
        </li>
        <li>
          <input-checkbox
            class="check-box"
            label="폭력 또는 사행성 조장"
            :default-value="false"
            @updateData="(checked) => actions.setReasonType7(checked)"
          ></input-checkbox>
        </li>
        <li>
          <input-checkbox
            class="check-box"
            label="기타"
            :default-value="false"
            @updateData="(checked) => actions.setReasonType8(checked)"
          ></input-checkbox>
        </li>
      </ul>
      <input-text-box
        v-model="state.reasonText"
        placeholder="내용을 입력해 주세요."
        :border-none="false"
      ></input-text-box>

      <div class="btn-wrapper">
        <button class="cancel sub-title-s1" @click="actions.closeModal()">
          취소
        </button>
        <button
          class="save sub-title-s1"
          :disabled="state.disabledSaveBtn"
          @click="actions.sendArticleReport()"
        >
          신고
        </button>
      </div>
    </template>
  </base-modal>
</template>
<script>
import BaseModal from "../../../../components/common/BaseModal.vue";
import InputCheckbox from "../../../../components/console/inputs/InputCheckbox";
import InputTextBox from "../../../../components/console/inputs/InputTextBox";
import { computed, reactive } from "vue";
import ApiService from "@/api";

export default {
  name: "DeclarationModal",
  components: { InputTextBox, InputCheckbox, BaseModal },
  props: {
    model: {
      type: String,
      required: true,
      validator(value) {
        return ["articles", "comments"].includes(value);
      },
    },
    modelResourceId: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      reasonText: "",
      reason: computed(() => {
        let result = "";
        if (state.checkedType1) {
          result += "타인에 대한 욕설/비방, ";
        }
        if (state.checkedType2) {
          result += "명예훼손/허위사실 유포, ";
        }
        if (state.checkedType3) {
          result += "개인정보 유츌/사생활 침해, ";
        }
        if (state.checkedType4) {
          result += "음란성 내용, ";
        }
        if (state.checkedType5) {
          result += "상업적 광고/홍보/스팸, ";
        }
        if (state.checkedType6) {
          result += "지적 저작권 침해, ";
        }
        if (state.checkedType7) {
          result += "폭력 또는 사행성 조장, ";
        }
        if (state.checkedType8) {
          result += "기타, ";
        }
        result += state.reasonText;
        return result;
      }),
      checkedType1: false,
      checkedType2: false,
      checkedType3: false,
      checkedType4: false,
      checkedType5: false,
      checkedType6: false,
      checkedType7: false,
      checkedType8: false,
      disabledSaveBtn: computed(() => {
        return (
          !state.checkedType1 &&
          !state.checkedType2 &&
          !state.checkedType3 &&
          !state.checkedType4 &&
          !state.checkedType5 &&
          !state.checkedType6 &&
          !state.checkedType7 &&
          !state.checkedType8
        );
      }),
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      setReasonType1: (checked) => {
        state.checkedType1 = checked;
      },
      setReasonType2: (checked) => {
        state.checkedType2 = checked;
      },
      setReasonType3: (checked) => {
        state.checkedType3 = checked;
      },
      setReasonType4: (checked) => {
        state.checkedType4 = checked;
      },
      setReasonType5: (checked) => {
        state.checkedType5 = checked;
      },
      setReasonType6: (checked) => {
        state.checkedType6 = checked;
      },
      setReasonType7: (checked) => {
        state.checkedType7 = checked;
      },
      setReasonType8: (checked) => {
        state.checkedType8 = checked;
      },
      sendArticleReport: () => {
        ApiService.postReport({
          model: props.model,
          resourceId: props.modelResourceId,
          reason: state.reason,
        }).then((res) => {
          if (res.data.success) {
            emit("reportSuccess");
          }
        });
      },
    };

    return { state, actions };
  },
};
</script>

<style scoped src="./style.css"></style>
