<template>
  <div>
    <label v-if="label" class="label sub-text-s2">
      {{ label }}
      <span v-if="required" class="text-purple-50">*</span>
      <span v-if="discount" class="discount">{{ discount }}</span>
    </label>

    <input
      class="b-text-1"
      inputmode="decimal"
      :class="{ error: danger }"
      :placeholder="placeholder"
      :disabled="disabled"
      :value="formattedValue"
      type="text"
      @input="actions.handleInput"
      @focusout="actions.handleFocusOut"
      @focusin="actions.handleFocusIn"
    />
    <div v-if="danger && errorMessage">
      <span class="text-red-50 sub-text-s3">
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, onMounted, reactive, watch } from "vue";

import helper from "@/helper";

export default {
  name: "InputMoneyV3",
  props: {
    label: {
      type: String,
      required: false,
    },
    value: {
      type: [String, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    danger: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      required: false,
    },
    discount: {
      type: String,
      required: false,
    },
    isFocusOutEvent: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isDecimalPoint: {
      type: Boolean,
      default: false,
    },
    locale: {
      type: String,
      default: "ko-KR",
      validator(props) {
        return ["ko-KR", "en-US", "ja-JP", "th"].includes(props);
      },
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      inputMoney: props.value || "",
      localeObj: {
        locale: "",
        curreny: "",
      },
      isFocus: false,
    });

    const setLocaleCurrency = (locale, currency) => {
      state.localeObj.locale = locale;
      state.localeObj.currency = currency;
    };

    onBeforeMount(() => {
      let propsLocale = props.locale;
      switch (propsLocale) {
        case "en-US":
          setLocaleCurrency("en-US", "USD");
          break;
        case "ja-JP":
          setLocaleCurrency("ja-JP", "JPY");
          break;
        case "th":
          setLocaleCurrency("th", "THB");
          break;
        default:
          setLocaleCurrency("ko-KR", "KRW");
          break;
      }
    });

    const formattedValue = computed(() => {
      let value = props.value;
      if (state.isFocus) {
        return value;
      } else {
        return formatLocaleCurrency(value);
      }
    });

    const formatLocaleCurrency = (number) => {
      return new Intl.NumberFormat(state.localeObj.locale, {
        style: "currency",
        currency: state.localeObj.currency,
      }).format(number);
    };

    const actions = {
      handleInput: (e) => {
        // const number = parseFloat(event.target.value.replace(/[^\d.-]/g, ""));
        const number = parseFloat(e.target.value);

        let result;
        if (Number.isNaN(number)) {
          result = 0;
        } else {
          let formattedString = formatLocaleCurrency(number);
          // /[^\d.-]/g => [^\d.]: 숫자(\d)와 마침표(.)를 제외한 모든 문자 /g: 문자열 전체에서 이러한 패턴을 모두 찾는다.
          result = parseFloat(formattedString.replaceAll(/[^\d.]/g, ""));
        }

        emit("updateData", result);
      },
      handleFocusIn: () => {
        state.isFocus = true;
      },
      handleFocusOut: () => {
        state.isFocus = false;
      },
    };
    return { actions, state, helper, formattedValue };
  },
};
</script>

<style scoped>
.label {
  margin-bottom: 4px;
}

.label .discount {
  padding-left: 6px;
  color: #ff550d;
}

input {
  border: 1px solid #e6eaef;
  border-radius: 4px;
  padding: 14px 16px;
  width: 100%;
}

input.error {
  border-color: #ff4646;
}
</style>
